<!-- 地图右下角图标按钮-功能组件-->
<template>
  <div class="cn-map-zoom-buttons">
    <MapButton @EventClick="EventClick"> </MapButton>
  </div>
</template>
<script>
import MapButton from './MapButton.vue'
import MapUtils from '@/plug/map/openLayer/utils/utils.js'
export default {
  components: { MapButton },
  inject: ['MapRoot'],
  props: {},
  data() {
    return { adminDiviOuterJson: {} }
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap()
  },

  methods: {
    initMap() {
      //初始化地图
      if (this.MapRoot.mapPlug) {
        this.initData()
      }
    },
    initData() {},
    EventClick(ret) {
      if (this.MapRoot.mapPlug.zoomCtrl) {
        this.MapRoot.mapPlug.zoomCtrl(ret)
      }
    },
    EventClick_home() {
      let padding = [90, 10, 100, 10]
      MapUtils.viewFeature({
        mapPlug: this.MapRoot.mapPlug,
        geoJson: this.adminDiviOuterJson,
        padding: padding
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cn-map-zoom-buttons {
  position: absolute;
  top: 10px;
  right: 450px;
}
.ui-button {
  display: block;
  height: 26px;
  width: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  color: #898989;
  cursor: pointer;
}
.ui-button:hover {
  color: #00b5ff;
}
</style>
