<template>
  <div class="cpn-title-c">
    <div class="name">{{ name }}</div>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    other: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-title-c {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  .name {
    position: relative;
    padding: 10px 0;
    font-weight: bold;
    color: #fff;
    &::after {
      content: '';
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 0;
      width: 70px;
      border-bottom: 1px solid var(--ko-bd-color);
    }
  }
}
</style>
