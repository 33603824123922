import TypeIs from '../TypeIs.js'
//判断时间是否在此区间内
Date.prototype.inDate = String.prototype.inDate = function (
  startDate,
  endDate
) {
  if (this && this.Format) {
    let date = this.Format('time')
    if (startDate && endDate) {
      startDate = startDate ? startDate.Format('time') : ''
      endDate = endDate ? endDate.Format('time') : ''
      if (startDate <= date && endDate >= date) {
        return true
      } else {
        return false
      }
    } else {
      console.log('inDate 传入参数错误')
    }
  } else {
    console.log('inDate 传入参数错误：', this)
  }
}

//+---------------------------------------------------
//| 比较日期差 dtEnd 格式为日期型或者有效日期格式字符串
//+---------------------------------------------------
Date.prototype.DateDiff = String.prototype.DateDiff = function (
  strInterval,
  dtEnd
) {
  let dtStart = toDate(this)
  //如果是字符串转换为日期型
  dtEnd = toDate(dtEnd)
  switch (strInterval) {
    case 's':
      return parseInt((dtEnd - dtStart) / 1000)
    case 'n':
      return parseInt((dtEnd - dtStart) / 60000)
    case 'h':
      return parseInt((dtEnd - dtStart) / 3600000)
    case 'd':
      return parseInt((dtEnd - dtStart) / 86400000)
    case 'w':
      return parseInt((dtEnd - dtStart) / (86400000 * 7))
    case 'm':
      return (
        dtEnd.getMonth() +
        1 +
        (dtEnd.getFullYear() - dtStart.getFullYear()) * 12 -
        (dtStart.getMonth() + 1)
      )
    case 'y':
      return dtEnd.getFullYear() - dtStart.getFullYear()
  }
}
Date.prototype.dayName = String.prototype.dayName = function () {
  let date = toDate(this)
  if (date) {
    let daybet = new Date()
      .Format('YYYY-MM-dd')
      .DateDiff('d', date.Format('YYYY-MM-dd'))
    let arr = ['今天', '明天', '后天']
    if (arr[daybet]) {
      return arr[daybet]
    } else {
      return date.Format('周w')
    }
  } else {
    console.log('传入的date参数为空或未定义')
  }
}
Date.prototype.Format =
  Number.prototype.Format =
  String.prototype.Format =
    function (formatStr) {
      let str = formatStr
      let date = toDate(this)
      if (date == false) {
        if (this) {
          return this.toString()
        } else {
          return ''
        }
      }
      function plus_0(num) {
        return num > 9 ? num : '0' + num
      }
      if (str == 'time') {
        return date.getTime()
      }
      if (str == 'date') {
        return date
      }
      let Week = ['日', '一', '二', '三', '四', '五', '六']
      if (typeof str == 'undefined') {
        console.error(
          '在调用Date.Format(fmtStr)是出错，fmtStr未定义，请传入你要格式化的字符如：YYYY-MM-DD'
        )
        return date
      }
      str = str.replace(/yyyy|YYYY/, date.getFullYear())
      str = str.replace(/yy|YY/, plus_0(date.getYear() % 100))
      let thisMonth = date.getMonth() + 1
      str = str.replace(/MM/, plus_0(thisMonth))
      str = str.replace(/M/g, thisMonth)
      str = str.replace(/w|W/g, Week[date.getDay()])
      str = str.replace(/dd|DD/, plus_0(date.getDate()))
      str = str.replace(/d|D/g, date.getDate())
      str = str.replace(/hh|HH/, plus_0(date.getHours()))
      str = str.replace(/h|H/g, date.getHours())
      str = str.replace(/mm/, plus_0(date.getMinutes()))
      str = str.replace(/m/g, date.getMinutes())
      str = str.replace(/ss|SS/, plus_0(date.getSeconds()))
      str = str.replace(/s|S/g, date.getSeconds())
      return str
    }
String.prototype.trim = function () {
  return this.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
}

Date.prototype.DateAdd = String.prototype.DateAdd = function (
  strInterval,
  Number
) {
  let dtTmp = toDate(this)
  if (dtTmp && dtTmp.getTime) {
    switch (strInterval) {
      case 's':
        return new Date(dtTmp.getTime() + 1000 * Number)
      case 'n':
        return new Date(dtTmp.getTime() + 60000 * Number)
      case 'h':
        return new Date(dtTmp.getTime() + 3600000 * Number)
      case 'd':
        return new Date(dtTmp.getTime() + 86400000 * Number)
      case 'w':
        return new Date(dtTmp.getTime() + 86400000 * 7 * Number)
      case 'q':
        return new Date(
          dtTmp.getFullYear(),
          dtTmp.getMonth() + Number * 3,
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        )
      case 'm':
        return new Date(
          dtTmp.getFullYear(),
          dtTmp.getMonth() + Number,
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        )
      case 'y':
        return new Date(
          dtTmp.getFullYear() + Number,
          dtTmp.getMonth(),
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        )
    }
  } else {
    return ''
  }
}

function toDate(ret) {
  if (ret == '' || ret == null || typeof ret == 'undefined') {
    //console.log('时间未定义')
    return ret
  }
  let date,
    year = new Date().getFullYear()
  let reDate
  if (TypeIs.Date(ret)) {
    return ret
  } else if (TypeIs.String(ret)) {
    date = ret
      .replace(new RegExp(/-/gm), '/')
      .replace(new RegExp(/T/gm), ' ')
      .replace(/\..*/, '')
    if (date.indexOf(' ') > 0) {
      reDate = date.split(' ')[0].split('/')
      if (reDate.length != 3) {
        return new Date(year + '/' + date)
      }
    } else if (date.indexOf('/') > 0) {
      reDate = date.split('/')
      if (reDate.length != 3) {
        if (reDate[0].length == 4) {
          return new Date(reDate[0] + '/' + reDate[1] + '/01')
        } else {
          return new Date(year + '/' + date)
        }
      }
    } else {
      if (parseInt(date) == date) {
        let year = date.substr(0, 4)
        if (year > 1900 && year < 2100) {
          if (date.length >= 14) {
            return new Date(
              year +
                '/' +
                date.substr(4, 2) +
                '/' +
                date.substr(6, 2) +
                ' ' +
                date.substr(8, 2) +
                ':' +
                date.substr(10, 2) +
                ':' +
                date.substr(12, 2)
            )
          } else if (date.length == 8) {
            return new Date(
              year + '/' + date.substr(4, 2) + '/' + date.substr(6, 2)
            )
          } else if (date.length == 6) {
            return new Date(year + '/' + date.substr(4, 2) + '/' + '01')
          } else if (date.length == 4) {
            return new Date(year + '/01/01')
          }
        }
      } else if (parseInt(date) > 1900 && parseInt(date) < 2100) {
        return new Date(parseInt(date) + '/01/01')
      }
    }
    if (new Date(date) && new Date(date) != 'Invalid Date') {
      return new Date(date)
    } else {
      console.error('你传入的时间无法格式化：', date)
      return false
    }
  } else if (TypeIs.Number(ret)) {
    if (ret > 9999) {
      return new Date(ret)
    } else if (ret > 1000 && ret < 9999) {
      return new Date(ret.toString() + '/01/01')
    } else {
      console.error('你传入的时间无法格式化：', ret)
      return false
    }
  } else {
    console.error('参数错误', ret)
    return false
  }
}
