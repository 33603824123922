import { KOAjax } from './koajax.js'

export function DefAjax(param) {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'production'
  ) {
    param.baseURL = process.env.VUE_APP_GATEWAY_SERVICE
  } else {
    param.load = 'newUrl'
    param.baseURL = process.env.VUE_APP_GATEWAY_SERVICE
  }
  return KOAjax(param)
}
