export function camelToSnake(obj) {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return obj // Return as is for non-object inputs
  }

  const toSnakeCase = (str) =>
    str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()

  return Object.keys(obj).reduce((result, key) => {
    const snakeKey = toSnakeCase(key)
    const value = obj[key]

    // Recursively process nested objects or arrays
    result[snakeKey] =
      typeof value === 'object' && value !== null ? camelToSnake(value) : value
    return result
  }, {})
}
