<template>
  <div class="map-module-main">
    <div :id="mapId" class="map-container" v-loading="mapLoadingArr?.length" :element-loading-text="mapLoadingText"
      element-loading-background="rgba(0, 0, 0, 0.5)"></div>
    <!--基础-->
    <ModuleMapZoomButton v-if="mapId == 'map-garden'" class="layer-map-zoom" log="地图调整按钮"></ModuleMapZoomButton>
    <!-- <ModuleMapFooter v-if="mapId !== 'map-data-product'" log="地图底部工具栏" @change="EventChange"></ModuleMapFooter> -->
    <ModuleLocationMarker :locatMarker="MapRoot.mapEvent.selectLocat" v-if="showLocatMarker && MapRoot.mapPlug && MapRoot.mapEvent.selectLocat">
    </ModuleLocationMarker>
    <!--功能-->
    <div class="mapPlug-cpn" v-if="MapRoot.mapPlug">
      <ModuleMarker ref="RModuleMarker" log="地图-点组件" :markerDict="markerDict"></ModuleMarker>
      <ModuleFeaturesAdminDivi log="MapModuleMain:地图-多边形-行政区划" :geoJsonInner="geoJsonInner" :geoJsonOuter="geoJsonOuter"></ModuleFeaturesAdminDivi>
      <ModulePolygon v-if="geoPolygon" log="MapModuleMain:地图-多边形-面数据叠加" :geoJsonInner="geoPolygon"></ModulePolygon>
      <ModuleVIPolygon ref="RModuleVIPolygon" log="MapModuleMain:地图-VI多边形-面数据叠加" v-if="VIPolygonGeoJson" :mapPlug="MapRoot.mapPlug" :geoJson="VIPolygonGeoJson"
        :legend="VIPolygonLegend">
      </ModuleVIPolygon>
      <ModuleImgIsogram log="地图图片贴图" :isoImg="isoImg" :bounds="isoImgBounds"></ModuleImgIsogram>
    </div>
    <ModuleMarkerPopup log="地图-点的弹出层组件">
      <template #popup="{ popupData }">
        <slot name="popup" :popupData="popupData"></slot>
      </template>
    </ModuleMarkerPopup>
    <div class="m-test" v-if="false">
      {{ MapRoot.mapEvent.selectFeature }}
    </div>
  </div>
</template>
<script>
/*base*/
import ModuleMapZoomButton from './group/base/ModuleMapZoomButton.vue';
import ModuleMapFooter from './group/base/ModuleMapFooter.vue';
import ModuleVrView from './group/cpn/ModuleVrView.vue';
/*cpn*/
import ModuleFeaturesAdminDivi from './group/cpn/ModuleFeaturesAdminDivi.vue';
import ModulePolygon from './group/cpn/ModulePolygon.vue';
import ModuleVIPolygon from './group/cpn/ModuleVIPolygon.vue';

import ModuleImgIsogram from './group/cpn/ModuleImgIsogram.vue';
import ModuleMarker from './group/cpn/ModuleMarker.vue';
import ModuleMarkerPopup from './group/cpn/ModuleMarkerPopup.vue';
import ModuleLocationMarker from './group/cpn/ModuleLocationMarker.vue';
/*layer*/

import { getMapPlug } from '@/plug/map/openLayer/utils/work.js';
import { HooksMapEvent } from '@/plug/map/openLayer/utils/HooksMapEvent.js';
import { create } from 'ol/transform';
import addFeature from '@/utils/addBaodan';
import ImageLayer from 'ol/layer/Image';
import ImageStatic from 'ol/source/ImageStatic';
/*end*/

import Vector from 'ol/layer/Vector'
export default {
  provide: function () {
    return {
      MapRoot: this.MapRoot
    };
  },
  components: {
    ModuleMapZoomButton,
    ModuleMapFooter,
    ModuleFeaturesAdminDivi,
    ModulePolygon,
    ModuleVIPolygon,
    ModuleImgIsogram,
    ModuleMarker,
    ModuleMarkerPopup,
    ModuleLocationMarker
  },
  props: {
    mapId: {
      type: String,
      default: 'map-module-main'
    },
    areaCode: {
      type: [Number, String],
      default: ''
    },
    //当前视图的bounds
    bounds: {
      type: Array,
      default: () => []
    },
    isoImg: {
      type: String,
      default: ''
    },
    //图片显示的bounds
    isoImgBounds: {
      type: Array,
      default: () => []
    },
    geoJsonInner: {
      type: [Object, Boolean],
      default: () => { }
    },
    geoJsonOuter: {
      type: [Object, Boolean],
      default: () => { }
    },
    //geoJson面数据叠加
    geoPolygon: {
      type: [Object, Boolean],
      default: () => { }
    },
    showBaodan: {
      type: Boolean,
      default: false
    },
    showGanjuData: {
      type: Boolean,
      default: false
    },
    VIPolygonGeoJson: {
      type: [Object, Boolean],
      default: false
    },
    VIPolygonCoord: {
      type: Array,
      default: () => []
    },
    VIPolygonLegend: {
      type: Array,
      default: () => []
    },
    markerDict: {
      type: Object,
      default: () => { }
    },
    showLocatMarker: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Array,
      default: () => [30, 30, 30, 30]
    }
  },
  data() {
    return {
      mapLoadingArr: [],
      mapLoadingText: '',
      MapRoot: {
        //地图共享数据集，子组件通过inject获取
        loading: false, //地图当前是否处于加载中
        mapPlug: false,
        areaCode: this.areaCode,
        areaName: '',
        mapEvent: {
          //地图事件管理对象
          featureType: '', //当前点击的feature类别
          markerType: '', //当前点击的marker类别
          plottingScale: '', //地图分辨率
          mouseCoord: '', //鼠标移入地图停留的当前经纬度
          selectLocat: false,
          selectFeature: false, //地图点组件-当前点击的点
          selectMultMarker: false
        },
        //地图插值图
        imgIsogram: {
          img: '',
          downImg: ''
        },
        footer: {
          //底部配置
          layerView: {
            options: [{ name: '显示设置', value: 'viewSetup' }],
            values: ['viewSetup']
          }
        },
        featureOpacity: 10, //区域区划透明度
        //方法
        onLoading: this.onLoading,
        offLoading: this.offLoading
      }
    };
  },
  watch: {
    'MapRoot.timeline.item': {
      handler() {
        this.setMapImg();
      }
    },
    'MapRoot.mapEvent.selectLocat': {
      handler(val) {
        if (this.showLocatMarker && val) {
          this.$emit('selectLocat', val);
        }
      }
    },
    VIPolygonGeoJson: {
      handler(newVal) {
        console.log(`newVal :>>`, newVal)
        this.initVIPolygon();
      }
    },
    bounds: {
      handler() {
        this.setMapViewBounds();
      }
    }
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      //初始化地图。并获取地图plug
      this.MapRoot.mapPlug = getMapPlug({ id: this.mapId, center: [104.599585, 30.804840], zoom: 14.5 });
      if (this.showBaodan) {
        addFeature(this.MapRoot.mapPlug);
      }
      if (this.showGanjuData) {
        this.addPci(this.MapRoot.mapPlug);
      }
      //定义地图事件
      this.MapRoot.mapEvent = HooksMapEvent(this.MapRoot.mapPlug, (ret) => {
        this.$emit('selectFeature', ret);
        console.log(`点击事件 :>>`, ret)
        console.log(`ret :>>`, ret.values_)
        this.$store.commit("setBaodanItem", ret.values_);

        // console.log(` this.MapRoot :>>`, this.MapRoot)
      });
      if (this.showLocatMarker) {
        this.initLocatMarker();
      }
      this.setMapViewBounds();
      this.initVIPolygon();
    },

    addPci(map) {
      const imageSource = new ImageStatic({
        url: require("@/assets/img/index/ganjuData.png"),
        imageExtent: [104.342917, 30.482590, 104.892722, 30.956023,], // 图片的范围
      });
      const imageLayer = new ImageLayer({
        source: imageSource,
        scale: 0.05
      });
      // 将图片图层添加到地图中
      map.map.addLayer(imageLayer);
    },

    setMapViewBounds() {
      if (this.bounds?.length == 4) {
        this.MapRoot.mapPlug.viewBounds(this.bounds, this.padding);
        this.MapRoot.mapPlug.zoomHome = () => {
          this.MapRoot.mapPlug.viewBounds(this.bounds, this.padding);
        };
      }
    },
    //初始化定位marker点
    initLocatMarker() {
      let center = [103.464156, 30.410275]; //this.MapRoot.mapPlug.center
      this.MapRoot.mapEvent.selectLocat = { lon: center[0], lat: center[1] };
    },
    //初始化VIPolygon的默认地块
    initVIPolygon() {
      if (this.VIPolygonGeoJson && this.VIPolygonCoord?.length == 2) {
        this.setCurrentFeature();
      }
    },
    setCurrentFeature() {
      let feat = this.$refs.RModuleVIPolygon?.setCurrentFeature(
        this.VIPolygonCoord
      );
      if (feat) {
        this.$emit('selectFeature', feat);
      } else {
        setTimeout(() => {
          this.setCurrentFeature();
        }, 100);
      }
    },
    setMapImg() {
      this.MapRoot.imgIsogram = {
        img: this.MapRoot.timeline.item.img,
        downImg: this.MapRoot.timeline.item.downImg
      };
    },
    mapPanTo(coord) {
      this.MapRoot.mapPlug.panTo(coord);
    },
    //设置点的dict
    setMapMarkerDict(markerDict, selectFeature) {
      this.setSelectFeature(selectFeature);
      this.$refs.RModuleMarker?.initMarker(markerDict);
    },
    /**设置当前点的feature */
    setSelectFeature(selectFeature) {
      this.MapRoot.mapEvent.selectFeature = selectFeature
        ? selectFeature
        : false;
      this.MapRoot.mapEvent.markerType = selectFeature?.markerType
        ? selectFeature.markerType
        : '';
    },
    onLoading(text) {
      this.MapRoot.loading = true;
      this.mapLoadingArr.push(text);
      this.mapLoadingText = text ?? '数据加载中...';
    },
    offLoading() {
      this.MapRoot.loading = false;
      this.mapLoadingArr.splice(0, 1);
      this.mapLoadingText = '';
    },
    EventChange(ret) {
      this.$emit('layerView', ret);
      if (ret.length !== 0) {
        this.showVisible()
      } else {
        this.hideVisible()
      }
    },

    showVisible() {
      let layers = this.MapRoot.mapPlug.map.getLayers()
      let arr = layers.getArray()
      arr[6].setVisible(true);
    },
    hideVisible() {
      let layers = this.MapRoot.mapPlug.map.getLayers()
      let arr = layers.getArray()
      arr[6].setVisible(false);
    },
  }
};
</script>
<style lang="scss" scoped>
.map-module-main {
  position: relative;
  height: 100%;

  .map-container {
    height: 100%;
  }

  ::v-deep(.cpn-map-img-legend) {
    left: 320px;
  }
}

.m-test {
  position: absolute;
  left: 50%;
  top: 80px;
  width: 500px;
  background: #000;
}
</style>
