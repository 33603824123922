import _ from 'lodash'
import 'ol/ol.css'
import { Map, View } from 'ol'
import { fromLonLat, toLonLat } from 'ol/proj'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import ResizeListener from 'element-resize-detector'
import TypeIs from '@/api/TypeIs'
import { defaults as defaultInteractions } from 'ol/interaction.js'
import OMU from '@/plug/map/openLayer/utils/utils.js'
class KOClass {
  center = []
  id = 'ol-map'
  zoom = 9
  baseMap = false
  mouseWheelZoom = true // 滚动鼠标中间的滑轮交互
  projection = 'EPSG:4326' //国标：4326 摩卡托：3857
  padding = [20, 10, 50, 10]
  constructor(config) {
    _.merge(this, config)
    this.init()
  }
  init() {
    let baseLayer
    if (this.baseMap) {
      baseLayer = this.getLayer_OSM()
    } else {
      baseLayer = []
    }
    if (!this.view) {
      this.view = new View({
        projection: this.projection, //使用摩卡托坐标系//EPSG:3857
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom
      })
      if (this.center?.length == 2) {
        this.view.setCenter(this.reCoord(this.center))
      }
    }
    if (!this.map) {
      let opt = {
        controls: [], //隐藏默认的放大缩小按钮
        target: this.id,
        layers: baseLayer,
        view: this.view
      }
      if (this.mouseWheelZoom == false) {
        // 取消滚动鼠标中间的滑轮交互
        let defInter = defaultInteractions()
        defInter = defInter.getArray()
        defInter.splice(7, 1)
        opt.interactions = defInter
      }
      this.map = new Map(opt)
      this.$mapView = this.map.getViewport()
      this.initEvent()
    }
  }
  panTo(ret) {
    let coord = this.getCoord(ret)
    this.view.animate({
      center: this.reCoord(coord),
      duration: 200
    })
  }
  setCenter(ret) {
    let coord = this.getCoord(ret)
    this.center = this.coord2Arr(ret)
    this.view.setCenter(coord)
  }
  coord2Arr(ret) {
    let coord = []
    if (TypeIs.Array(ret)) {
      coord = ret
    } else if (TypeIs.Object(ret)) {
      coord = [ret.lon || ret.lng, ret.lat]
    }
    return coord
  }
  getCoord(ret) {
    let coord = this.coord2Arr(ret)
    if (coord[0] && coord[1]) {
      return this.reCoord(coord)
    } else {
      console.error('无法执行设置中心点指令，传入的经纬度未定义：' + coord)
    }
  }
  initEvent() {
    ResizeListener().listenTo(this.$mapView, () => {
      this.map.updateSize()
    })
  }
  getLayer_OSM() {
    //获取openlayer默认底图服务
    return [
      new TileLayer({
        opacity: 1,
        source: new OSM()
      })
    ]
  }
  getZoom() {
    return this.view.getZoom()
  }
  zoomCtrl(ret) {
    //改变地图大小，放大缩小
    let zoom = this.getZoom()
    if (ret === 'add') {
      this.view.animate({ zoom: zoom + 1, duration: 200 })
    } else if (ret === 'sub') {
      this.view.animate({ zoom: zoom - 1, duration: 200 })
    } else if (ret === 'resize') {
      if (this.center?.length == 2) {
        this.view.animate({
          zoom: zoom,
          center: this.reCoord(this.center),
          duration: 200
        })
      }
    }
  }

  getMapViewBounds() {
    return this.view.calculateExtent(this.map.getSize())
  }
  /**
   * 按照bounds显示当前地图视图区域
   * @param {Array} bounds  //[103,29,105,30]
   * @param {Array} padding  //[0,0,0,0]
   */
  viewBounds(bounds) {
    if (bounds) {
      let reArr = [],
        lg,
        lg2
      if (this.projection == 'EPSG:4326') {
        reArr = bounds
      } else {
        lg = this.reData_coords([bounds[0], bounds[1]])
        lg2 = this.reData_coords([bounds[2], bounds[3]])
        reArr = [...lg, ...lg2]
      }
      this.view.fit(reArr, { padding: this.padding ?? [30, 10, 30, 10] })
    }
  }
  reData_coords(coord) {
    let reData = []
    if (coord.length == 2) {
      return fromLonLat(coord)
    } else {
      for (let item of coord) {
        reData.push(fromLonLat(item))
      }
      return reData
    }
  }
  /**
   * 按照geojson显示当前地图视图区域
   * @param {Array} ret.padding //[0,0,0,0]
   * @param {Object} ret.geoJson //geoJson:{features:[]}
   */
  viewFeature(ret) {
    //geoJson:{features:[]}
    if (ret.geoJson?.features?.length) {
      const feat = OMU.getData_features({
        projection: this.projection,
        geoJson: ret.geoJson
      })
      if (feat && feat[0]) {
        const polygon = feat[0].getGeometry()
        let pd = this.padding
        if (ret.padding?.length == 4) {
          pd = ret.padding
        }
        this.view.fit(polygon, {
          padding: pd
        })
      }
    } else {
      console.error('地图方法viewFeature未传入正确的geoJson或padding:', ret)
    }
  }
  reCoord(coord) {
    let reArr = this.reArr
    if (reArr?.length == 2) {
      coord = [parseFloat(coord[0]) + reArr[0], parseFloat(coord[1]) + reArr[1]]
    }
    return this.fromLonLat(coord)
  }
  fromLonLat(coord) {
    if (this.projection == 'EPSG:4326') {
      return coord
    } else {
      return fromLonLat(coord)
    }
  }
  toLonLat(coord) {
    if (this.projection == 'EPSG:4326') {
      return coord
    } else {
      return toLonLat(coord)
    }
  }
  setOption(opt) {
    for (let i in opt) {
      this[i] = opt[i]
    }
  }
}

export default KOClass
