<template>
  <template v-for="(item, index) in navs" :key="item.name">
    <el-sub-menu popper-class="is-dark ko-sub-menu" :index="item.name" class="nav-li" v-if="item?.children" :class="index == 2 ? 'lastLi' : ''">
      <template #title>
        <div class="icon" v-if="item.icon">
          <KOimg :src="getNavImg(item.icon)"></KOimg>
        </div>
        <div class="name">{{ item.name }}</div>
      </template>
      <SubMenu :navs="item.children"></SubMenu>
    </el-sub-menu>
    <el-menu-item v-else :index="item.name" class="nav-li" :class="getClass(item)" @click="EventClick_navLi(item, index)">
      <div class="name">{{ item.name }}</div>
    </el-menu-item>
  </template>
</template>
<script>
export default {
  name: 'SubMenu',
  components: {},
  props: {
    navs: {
      type: Array
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  mounted() { },
  methods: {
    getClass(item) {
      let homeNav = ['/', '/home']
      if (item.path == '/home' && homeNav.includes(this.$route.path)) {
        return 'current'
      } else {
        return item.path == this.$route.path ? 'current' : ''
      }
    },
    EventClick_navLi(item) {
      if (item.path.indexOf('http://') >= 0) {
        if (this.userInfo?.name) {
          window.open(item.path)
        } else {
          this.Api.msg('请先登录')
        }
      } else {
        this.$router.push(item.path)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-popper {
  .nav-li {

    .name {
      font-size: 14px;
    }
  }
}

.nav-li {
  // background: url('@/assets/img/newVersion/nav-normal.png') 100% no-repeat;
  // background-size: 200px 70px;
  background: linear-gradient(130deg, rgba(0, 0, 0, 0), 80%, var(--theme-nav-btn-bg-color-unactive));
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  border-bottom: 0 solid transparent;
  padding: 0 60px;
  transform: skewX(-35deg);

  .name {
    transform: skewX(35deg);
    color: var(--theme-nav-font-color);
  }

  &:last-child:after {
    border-right: 0px solid rgba(255, 255, 255, 0.5);
  }

  .ko-img {
    height: 30px;
    width: 40px;
    margin-right: 10px;

    ::v-deep(img) {
      object-fit: contain;
      vertical-align: top;
    }
  }

  &.is-active {
    border-bottom: 0 solid transparent !important;
  }

  &.current {
    .name {
      color: var(--ko-text-hover-color);
      font-weight: bold;
    }

    border-bottom: 0 solid transparent !important;
    // background: url('~@/assets/img/newVersion/nav-current.png') center/100px 100% no-repeat;
    background: linear-gradient(130deg, rgba(0, 0, 0, 0), 80%, var(--theme-nav-btn-bg-color-active));
    /* 渐变背景 */
    background-size: 200px 64px;
  }
}

.nav-li.current .name {
  color: #fff !important;
  // background-image: -webkit-linear-gradient(right, var(--theme-color), rgba(252, 247, 227, 1)); //开始方向，渐变颜色
  // -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。
  // -webkit-text-fill-color: transparent; //把文字变透明
}

::v-deep .el-menu-item {
  width: 200px !important;
}

::v-deep .el-menu .el-menu-item:last-child {
  padding-left: 0px !important;
}
</style>

<style>
.lump-2 .el-menu .el-menu-item:last-child {
  padding-left: 18px !important;
  padding-right: 37px !important;
}

.lump-2 .el-menu .el-menu-item:nth-child(2) {
  padding-right: 51px !important;
}
</style>
