<template>
  <div class="cpn-dl-labels">
    <dl class="m-dl-label" v-for="item in list" :key="item">
      <dt>{{ item.name }}</dt>
      <dd>{{ item.value }}</dd>
    </dl>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-dl-labels {
  .m-dl-label {
    display: flex;
    margin-bottom: 15px;
    dd {
      text-align: right;
      flex-grow: 1;
    }
  }
}

</style>
