<template>
  <div>
    <BoxContent width="24vw" height="20vh" top="79vh" left="20px" title="赔付金额">
      <div class="pfje_box">
        <div class="pfje_item" v-for="(v, i) in list" :key="i">
          <div class="pfje_item_row1">{{ v.value }}</div>
          <div style="width: 7vw;position: absolute;">
            <img src="@/assets/img/changeColor/pfje_white.png" alt="" style="width: 7vw;">
            <div class="color_bg">
            </div>
          </div>
          <div class="pfje_item_row2">{{ v.name }}</div>
        </div>
      </div>
    </BoxContent>
  </div>
</template>

<script>
import BoxContent from '@/components/newVersion/boxContent.vue'
export default {
  inject: ['MapWRoot'],
  components: {
    BoxContent
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style lang="scss" scoped>
.pfje_box {
  display: flex;
  justify-content: space-evenly;

  .pfje_item {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding-top: 15px;
    width: 7vw;

    .color_bg {
      position: absolute;
      top: 0px;
      left: 0px;
      inset: 0;
      // background: var(--theme-compensation-img-color);
      background: linear-gradient(to bottom, var(--theme-compensation-img-color-inner), var(--theme-compensation-img-color-out));
      // background: radial-gradient(circle, var(--theme-compensation-img-color-inner), 60%, var(--theme-compensation-img-color-out));
      mask: url('@/assets/img/changeColor/pfje_black.png') no-repeat 50% 50% / cover;
      mask-size: 7vw;
      mix-blend-mode: multiply;
    }
  }

  .pfje_item_row1 {
    font-family: 'ysbthFont';
    font-size: 18px;
    background-image: -webkit-linear-gradient(bottom, var(--theme-font-lgc1), var(--theme-font-lgc2)); //开始方向，渐变颜色
    -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。
    -webkit-text-fill-color: transparent; //把文字变透明
  }

  .pfje_item_row2 {
    color: var(--theme-font-color1);
    line-height: 50px;
    position: absolute;
    bottom: -6px;
  }
}
</style>
