<template>
  <div class="module-map-footer is-dark">
    <ul class="m-map-footer">
      <li v-if="false">
        <div class="text box-inner box-fixed">
          <i class="icon icon-fenbianshuai icon-lh"></i>比例尺：
          <div class="bilichi" ref="RCtrl"></div>
        </div>
      </li>
      <li>
        <div class="text box-inner box-fixed">
          <i class="icon icon-fenbianshuai icon-lh"></i>分辨率：{{
            plottingScale
          }}
        </div>
      </li>
      <li>
        <div class="text box-inner box-fixed">
          <i class="icon icon-jingweidu icon-lh"></i>经纬度：{{ mapCoord }}
        </div>
      </li>
      <li class="li-handle">
        <MapBaseSelect></MapBaseSelect>
      </li>
      <li class="li-handle">
        <PopOverLayerCheckbox
          v-model:modelValue="MapRoot.footer.layerView.values"
          :options="MapRoot.footer.layerView.options"
          @change="EventChange"
        ></PopOverLayerCheckbox>
      </li>
      <li class="li-handle" v-if="false">
        <div class="box-handle box-inner box-fixed">
          <i class="icon icon-tuli icon-lh"></i>图例
        </div>
      </li>
      <li class="li-handle">
        <div class="box-handle box-inner box-fixed" @click="showDialogSetup">
          <i class="icon icon-cb20-shezhi icon-lh"></i>设置
        </div>
      </li>
      <li class="li-end">
        <img :src="require('@/assets/img/logo2.png')" alt="" />
        <!-- <div class="text">技术支持：北京华泰德丰技术有限公司</div> -->
      </li>
    </ul>
    <ModuleDialogSetup ref="RDialogSetup"></ModuleDialogSetup>
  </div>
</template>
<script>
import MapBaseSelect from './mapSetup/MapBaseSelect.vue'
import PopOverLayerCheckbox from './mapSetup/PopOverLayerCheckbox.vue'
import ModuleDialogSetup from './mapSetup/ModuleDialogSetup.vue'
//import ScaleLine from 'ol/control/ScaleLine'
export default {
  log: '',
  components: { MapBaseSelect, PopOverLayerCheckbox, ModuleDialogSetup },
  inject: ['MapRoot'],
  props: {},
  data() {
    return {
      DialogSetupVisible: false
    }
  },
  watch: {
    'MapRoot.mapPlug': {
      handler() {
        this.initMap()
      }
    }
  },
  computed: {
    mapCoord() {
      if (this.MapRoot.mapEvent.mouseCoord?.length == 2) {
        return (
          this.MapRoot.mapEvent.mouseCoord[0].toFixed(4) +
          ' | ' +
          this.MapRoot.mapEvent.mouseCoord[1].toFixed(4)
        )
      } else {
        return '-'
      }
    },
    plottingScale() {
      return this.MapRoot?.mapEvent.plottingScale ?? '-'
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
  },
  methods: {
    initMap() {
      //初始化地图
    },
    showDialogSetup() {
      this.$refs.RDialogSetup.open()
    },
    EventChange(ret) {
      this.$emit('change', ret)
    }
  }
}
</script>
<style lang="scss" scoped>
.module-map-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--ko-color-boxbg);
  --el-text-color-regular: var(--ko-regular-color);

  .m-map-footer {
    display: flex;
    font-size: 14px;
    li {
      flex-grow: 1;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      &:last-child {
        border-right: 0 solid #000;
      }
      .bilichi {
        ::v-deep(.ol-scale-line) {
          position: relative;
          left: 0;
          bottom: 0;
          line-height: 14px;
        }
      }
      .box-inner {
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        flex-grow: 1;
      }
      .box-fixed {
        align-items: center;
        justify-content: center;
        display: flex;
        word-break: keep-all;
        white-space: nowrap;
      }
      .box-handle {
        cursor: pointer;
        &:hover {
          background: rgba(100, 100, 100, 0.05);
        }
      }
      i.icon {
        margin-right: 5px;
        font-size: 16px;
        display: inline-block;
      }
      i.icon-right {
        margin-left: 5px;
        font-size: 12px;
      }
      .el-dropdown {
        display: block;
      }
      &.li-end {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 30px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
