<template>
  <div class="cpn-title">
    <div class="title">
      <i class="i-left"></i><i class="i-line"></i>
      <div class="name">{{ title }}</div>
    </div>
    <div class="other" v-if="other">
      {{ other }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  components: {},
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    other: {
      type: String,
      default: ''
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {},
  created() {
    // setTimeout(() => {
    //   this.options[0].label = this.subTitle
    //   this.options[0].value = this.subTitle
    //   this.value = this.subTitle
    // }, 1000)
  },
  unmounted() {}
}
</script>
<style lang="scss" scoped>
.cpn-title {
  display: flex;
  align-items: center;
  .title {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    &:after {
      content: '';
      display: block;
      width: 27px;
      height: 30px;
      margin-left: 10px;
      background: url('@/assets/img/title-i-right.png') center/contain no-repeat;
      flex-shrink: 0;
    }
    .i-left {
      width: 24px;
      height: 30px;
      background: url('@/assets/img/title-i-left.png') center/contain no-repeat;
      flex-shrink: 0;
    }
    .i-line {
      position: absolute;
      bottom: 0;
      left: 23px;
      right: 0;
      height: 2px;
      background: linear-gradient(90deg, #1d324e, #08679e);
    }
    .name {
      flex-grow: 1;
      color: rgba(255, 255, 255, 0.8);
      font-weight: bold;
      background-image: -webkit-linear-gradient(bottom, #2194ff, #f7fffd);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .sub-title {
      color: var(--ko-text-hover-color);
    }
  }
  .other {
    flex-shrink: 0;
    cursor: pointer;
  }
}
:deep(.el-tooltip__trigger) {
  width: 250px;
}
</style>
