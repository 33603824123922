import { DefAjax } from '../service/ajax.js'
//import Api from '@/api/Api.js'

/**
 * 查询所有作物
 * @param {Object} ret.areaCode
 * @returns
 */
export function Info_farmInsureList(ret) {
  let def = {
    companyBaseId: 'fb38662573d244839dd6dd2af2488f72'
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    // url: '/source/api/insure/insurandFarmland/list',
    url: '/insure/insurandFarmland/list',
    params: opt
  })
}
