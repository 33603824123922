<!--地图轮廓绘制-VI功能组件-->
<template>
  <div></div>
</template>
<script>
import { Fill, Stroke, Style, Text } from 'ol/style';
import LayerGeojsonVI from '@/plug/map/openLayer/polygon/LayerGeojsonVI.js';
export default {
  log: '',
  components: {},
  inject: [],
  props: {
    mapPlug: {
      type: [Object, Boolean],
      default: false
    },
    geoJson: {
      type: [Object, Boolean],
      default: false
    },
    legend: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      style: new Style({
        fill: new Fill({
          color: '#ff0099'
        }),
        text: new Text({
          text: 'test',
          fill: new Fill({
            color: '#000'
          })
        }),
        stroke: new Stroke({
          color: 'rgba(255,255,255,0.1)',
          width: 1
        })
      }),
      selectStyle: new Style({
        fill: new Fill({
          color: '#ff0099'
        }),
        stroke: new Stroke({
          color: '#ff0099',
          width: 2
        })
      }),
      currentDict: {
        1: '#5dff3f',
        2: '#fffb8e',
        3: '#ffa966',
        4: '#ff6666'
      }
    };
  },
  watch: {
    geoJson: function (newVal) {
      this.initGeojson(newVal);
    }
  },
  computed: {
    legendDict() {
      let dict = {};
      for (let item of this.legend) {
        dict[item.name] = item.color;
      }
      return dict;
    }
  },
  created() { },
  mounted() {
    this.initGeojson(this.geoJson);
  },
  methods: {
    initGeojson(geoJson) {
      if (this.layerGeojsonVI) {
        this.layerGeojsonVI.setFeatures(geoJson);
      } else {
        this.layerGeojsonVI = new LayerGeojsonVI({
          mapPlug: this.mapPlug,
          features: geoJson,
          defaultStyle: (feature) => {
            this.style.getStroke().setColor('rgba(255,255,255,0.1)');
            return this.getFeatureStyle(feature, 0);
          },
          selectStyle: (feature) => {
            this.selectStyle.getStroke().setColor('#FFF');
            return this.getNewFeatureStyle(feature);
          }
        });
      }
    },
    getFeatureStyle(feature) {
      const value = feature?.data['品种'];
      let color = '#999'; //value是百分比给与对应的颜色
      if (value.indexOf('池') !== -1) {
        color = this.legendDict.池塘;
      } else if (this.legendDict[value]) {
        color = this.legendDict[value];
      }
      //new ColorScale(0, 4, ['#d64556', '#FFB43A'])
      //color = colorScale.getColor(value) //value是百分比给与对应的颜色
      this.style.getFill().setColor(color);
      this.style.getText().setText(String(feature?.data.mj.toFixed(1)));
      return this.style;
    },
    getNewFeatureStyle() {
      let color = 'rgba(0,0,0,0)';
      //new ColorScale(0, 4, ['#d64556', '#FFB43A'])
      //color = colorScale.getColor(value) //value是百分比给与对应的颜色
      this.selectStyle.getFill().setColor(color);
      return this.selectStyle;
    },
    /**
     * coord:[103,31]
     * 传入经纬度，返回一个当前经纬度的feature对象
     */
    setCurrentFeature(coord) {
      if (coord?.length == 2) {
        return this.layerGeojsonVI.setCurrentFeature(coord);
      }
    }
  }
};
</script>
<style lang="scss"></style>
