import { createStore } from 'vuex'

import storeModules from './storeModules.js'

const store = createStore({
  state() {
    return {
      flag: true,
      baodanItem: {},
      jidiName: '', // 基地名称
      jidiCode: '', // 基地id 金堂：fb38662573d244839dd6dd2af2488f72 安岳：310214d6b15011ef93cf00163e018e82
      coor: {}, // 基地经纬度
      centerBounds: [], // 地图边界
      nyMsg: {}, // 数字农业示范园相关信息
      border:{} // 边界
    }
  },
  mutations: {
    Flag_True(state) {
      state.flag = true
    },
    Flag_Flase(state) {
      state.flag = false
    },
    setBaodanItem(state, newVal) {
      state.baodanItem = newVal
    },
    SET_JIDINAME(state, newVal) {
      state.jidiName = newVal
    },
    SET_JIDICODE(state, newVal) {
      state.jidiCode = newVal
    },
    SET_COOR(state, newVal) {
      state.coor = newVal
    },
    SET_CENTERBOUNDS(state, newVal) {
      // console.log(`newVal :>>`, newVal)
      state.centerBounds = newVal
    },
    SET_NYMSG(state, newVal) {
      state.nyMsg = newVal
    },
    SET_BORDER(state, newVal) {
      // console.log(`newVal :>>`, newVal)
      state.border = newVal
    }
  },
  getters: {},
  actions: {},
  modules: {
    ...storeModules
  }
})

export default store
