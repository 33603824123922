<template>
  <VIndex></VIndex>
</template>

<script>
import VIndex from '@/views/VIndex.vue'
export default {
  components: { VIndex },
  created() {
    // this.fetchThemeColor()
  },
  methods: {
    // 颜色变浅
    lightenColorRGBA(rgba, amount) {
      const match = rgba.match(/rgba?\((\d+), (\d+), (\d+), ([\d.]+)\)/);
      if (!match) return rgba;

      let [_, r, g, b, a] = match.map(Number);
      r = Math.min(255, r + amount);
      g = Math.min(255, g + amount);
      b = Math.min(255, b + amount);

      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    // 颜色变深
    darkenColorRGBA(rgba, amount) {
      const match = rgba.match(/rgba?\((\d+), (\d+), (\d+), ([\d.]+)\)/);
      if (!match) return rgba;

      let [_, r, g, b, a] = match.map(Number);
      r = Math.max(0, r - amount);
      g = Math.max(0, g - amount);
      b = Math.max(0, b - amount);

      return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
  }
}
</script>
<style lang="scss" scoped></style>
