import 'ol/ol.css'
import _ from 'lodash'
import TileLayer from 'ol/layer/Tile'
import TileWMS from 'ol/source/TileWMS'
import { XYZ, OSM, TileArcGISRest } from 'ol/source'
import Api from '@/api/Api.js'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Collection from 'ol/Collection.js'
import { Fill, Stroke, Style } from 'ol/style'

class KOClass {
  type = 'satellite.xyz' //0,1,2,3
  opacity = 0.8
  projection = 'EPSG:3857' //底图是EPSG:3857的标准///4326

  constructor(config) {
    _.merge(this, config)

    this.init()
  }
  init() {
    this.setSourceKey(this.type)
    this.addLayer_base()
  }
  setSourceKey(type) {
    this.sourceType = Api.getSuffix(type)
    this.sourceKey = Api.getSFileName(type)
  }
  // 添加矢量边界
  addAreaBound() {
    return
    console.debug('加载边界')
    fetch('http://8.137.96.209:5002/jintang/Data/jt.json')
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        let feats = new GeoJSON().readFeatures(data)
        let source = new VectorSource({
          features: new Collection(feats)
        })
        let style = new Style({
          stroke: new Stroke({
            color: 'rgba(125, 125, 125, 1)',
            width: 2
          }),
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0)'
          })
        })
        let layer = new VectorLayer({
          source: source,
          style: style,
          zIndex: 1
        })
        this.mapPlug.map.addLayer(layer)
      })
  }
  addLayer_base() {
    this.addAreaBound()
    this.baseLayers = this.getLayers_TXYZ()
    // 添加矢量边界
    if (this.baseLayers) {
      for (let i in this.baseLayers) {
        this.mapPlug.map.addLayer(this.baseLayers[i])
      }
    }
  }
  getXYZSourceUrl(type, base) {
    let typeDict = {
      vector: { type: 'xyz', name: '矢量', baseKey: 'vec_w', nameKey: 'cva_w' },
      terrain: {
        type: 'xyz',
        name: '地形',
        baseKey: 'ter_w',
        nameKey: 'cta_w'
      },
      satellite: {
        type: 'xyz',
        name: '卫星',
        baseKey: 'img_w',
        nameKey: 'cia_w'
      }
    }
    let keyObj = typeDict[type]
    if (keyObj) {
      if (base == 'base') {
        return (
          'http://t0.tianditu.gov.cn/DataServer?T=' +
          keyObj.baseKey +
          '&x={x}&y={y}&l={z}&tk=872a282f7273dd7ed7aaccbe1768b427'
          // `http://t0.tianditu.gov.cn/img_w/wmts?tk=55a99da3890b9f7cfabed61b878c1d2f`
        )
      } else if (base == 'name') {
        // return (
        //   'http://t0.tianditu.gov.cn/DataServer?T=' +
        //   keyObj.nameKey +
        //   '&x={x}&y={y}&l={z}&tk=872a282f7273dd7ed7aaccbe1768b427'
        // )
        // return 'https://tile.digital-flying.com:1445/anyuelemon/{z}/{x}/{y}.png'
        return 'https://tile.digital-flying.com:1445/img/{z}/{x}/{y}.png'
      }
    } else {
      return false
    }
  }
  getArcSourceUrl(type) {
    if (type == 'default')
      return 'http://map.geoq.cn/arcgis/rest/services/ChinaOnlineStreetPurplishBlue/MapServer'
  }
  getLayerSource(base) {
    if (base) {
      let sourceType = this.sourceType
      if (sourceType == 'arc' && base == 'base') {
        return new TileArcGISRest({
          url: this.getArcSourceUrl(this.sourceKey),
          extent: [68.17665, 7.96553, 97.40256, 35.49401],
          crossOrigin: 'Anonymous'
        })
      } else if (sourceType == 'xyz') {
        let sourceUrl = this.getXYZSourceUrl(this.sourceKey, base)
        return new XYZ({
          url: sourceUrl,
          projection: this.projection
        })
      }
    }
    return null
  }
  getSource_ArcGIS() {
    return new TileLayer({
      properties: { type: 'baseLayer' },
      opacity: this.opacity,
      source: this.getLayerSource('base')
    })
  }
  getLayers_TXYZ() {
    //街道底图
    if (this.type) {
      let sourceKey = this.sourceKey
      let sourceType = this.sourceType
      if (sourceType == 'arc') {
        return {
          base: this.getSource_ArcGIS(sourceKey),
          name: new TileLayer({
            properties: { type: 'baseLayer' },
            opacity: this.opacity
          })
        }
      } else if (sourceType == 'wms') {
        return {
          base: this.getLayer_WMS_sc(),
          name: new TileLayer({
            properties: { type: 'baseLayer' },
            opacity: this.opacity
          })
        }
      } else if (sourceType == 'xyz') {
        return {
          //地形底图图层
          base: this.getTileLayer('base'),
          //名称底图图层
          name: this.getTileLayer('name')
        }
      }
    } else {
      return {
        //地形底图图层
        base: this.getTileLayer('base'),
        //名称底图图层
        name: this.getTileLayer('name')
      }
    }
  }
  getTileLayer(base) {
    return new TileLayer({
      properties: { type: 'baseLayer' },
      opacity: base == 'base' ? this.opacity : 0.5,
      source: this.getLayerSource(base)
    })
  }
  changeLayerType(type) {
    if (type != this.type) {
      this.setSourceKey(type)
      this.baseLayers.base.setSource(this.getLayerSource('base'))
      this.baseLayers.name.setSource(this.getLayerSource('name'))
      this.type = type
    }
    //this.addLayer_base()
  }
  getLayer_OSM() {
    //获取openlayer默认底图服务
    return [
      new TileLayer({
        opacity: this.opacity,
        source: new OSM(),
        zIndex: 3
      })
    ]
  }
  getLayer_WMS_sc() {
    //获取wms底图服务
    return new TileLayer({
      opacity: this.opacity,
      source: new TileWMS({
        url: process.env.VUE_APP_MAP_SERVICE, //"http://47.108.208.238:3098/geoserver/wk/wms",
        params: {
          FORMAT: 'image/png',
          VERSION: '1.3.0',
          LAYERS: 'sc',
          exceptions: 'application/vnd.ogc.se_inimage'
        }
      }),
      zIndex: 2
    })
  }
  getLayer_XYZ() {
    return [
      new TileLayer({
        opacity: this.opacity,
        source:
          // new OSM()
          new XYZ({
            url: 'https://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}',
            crossOrigin: 'anonymous',
            extent: [68.17665, 7.96553, 97.40256, 35.49401]
          })
      })
    ]
  }
  getLayer_AMapXYZ() {
    return [
      new TileLayer({
        opacity: this.opacity,
        source:
          // new OSM()
          new XYZ({
            url: 'https://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}',
            crossOrigin: 'anonymous'
          })
      })
    ]
  }
}
export default KOClass
