<template>
  <div class="vpage-home">
    <AreaMapWrapHome
      log="地图包组件-首页"
      :userAreaCode="userAreaCode"
    ></AreaMapWrapHome>
  </div>
</template>
<script>
import AreaMapWrapHome from './areaMap/AreaMapWrapHome.vue'
export default {
  inject: ['AppRoot'],
  components: { AreaMapWrapHome },
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {
    userAreaCode() {
      return '41'
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.vpage-home {
  box-sizing: border-box;
  background: var(--ko-color-boxbg);
}
</style>
