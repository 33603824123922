<!--地图轮廓绘制-功能组件-->
<template>
  <div></div>
</template>
<script>
import AddPolygon from '@/plug/map/openLayer/polygon/AddPolygon.js';
import { FeatSty } from '@/plug/map/openLayer/utils/work.js';
export default {
  log: '',
  components: {},
  inject: ['MapRoot'],
  props: {
    mapPlug: {
      type: Object,
      default: () => { }
    },
    geoJsonInner: {
      type: [Object, Boolean],
      default: () => { }
    },
    geoJsonOuter: {
      type: [Object, Boolean],
      default: () => { }
    }
  },
  data() {
    return {
      featTheme: 'lightGreen'
    };
  },
  watch: {
    geoJsonInner: {
      handler() {
        this.setMapInnerFeatures();
      }
    }
  },
  computed: {},
  created() { },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  methods: {
    initMap() {
      //初始化地图
      this.setMapInnerFeatures();
      //this.setMapOuterFeatures()
    },
    setMapInnerFeatures() {
      let mapPlug = this.MapRoot.mapPlug;
      let geoJson = this.geoJsonInner;
      if (geoJson?.features?.length) {
        //绘制内轮廓
        if (mapPlug.PolygonInnerPlug) {
          mapPlug.PolygonInnerPlug.update({ data: geoJson });
        } else {
          mapPlug.PolygonInnerPlug = new AddPolygon({
            mapPlug: mapPlug,
            data: geoJson,
            defaultStyle: FeatSty[this.featTheme].innerPoly,
            selectStyle: FeatSty[this.featTheme].innerPoly,
            zIndex: 18
          });
        }
      } else {
        mapPlug.PolygonInnerPlug?.update({ data: geoJson });
      }
      /*mapPlug.viewFeature({
        geoJson: geoJson,
        padding: [60, 30, 60, 30]
      })*/
      //setFeatureLabel(this.MapRoot.mapPlug, this.geoJsonInner) //绘制feature标签
    }
  }
};
</script>
<style lang="scss"></style>
