import { Fill, Stroke, Text } from 'ol/style'
import initMap from '@/plug/map/openLayer/initMap.js'
import MapBaseImg from '@/plug/map/openLayer/MapBaseImg.js'
import Api from '@/api/Api.js'
import TypeIs from '@/api/TypeIs.js'
// 地图轮廓样式定义
export const FeatSty = {
  dark: {
    //黑色风格
    innerPoly: {
      stroke: new Stroke({
        color: 'rgba(129, 155, 183, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(22, 47, 86, 0.9)'
      })
    },
    outerPoly: {
      stroke: new Stroke({
        color: 'rgba(129, 155, 183, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(30, 120, 255, 0)'
      })
    }
  },
  lightGreen: {
    //白色底色，绿色feature风格
    innerPoly: {
      stroke: new Stroke({
        color: 'rgba(24,255,60, 0)',
        width: 0
      }),
      fill: new Fill({
        color: 'rgba(24,255,60, 0.6)'
      })
    },
    outerPoly: {
      stroke: new Stroke({
        color: 'rgba(24,255,60, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(24,255,60, 0)'
      })
    }
  },
  lightBlue: {
    //白色风格
    innerPoly: {
      stroke: new Stroke({
        color: 'rgba(22, 127, 230, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(22, 127, 230, 0)'
      })
    },
    outerPoly: {
      stroke: new Stroke({
        color: 'rgba(22, 127, 230, 1)',
        width: 2
      }),
      fill: new Fill({
        color: 'rgba(22, 127, 230,0.1)'
      })
    }
  },
  red: {
    //红色风格
    innerPoly: {
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.1)'
      })
    },
    outerPoly: {
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0,0.1)'
      })
    }
  }
}

export const FeatureStyle = FeatSty.lightGreen
export const FeatureStyleBlue = FeatSty.lightBlue
//把接口返回的data数据格式化为geoJson格式
export function reData_info2geoJson(data) {
  let geoJson = { features: [], type: 'FeatureCollection' }
  if (data?.length) {
    for (let item of data) {
      geoJson.features.push({
        type: 'Feature',
        properties: item.properties,
        geometry: item.geometry
      })
    }
  }
  return geoJson
}
//获取feature的item中的key
export function setAreaKey(item) {
  let areaNameArr = [
    'areaName',
    'AreaName',
    'NAME_1',
    'NAME',
    'AREANAME',
    'name',
    'qu_mc'
  ]
  let areaCodeArr = [
    'areaCode',
    'AreaCode',
    'CODE_1',
    'AREACODE',
    'adcode',
    'FID'
  ]
  let areaNameKey = ''
  let areaCodeKey = ''
  for (let code of areaCodeArr) {
    if (item.properties[code] || item.properties[code] === 0) {
      areaCodeKey = code
    }
  }
  if (areaNameKey == '') {
    for (let name of areaNameArr) {
      if (item.properties[name]) {
        areaNameKey = name
      }
    }
  }
  /*if (areaCodeKey == '' || areaNameArr == '') {
    console.error(
      'MapAdminDiv.vue:未找到对应的areaCode或areaName，请检查item并加上对应的areaCode',
      item
    )
  }*/
  return { areaNameKey: areaNameKey, areaCodeKey: areaCodeKey }
}

//格式化轮廓feature的item
export function reData_featuresMarker(features) {
  let reData = []
  let reLocat = { 安阳市: [114.52, 36] }
  let areaCodeKey, areaNameKey
  if (features?.length) {
    for (let item of features) {
      let keyObj = setAreaKey(item)
      areaNameKey = keyObj.areaNameKey
      areaCodeKey = keyObj.areaCodeKey
      let center = ['', '']
      let areaName = item.properties[areaNameKey]
      if (reLocat[areaName]) {
        center = reLocat[areaName]
      } else if (item.properties?.center) {
        center = item.properties?.center
      }
      reData.push({
        id: center.join(','),
        areaCode: item.properties[areaCodeKey],
        name: item.properties[areaNameKey],
        lon: center[0],
        lat: center[1]
      })
    }
    return reData
  } else {
    return []
  }
}

//验证是否是无效值
export function verVoidValue(val) {
  let v = '-'
  if (val == -9999 || val == 9999) {
    return v
  }
  if (val >= 999990 || val <= -999990) {
    return v
  }
  return val
}

//组装流域feature的字典数据，key为流域code
export function getDict_featureData(list) {
  let dict = {}
  for (let item of list) {
    if (typeof dict[item.code] == 'undefined') {
      dict[item.code] = item
    }
  }
  return dict
}

//组装流域feature的字典数据，key为流域code
export function getMapPlug(ret) {
  // console.log(`ret :>>`, ret)
  let def = {
    id: 'map-plug',
    center: [104.609, 30.809], //[117, 32],
    zoom: 15.8,
    minZoom: 2,
    maxZoom: 19
  }
  let config = Object.assign({}, def, ret)
  let mapPlug = new initMap(config)
  mapPlug.mapBaseImg = new MapBaseImg({
    mapPlug: mapPlug,
    opacity: 1
  })
  return mapPlug
}
/**
 * 获取完整的透明图片路径
 * @param {String} url //图片路径
 * @returns
 */
export function getMapIsoImg(url) {
  let suf = Api.getSuffix(url)
  let tmImg = url.split('.')[0] + '_TM.' + suf
  return Api.getHostPath() + tmImg
}

/**
 * 获取完整的下载图片路径
 * @param {String} url //图片路径
 * @returns
 */
export function getDownImg(url) {
  return Api.getHostPath() + url
}

/**
 * 同cpn之间进行类别的切换，用于取消显示当前label
 * @param {String} type1 //切换的cpnName
 * @param {String} type2 //当前cpnName
 * @returns
 */
export function sameCpnChangeType(type1, type2) {
  let arrType1 = type1.split('-')
  let arrType2 = type2.split('-')
  if (arrType1[0] == arrType2[0]) {
    if (arrType1[1] != arrType2[1]) {
      return true
    }
  }
  return false
}

/**
 * 获取子标题
 * @param {Object} ret //获取子标题
 * @returns
 */
export function getSubTitle(ret) {
  let def = {
    fmt: 'YYYY-MM-DD'
  }
  let opt = Object.assign({}, def, ret)
  if (opt?.startDate && opt?.endDate) {
    return (
      opt?.startDate?.Format(opt.fmt) + '至' + opt?.endDate?.Format(opt.fmt)
    )
  } else if (opt?.date) {
    return opt?.date?.Format(opt.fmt)
  } else {
    //console.error("subTitle没有开始结束时间和当前时间");
    return ''
  }
}

/*
coord:点击的经纬度，格式：[104,31]
stations:所有站点的数组集合，格式：[{lon:104,lat:31},{lon:104.1,lat:31.1}]
 */
export function getRecentlyStation(ret, stations) {
  //辅助方法，传入经纬度，返回附近的站点对象
  var minDistance = 9, //最小距离
    curMin
  var smallIndex = 0
  let coord = []
  if (TypeIs.Array(ret)) {
    coord = ret
  } else if (TypeIs.Object(ret)) {
    coord = [ret.lon, ret.lat]
  } else {
    console.log('意料之外的经纬度参数数据:', ret)
  }
  if (coord?.length == 2 && stations?.length) {
    stations.map((el, index) => {
      curMin = Math.abs(el.lon - coord[0]) + Math.abs(el.lat - coord[1])
      if (minDistance > curMin) {
        minDistance = curMin
        smallIndex = index
      }
    })
    if (minDistance < 1) {
      //这个值可以调
      return stations[smallIndex]
    } else {
      console.error('附近没有找到站点')
      return []
    }
  } else {
    console.error('未传入的经纬度或站点，无法匹配')
    return []
  }
}
