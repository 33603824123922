export function mix(...mixins) {
  class KO {
    // 不需要拷贝实例属性下面这段代码可以注释掉
    // constructor() {
    //   for (let mixin of mixins) {
    //     copyProperties(this, new mixin()); // 拷贝实例属性
    //   }
    // }
  }
  for (let mixin of mixins) {
    copyProperties(KO, mixin) // 拷贝静态属性
    copyProperties(KO.prototype, mixin.prototype) // 拷贝原型属性
  }
  return KO
}
// 深拷贝
function copyProperties(target, source) {
  for (let key of Reflect.ownKeys(source)) {
    if (key !== 'constructor' && key !== 'prototype' && key !== 'name') {
      let desc = Object.getOwnPropertyDescriptor(source, key)
      Object.defineProperty(target, key, desc)
    }
  }
}
