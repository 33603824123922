import _ from 'lodash'
import TypeIs from '@/api/TypeIs.js'
class KOApi {
  /*
  *防抖函数
  let Dounce = Api.debounce(this.changeMapZoom, 500);
  Dounce() //多次执行这个方法
  true:先跑一次，忽略500毫秒中持续执行的事件
  false:只执行500毫秒以后的最后一次
  */
  debounce(func, wait, immediate) {
    //防抖函数
    let timer = null
    let context, args, result, timestamp

    let later = function () {
      let oDate = new Date()
      let last = oDate.getTime() - timestamp // 计算第一次时间戳与当前时间戳的差值。

      if (last < wait && last >= 0) {
        // 在等待时间内触发此函数，重新计时。
        timer = setTimeout(later, wait - last)
      } else {
        timer = null
        if (!immediate) {
          // 限制immediate 为true时，执行回调函数。
          result = func.apply(context, args)
          if (!timer) context = args = null
        }
      }
    }

    return function () {
      let oDate = new Date()
      let callNow = immediate && !timer // 代表第一次调用立即执行。

      timestamp = oDate.getTime() // 记录下当前时间戳
      context = this // 保存上下文
      args = arguments

      if (!timer) {
        // 第一次触发时，timer为空，进入此分支
        timer = setTimeout(later, wait)
      }

      if (callNow) {
        // 第一次触发且immediate为true，进入此分支
        result = func.apply(context, args)
        context = args = null
      }

      return result
    }
  }
  findItem(ret) {
    let def = {
      arr: [],
      idKey: 'value',
      value: ''
    }
    let opt = Object.assign({}, def, ret)
    if (opt.value) {
      let item = this.loop(opt)
      if (item) {
        return item
      } else {
        return false
      }
    } else {
      console.error('findItem方法：未传入待查找的value参数', opt.value)
      return false
    }
  }
  loop(ret) {
    for (let item of ret.arr) {
      if (item[ret.idKey] == ret.value) {
        return item
      } else if (item.children?.length) {
        return this.loop({
          arr: item.children,
          idKey: ret.idKey,
          value: ret.value
        })
      }
    }
  }
  extend() {
    return _.merge({}, ...arguments)
  }
  reSubffix(url, suf) {
    //变更后缀名
    if (url && TypeIs.String(url) && url.split('.') && url.split('.').length) {
      let urlArr = url.split('.')
      let beforeUrl = urlArr[0]
      return beforeUrl + '.' + suf
    } else {
      console.log('传入path未定义：' + url)
    }
  }
  getSuffix(path) {
    if (
      path &&
      TypeIs.String(path) &&
      path.split('.') &&
      path.split('.').pop()
    ) {
      return path.split('.').pop().toLowerCase()
    } else {
      console.log('传入path未定义：' + path)
    }
  }
  getFileName(path, str) {
    //获取文件名-含后缀名
    if (path) {
      if (str) {
        return path.split(str).pop()
      } else {
        return path.split('/').pop()
      }
    } else {
      return ''
    }
  }
  getSFileName(path, str) {
    //获取文件名-不含后缀名
    let name = this.getFileName(path, str)
    if (name) {
      return name.split('.')[0]
    } else {
      return name
    }
  }
  downloadHref(url, fileName) {
    // 如果是在网页中可以直接创建一个 a 标签直接下载
    let a = document.createElement('a')
    a.href = url
    if (fileName) {
      a.download = fileName
    }
    a.click()
  }
  downloadFile(url, fileName) {
    let suffix = this.getSuffix(url)
    if (suffix.length < 5) {
      this.getBlob(url, (blob) => {
        let suffix = this.getSuffix(url)
        this.saveAs(blob, fileName + '.' + suffix)
      })
    } else {
      this.downloadHref(url, fileName)
    }
  }
  getBlob(url, cb) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if (xhr.status === 200) {
        cb(xhr.response)
      }
    }
    xhr.send()
  }
  saveAs(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename)
    } else {
      var link = document.createElement('a')
      var body = document.querySelector('body')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.style.display = 'none'
      body.appendChild(link)
      link.click()
      body.removeChild(link)
      window.URL.revokeObjectURL(link.href)
    }
  }
  downImg(url, fileName) {
    const allUrl = this.imgPath(url)
    fetch(allUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // 将链接地址字符内容转变成blob地址
        this.saveAs(blob, fileName)
      })
  }
  hasDomClass(evt, className) {
    if (evt.domClass?.indexOf(className) >= 0) {
      return true
    }
    return false
  }
}
export default KOApi
