import GeoJSON from 'ol/format/GeoJSON'
import { Fill, Stroke } from 'ol/style'
import AddPolygon from '@/plug/map/openLayer/polygon/AddPolygon.js'
import { toLonLat, fromLonLat } from 'ol/proj'
import TypeIs from '@/api/TypeIs.js'
class KOApi {
  geoPoly = {
    defaultStyle: {
      stroke: new Stroke({
        color: 'rgba(22, 127, 230, 1)',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(22, 127, 230, 0.1)'
      })
    },
    selectStyle: {
      stroke: new Stroke({
        color: 'rgba(22, 127, 230, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(22, 127, 230, 0.1)'
      })
    }
  }
  setCenter_byFeatures(features) {
    //给Features设置中心点
    let coord
    if (features?.length) {
      for (let i = 0; i < features.length; i++) {
        if (features[i]?.properties) {
          if (features[i]?.properties?.center?.length == 2) {
            continue
          } else {
            //如果没有center字段，则需要通过轮廓来计算一个
            let maxLength = 0,
              index = 0
            coord = features[i].geometry.coordinates

            for (let j = 0; j < coord.length; j++) {
              if (coord[j].length > maxLength) {
                maxLength = coord[j].length
                index = j
              }
            }
            features[i].properties.center = this.getPolyCenter(coord[index]) //把center集合的center计算出来
          }
        } else {
          console.error(
            'features的item属性properties异常，请检查',
            features[i].properties
          )
        }
      }
    } else {
      console.error('请传入features', features)
    }
  }
  getPolyCenter(arr) {
    //获取所有轮廓的中心经纬度，arr：轮廓数组
    let max = [0, 0],
      min = [999, 999]
    for (let i = 0; i < arr.length; i++) {
      if (max[0] < arr[i][0]) {
        max[0] = arr[i][0]
      }
      if (max[1] < arr[i][1]) {
        max[1] = arr[i][1]
      }
      if (min[0] > arr[i][0]) {
        min[0] = arr[i][0]
      }
      if (min[1] > arr[i][1]) {
        min[1] = arr[i][1]
      }
    }
    let center = [
      (max[0] - min[0]) / 2 + min[0],
      (max[1] - min[1]) / 2 + min[1]
    ]
    return center
  }
  verFeatures(ret) {
    let features = []
    if (TypeIs.Object(ret)) {
      if (ret?.features?.length) {
        features = ret.features
      } else {
        console.error('您传入的geoJson数据对象没有找到features数组', ret)
      }
    } else if (TypeIs.Array(ret)) {
      if (ret?.length) {
        features = ret
      } else {
        features = []
        console.error('您传入的features数组数据为空', ret)
      }
    }
    return features
  }
  getGeoJson(ret) {
    //ret:{geoJson:{features:[]}}
    let features = []
    if (TypeIs.Object(ret)) {
      if (ret?.features?.length) {
        features = ret.features
      } else {
        console.error('您传入的geoJson数据对象没有找到features数组', ret)
      }
    } else if (TypeIs.Array(ret)) {
      if (ret?.length) {
        features = ret
      } else {
        features = []
        console.error('您传入的features数组数据为空', ret)
      }
    }
    return features
  }
  getData_features(ret) {
    //ret:{mapPlug:{},geoJson:{features:[]}}
    let geoJson = this.getGeoJson(ret.geoJson)
    let jsonObj
    if (ret.projection == 'EPSG:3857') {
      jsonObj = this.reGeo_4326to3857(geoJson)
    } else {
      jsonObj = ret.geoJson
    }
    jsonObj.type = 'FeatureCollection'
    jsonObj.crs = {
      type: 'name',
      properties: {
        name: 'EPSG:3857'
      }
    }
    let feats = new GeoJSON({
      dataProjection: 'EPSG:3857',
      featureProjection: 'EPSG:3857'
    }).readFeatures(jsonObj, {
      dataProjection: 'EPSG:3857',
      featureProjection: 'EPSG:3857'
    })
    for (let i = 0; i < feats.length; i++) {
      feats[i].index = i
      feats[i].data = jsonObj.features[i].properties
    }
    return feats
  }
  reJson_4326to3857(ret) {
    //地理国标[114.05,22.37] 转 摩卡托[12914838.35,4814529.9]
    let feats = []
    let geoJson
    if (ret?.length == 1 && ret[0].length > 2) {
      geoJson = ret[0]
    } else {
      geoJson = ret
    }
    for (let item of geoJson) {
      feats.push(fromLonLat(item))
    }
    return feats
  }
  reJson_3857to4326(geoJson) {
    //摩卡托[12914838.35,4814529.9] 转 地理国标[114.05,22.37]
    let feats = []
    for (let item of geoJson) {
      feats.push(toLonLat(item))
    }
    return feats
  }
  getFeatures_4326(ret, key) {
    //获取国标地理特征不转换直接输入国标geo特征
    let feats = this.verFeatures(ret) //验证数据是否是标准的地理特征格式
    let geoObj = {
      features: feats,
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326'
        }
      }
    }
    return this.getGeoFeatures(geoObj, key)
  }
  getFeatures_3857(ret) {
    //获取摩卡托地理特征
    let geoJson = this.verFeatures(ret) //验证数据是否是标准的地理特征格式
    let jsonObj = this.reGeo_4326to3857(geoJson) //摩卡托转国标
    //由于地图是使用的摩卡托投影，这里需要先把国标转为摩卡托[12914838.35,4814529.9]
    return this.getGeoFeatures(jsonObj)
  }
  getGeojson_4326(ret) {
    //获取国标地理特征不转换直接输入国标geo特征
    let feats = this.verFeatures(ret) //验证数据是否是标准的地理特征格式
    let geoObj = {
      features: feats,
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326'
        }
      }
    }
    return geoObj
  }
  getGeojson_3857(ret) {
    //获取摩卡托地理特征
    let geoJson = this.verFeatures(ret) //验证数据是否是标准的地理特征格式
    let jsonObj = this.reGeo_4326to3857(geoJson) //摩卡托转国标
    //由于地图是使用的摩卡托投影，这里需要先把国标转为摩卡托[12914838.35,4814529.9]
    return jsonObj
  }
  getGeoFeatures(jsonObj, key) {
    let feats = new GeoJSON().readFeatures(jsonObj)
    for (let i = 0; i < feats.length; i++) {
      feats[i].index = i
      if (jsonObj.features[i].properties) {
        feats[i].key = key
        feats[i].data = jsonObj.features[i].properties
      }
    }
    return feats
  }
  reGeo_4326to3857(geoJson) {
    //geoJson 国标转摩卡托
    let feats = []
    for (let item of geoJson) {
      let geos = {
        type: 'Feature',
        properties: item.properties,
        geometry: {
          coordinates: [],
          type: 'Polygon'
        }
      }
      for (let coord of item?.geometry?.coordinates) {
        geos.geometry.coordinates.push(this.reJson_4326to3857(coord)) //json 国标转摩卡托
      }
      feats.push(geos)
    }
    return {
      features: feats,
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:3857'
        }
      }
    }
  }
  reData_coords(coord) {
    let reData = []
    if (coord.length == 2) {
      return fromLonLat(coord)
    } else {
      for (let item of coord) {
        reData.push(fromLonLat(item))
      }
      return reData
    }
  }
  viewFeature(ret) {
    //ret:{mapPlug:{},geoJson:{features:[]}}
    if (ret.geoJson?.features?.length) {
      const feat = this.getData_features(ret)
      if (feat && feat[0]) {
        const polygon = feat[0].getGeometry()
        let pd = [90, 10, 100, 10]
        if (ret?.padding?.length == 4) {
          pd = ret.padding
        }
        ret.mapPlug.view.fit(polygon, {
          padding: pd
        })
      }
    } else {
      console.log('未传入geojson，无法定位')
    }
  }
  mapView(mapPlug, arr, padding) {
    if (arr) {
      let reArr = [],
        lg,
        lg2
      if (mapPlug.projection == 'EPSG:4326') {
        reArr = arr
      } else {
        lg = this.reData_coords([arr[0], arr[1]])
        lg2 = this.reData_coords([arr[2], arr[3]])
        reArr = [...lg, ...lg2]
      }
      mapPlug.view.fit(reArr, { padding: padding ?? [30, 10, 30, 10] })
    }
  }
  addMask(ret) {
    let newOuterJson = this.reData_outer(ret)
    if (this.PlugOuterPolygon) {
      this.PlugOuterPolygon.remove()
      this.PlugOuterPolygon.addLayerPolygon(newOuterJson)
    } else {
      this.PlugOuterPolygon = new AddPolygon({
        map: ret.map,
        defaultStyle: {
          stroke: new Stroke({
            color: 'rgba(135, 222, 255, 1)',
            width: 1
          }),
          fill: new Fill({
            color: 'rgba(5, 30, 70, 1)'
          })
        },
        zIndex: 10,
        addSelectEvent: false,
        data: newOuterJson
      })
    }
  }
  reData_outer(ret) {
    let outer = [
      [-360, -90],
      [-360, 90],
      [360, 90],
      [360, -90]
    ]
    let outerCoord = ret.outerJson.features[0]?.geometry?.coordinates
    let newCoord = [outer, ...outerCoord]
    return {
      features: [
        {
          geometry: {
            coordinates: newCoord,
            type: 'Polygon'
          },
          properties: {},
          type: 'Feature'
        }
      ],
      type: 'FeatureCollection'
    }
  }
  getMapScale(view) {
    //比例尺
    const DEFAULT_DPI = 25.4 / 0.28
    //每米多少英寸
    const inchesPerMeter = 1000 / 25.4
    let currentScale = 1
    const resolution = view.getResolution()
    const projection = view.getProjection()
    //如果是度分秒的话需要转换为米
    if (projection.getUnits() != 'metric') {
      currentScale =
        resolution *
        projection.getMetersPerUnit() *
        inchesPerMeter *
        DEFAULT_DPI
    } else {
      currentScale = resolution * inchesPerMeter * DEFAULT_DPI
    }
    const mapScale = '1 : ' + Math.round(currentScale).toLocaleString()
    return mapScale
  }
  /**
   *获取point点是否在多边形polygon中
   * @param {Array} point //经纬度[lon,lat]
   * @param {Array} polygon //经纬度数组集合[[lon,lat],[lon,lat]]
   * @returns
   */
  isPointInPolygon(point, polygon) {
    //下述代码来源：http://paulbourke.net/geometry/insidepoly/，进行了部分修改
    //基本思想是利用射线法，计算射线与多边形各边的交点，如果是偶数，则点在多边形外，否则
    //在多边形内。还会考虑一些特殊情况，如点在多边形顶点上，点在多边形边上等特殊情况。

    var N = polygon.length
    var boundOrVertex = true //如果点位于多边形的顶点或边上，也算做点在多边形内，直接返回true
    var intersectCount = 0 //cross points count of x
    var precision = 2e-10 //浮点类型计算时候与0比较时候的容差
    var p1, p2 //neighbour bound vertices
    var p = point //测试点

    p1 = polygon[0] //left vertex
    for (var i = 1; i <= N; ++i) {
      //check all rays
      if (p[0] == p1[0] && p[1] == p1[1]) {
        return boundOrVertex //p is an vertex
      }

      p2 = polygon[i % N] //right vertex
      if (p[1] < Math.min(p1[1], p2[1]) || p[1] > Math.max(p1[1], p2[1])) {
        //ray is outside of our interests
        p1 = p2
        continue //next ray left point
      }

      if (p[1] > Math.min(p1[1], p2[1]) && p[1] < Math.max(p1[1], p2[1])) {
        //ray is crossing over by the algorithm (common part of)
        if (p[0] <= Math.max(p1[0], p2[0])) {
          //x is before of ray
          if (p1[1] == p2[1] && p[0] >= Math.min(p1[0], p2[0])) {
            //overlies on a horizontal ray
            return boundOrVertex
          }

          if (p1[0] == p2[0]) {
            //ray is vertical
            if (p1[0] == p[0]) {
              //overlies on a vertical ray
              return boundOrVertex
            } else {
              //before ray
              ++intersectCount
            }
          } else {
            //cross point on the left side
            var xinters =
              ((p[1] - p1[1]) * (p2[0] - p1[0])) / (p2[1] - p1[1]) + p1[0] //cross point of x
            if (Math.abs(p[0] - xinters) < precision) {
              //overlies on a ray
              return boundOrVertex
            }

            if (p[0] < xinters) {
              //before ray
              ++intersectCount
            }
          }
        }
      } else {
        //special case when ray is crossing through the vertex
        if (p[1] == p2[1] && p[0] <= p2[0]) {
          //p crossing over p2
          var p3 = polygon[(i + 1) % N] //next vertex
          if (
            p[1] >= Math.min(p1[1], p3[1]) &&
            p[1] <= Math.max(p1[1], p3[1])
          ) {
            //p[1] lies between p1[1] & p3[1]
            ++intersectCount
          } else {
            intersectCount += 2
          }
        }
      }
      p1 = p2 //next ray left point
    }

    if (intersectCount % 2 == 0) {
      //偶数在多边形外
      return false
    } else {
      //奇数在多边形内
      return true
    }
  }
  mymax(a, b) {
    if (a === null) return b
    if (b === null) return a

    if (a > b) return a
    return b
  }
  mymin(a, b) {
    if (a === null) return b
    if (b === null) return a
    if (a < b) return a
    return b
  }
  getBoundsByPolygon(polygons) {
    let minLon = 180,
      minLat = 90,
      maxLon = 0,
      maxLat = 0
    for (let item of polygons) {
      minLon = this.mymin(minLon, item[0])
      minLat = this.mymin(minLat, item[1])
      maxLon = this.mymax(maxLon, item[0])
      maxLat = this.mymax(maxLat, item[1])
    }
    return [minLon, minLat, maxLon, maxLat]
  }
  getBoundsByGeojson(geoJson) {
    let polygons = []
    for (let item of geoJson.features) {
      polygons.push(...item.geometry.coordinates[0])
    }
    let bounds = this.getBoundsByPolygon(polygons)
    return bounds
  }
  setPolygon(ret) {
    //ret:{mapPlug:{},geoJson:{features:[]}}
    //在地图上绘制多边形
    let mapPlug = ret?.mapPlug
    let geoJson = ret?.geoJson
    if (this.PlugPolygon) {
      this.PlugPolygon.remove()
      if (geoJson?.features?.length) {
        this.PlugPolygon.addLayerPolygon(geoJson)
      }
    } else {
      if (geoJson?.features?.length) {
        this.PlugPolygon = new AddPolygon({
          map: mapPlug.map,
          data: geoJson,
          defaultStyle: this.geoPoly.defaultStyle,
          selectStyle: this.geoPoly.selectStyle
        })
        this.viewFeature(ret)
      } else {
        this.PlugPolygon?.remove()
      }
    }
  }
}

export default new KOApi()
