let typeIs = {
  types: [
    'Array',
    'Boolean',
    'Date',
    'Number',
    'Object',
    'RegExp',
    'String',
    'Window',
    'HTMLDocument'
  ]
}
for (var i = 0, c; (c = typeIs.types[i++]); ) {
  typeIs[c] = (function (type) {
    return function (obj) {
      return Object.prototype.toString.call(obj) == '[object ' + type + ']'
    }
  })(c)
}
export default typeIs
