<template>
  <div class="cpn-title-b">
    <div class="title">
      <i class="i-left"></i>
      <div class="name">{{ name }}</div>
      <div class="other">
        <i class="i-line"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    other: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-title-b {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  .title {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 7px 0;
    .i-left {
      width: 4px;
      height: 16px;
      margin-right: 10px;
      background: #00a2ff;
      color: #fff;
      flex-shrink: 0;
    }
    .i-line {
      position: absolute;
      top: 50%;
      left: 10px;
      right: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
    }
    .name {
      flex-shrink: 0;
      margin-right: 5px;
      color: rgba(255, 255, 255, 0.8);
      font-weight: bold;
    }
  }
  .other {
    position: relative;
    flex-grow: 1;
    cursor: pointer;
    &:after {
      position: absolute;
      right: 5px;
      top: 50%;
      content: '';
      display: block;
      width: 20px;
      height: 5px;
      transform: translate(0, -50%);
      background: #1368a1;
      flex-shrink: 0;
    }
  }
}
</style>
