import TypeIs from '@/api/TypeIs.js'
class KOApi {
  isForecast(date) {
    return (
      date?.Format('time') >=
      new Date().Format('YYYY-MM-DD 00:00:00').Format('time')
    )
  }
  getDate(ret) {
    let now = new Date(),
      fmt = 'yyyy-MM-dd'
    let dict = {
      now: now.DateAdd('d', 8).Format(fmt)
    }
    if (TypeIs.Number(ret)) {
      return new Date().DateAdd('d', ret).Format('yyyy-MM-dd')
    } else if (TypeIs.String(ret)) {
      if (dict[ret]) {
        return dict[ret]
      } else {
        return now.Format(fmt)
      }
    } else {
      return now.Format(fmt)
    }
  }
  getDateTime(ret) {
    let now = new Date(),
      fmt = 'yyyy-MM-dd hh:00'
    let dict = {
      now: now.Format(fmt)
    }
    if (TypeIs.Number(ret)) {
      return new Date().DateAdd('h', ret).Format('yyyy-MM-dd hh:00')
    } else if (TypeIs.String(ret)) {
      if (dict[ret]) {
        return dict[ret]
      } else {
        return now.Format(fmt)
      }
    } else {
      return now.Format(fmt)
    }
  }
  testDefaultDate(type) {
    let dateType = type ?? 'date'
    if (process.env.NODE_ENV == 'development') {
      let obj = {}
      if (dateType == 'datetime') {
        obj = {
          startDate: '2021-03-31 16:00:00',
          endDate: '2021-04-01 16:00:00'
        }
      } else if (dateType == 'date') {
        obj = {
          startDate: '2021-03-04',
          endDate: '2021-03-06'
        }
      } else if (dateType == 'xun') {
        obj = {
          startDate: '2021-03-01',
          endDate: '2021-03-10'
        }
      } else {
        obj = {
          startDate: '2021-03-04',
          endDate: '2021-03-06'
        }
      }
      obj.type = dateType
      return obj
    } else {
      return this.getDefaultDate(dateType)
    }
  }
  getDefaultDate(type) {
    let picker = {
      type: type
    }
    if (type == 'datetime') {
      picker.startDate = new Date()
        .DateAdd('h', -23)
        .Format('YYYY-MM-DD HH:00:00')
      picker.endDate = new Date().Format('YYYY-MM-DD HH:00:00')
    } else if (type == 'date') {
      picker.startDate = new Date().DateAdd('d', -1).Format('YYYY-MM-DD')
      picker.endDate = new Date().DateAdd('d', -1).Format('YYYY-MM-DD')
    } else if (type == 'xun') {
      let thisXun = this.XunFormat(new Date())
      picker.startDate = this.XunFormat(thisXun.DateAdd('d', -1))
      picker.endDate = this.XunFormat(thisXun.DateAdd('d', -1), 'end')
    } else if (type == 'month') {
      picker.startDate = new Date().DateAdd('m', -1).Format('YYYY-MM-01')
      picker.endDate = new Date()
        .Format('YYYY-MM-01')
        .DateAdd('d', -1)
        .Format('YYYY-MM-DD')
    } else if (type == 'year') {
      picker.startDate = new Date().DateAdd('y', -1).Format('YYYY-01-01')
      picker.endDate = new Date()
        .Format('YYYY-01-01')
        .DateAdd('d', -1)
        .Format('YYYY-MM-DD')
    }
    return picker
  }
  DateFormat(date, type) {
    let types = {
      datetime: 'YYYY年MM月DD日hh时',
      date: 'YYYY年MM月DD日',
      xun: 'YYYY年MM月DD日',
      month: 'YYYY年MM月',
      ji: 'YYYY年MM月',
      year: 'YYYY年'
    }
    let fmtDate = ''
    if (date) {
      if (type == 'xun') {
        fmtDate = this.XunFormatName(date)
      } else if (type == 'ji') {
        fmtDate = this.JiFormatName(date)
      } else {
        fmtDate = date.Format(types[type])
      }
    } else {
      console.error('date数据未定义')
    }
    return fmtDate
  }
  Xun2Date(month, xun, type) {
    let startFmt = {
      上旬: 'YYYY-MM-01',
      中旬: 'YYYY-MM-11',
      下旬: 'YYYY-MM-21'
    }
    let endFmt = { 上旬: 'YYYY-MM-10', 中旬: 'YYYY-MM-20' }
    let date = ''
    if (month) {
      if (['上旬', '中旬', '下旬'].includes(xun)) {
        if (type == 'end') {
          if (xun == '下旬') {
            date = month
              .Format('YYYY-MM-01')
              .DateAdd('m', 1)
              .DateAdd('d', -1)
              .Format('YYYY-MM-DD')
          } else {
            date = month.Format(endFmt[xun])
          }
        } else {
          date = month.Format(startFmt[xun])
        }
      } else {
        console.error('意料之外的xun字段:', xun + '只能为“上旬, 中旬, 下旬”')
      }
    }
    return date.Format('MM-DD')
  }
  XunFormat(date, se) {
    //date:时间,se:'start','end'
    let day = 1,
      month = '',
      reDate = ''
    if (date) {
      month = date.Format('YYYY-MM')
      day = date.Format('DD')
      if (day >= 21) {
        if (se == 'end') {
          reDate = month
            .Format('YYYY-MM-01')
            .DateAdd('m', 1)
            .DateAdd('d', -1)
            .Format('YYYY-MM-DD')
        } else {
          reDate = month + '-21'
        }
      } else if (day >= 11 && day < 21) {
        if (se == 'end') {
          reDate = month + '-20'
        } else {
          reDate = month + '-11'
        }
      } else {
        if (se == 'end') {
          reDate = month + '-10'
        } else {
          reDate = month + '-01'
        }
      }
      return reDate
    } else {
      console.error('XunFormat请传入时间参数：', date)
      return ''
    }
  }
  XunFormatName(date) {
    let day = 1,
      month = '',
      xun = ''
    if (date) {
      month = date.Format('YYYY年MM月')
      day = date.Format('DD')
      if (day >= 21) {
        xun = '下旬'
      } else if (day >= 11 && day < 21) {
        xun = '中旬'
      } else {
        xun = '上旬'
      }
      return month + xun
    } else {
      console.error('XunFormatName请传入时间参数：', date)
      return ''
    }
  }
  JiFormatName(date) {
    let month = 1,
      year = '',
      ji = ''
    if (date) {
      year = date.Format('YYYY年')
      month = date.Format('MM')
      if (month >= 10) {
        ji = '四季度'
      } else if (month >= 7 && month < 10) {
        ji = '三季度'
      } else if (month >= 3 && month < 7) {
        ji = '二季度'
      } else {
        ji = '一季度'
      }
      return year + ji
    } else {
      console.error('JiFormatName请传入时间参数：', date)
      return ''
    }
  }
  DateFormatString(data, type) {
    let types = {
      datetime: 'YYYY-MM-DD hh:mm:ss',
      date: 'YYYY-MM-DD',
      xun: 'YYYY-MM',
      month: 'YYYY-MM',
      ji: 'YYYY',
      year: 'YYYY'
    }
    if (data) {
      return data.Format(types[type])
    } else {
      console.error('data数据未定义')
      return ''
    }
  }
  FormatSEDate(ret) {
    let def = {
      type: 'date', //year、month、date、datetime
      startDate: '',
      endDate: '',
      triggerType: '' //startDate,endDate
    }
    let opt = Object.assign({}, def, ret)
    let reData = {
      startDate: '',
      endDate: ''
    }
    if (opt.startDate && opt.endDate) {
      reData = this.reSEDate(opt.startDate, opt.endDate, opt.triggerType)
      if (opt.type === 'year') {
        reData.startDate = reData.startDate.Format('YYYY-01-01')
        reData.endDate = reData.endDate
          .Format('YYYY-01-01')
          .DateAdd('y', 1)
          .DateAdd('d', -1)
          .Format('YYYY-MM-DD')
      } else if (opt.type === 'month') {
        reData.startDate = reData.startDate.Format('YYYY-MM-01')
        reData.endDate = reData.endDate
          .Format('YYYY-MM-01')
          .DateAdd('m', 1)
          .DateAdd('d', -1)
          .Format('YYYY-MM-DD')
      } else if (opt.type === 'date') {
        reData.startDate = reData.startDate.Format('YYYY-MM-DD')
        reData.endDate = reData.endDate.Format('YYYY-MM-DD')
      } else {
        reData.startDate = reData.startDate.Format('YYYY-MM-DD HH:mm:ss')
        reData.endDate = reData.endDate.Format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      console.error('请传入正确的开始结束时间')
      return reData
    }
    return reData
  }
  sameSEDate(start, end, trigger) {
    let reData = {
      startDate: start,
      endDate: end
    }
    if (
      reData.startDate &&
      reData.endDate &&
      reData.startDate.Format('time') &&
      reData.endDate.Format('time')
    ) {
      if (reData.startDate.Format('time') > reData.endDate.Format('time')) {
        if (trigger == 'startDate') {
          reData.endDate = reData.startDate
        } else {
          reData.startDate = reData.endDate
        }
      }
      return reData
    } else {
      console.error('请传入正确的开始结束时间')
      return reData
    }
  }
  reSEDate(start, end, triggerType) {
    let reData = {
      startDate: start,
      endDate: end
    }
    if (
      reData.startDate &&
      reData.endDate &&
      reData.startDate.Format('time') &&
      reData.endDate.Format('time')
    ) {
      if (reData.startDate.Format('time') > reData.endDate.Format('time')) {
        if (triggerType == 'startDate') {
          reData.endDate = reData.startDate
        } else {
          reData.startDate = reData.endDate
        }
        reData.change = true
      }
      return reData
    } else {
      console.error('请传入正确的开始结束时间')
      return reData
    }
  }
  limitDate(ret) {
    let def = {
      num: 10,
      type: 'date', //year、month、date、datetime
      startDate: '',
      endDate: '',
      trigger: 'startDate' //触发时间:如果触发时间是开始时间，则变更结束时间。
    }
    //triggerType：如果触发的是开始时间，当超过‘num’最大长度时间，则会把结束时间强制约束为‘num’周期的最后一个时间
    //比如 开始时间是 2022-01-02，结束时间选了2022-01-20，num为10
    //如果triggerType为startDate，则会变更结束时间为2022-01-12，开始时间不变
    //否则会变更开始时间为2022-01-10，结束时间不变

    if (ret.type == 'datetime') {
      def.num = 24
    } else {
      def.num = 10
    }
    let opt = Object.assign({}, def, ret)
    let dayNum = opt.num - 1
    let reData = {
      startDate: opt.startDate,
      endDate: opt.endDate
    }
    let unitDict = { date: '日', month: '个月', year: '年', datetime: '小时' }
    let limitEndDate, limitStartDate
    if (opt.type === 'year') {
      reData.startDate = reData.startDate.Format('YYYY-01-01')
      if (opt.trigger === 'startDate') {
        limitEndDate = reData.startDate
          .DateAdd('y', dayNum)
          .DateAdd('m', 1)
          .DateAdd('d', -1)
      } else {
        limitStartDate = reData.endDate
          .DateAdd('y', -dayNum)
          .Format('YYYY-01-01')
      }
    } else if (opt.type === 'month') {
      reData.startDate = reData.startDate.Format('YYYY-MM-01')
      if (opt.trigger === 'startDate') {
        limitEndDate = reData.startDate.DateAdd('m', dayNum).DateAdd('d', -1)
      } else {
        limitStartDate = reData.endDate
          .DateAdd('m', -dayNum)
          .Format('YYYY-MM-01')
      }
    } else if (opt.type === 'date') {
      reData.startDate = reData.startDate.Format('YYYY-MM-DD')
      if (opt.trigger === 'startDate') {
        limitEndDate = reData.startDate.DateAdd('d', dayNum)
      } else {
        limitStartDate = reData.endDate.DateAdd('d', -dayNum)
      }
    } else {
      reData.startDate = reData.startDate.Format('YYYY-MM-DD HH:mm:ss')
      if (opt.trigger === 'startDate') {
        limitEndDate = reData.startDate.DateAdd('h', dayNum)
      } else {
        limitStartDate = reData.endDate.DateAdd('h', -dayNum)
      }
    }
    if (opt.trigger === 'startDate') {
      if (reData.endDate.Format('time') > limitEndDate.Format('time')) {
        if (opt.type === 'datetime') {
          reData.endDate = limitEndDate.Format('YYYY-MM-DD HH:mm:ss')
        } else {
          reData.endDate = limitEndDate.Format('YYYY-MM-DD')
        }
        this.msg({
          message:
            unitDict[opt.type] +
            '类型：开始、结束时间间隔不能超过' +
            opt.num +
            unitDict[opt.type],
          type: 'warning'
        })
      }
    } else {
      if (reData.startDate.Format('time') < limitStartDate.Format('time')) {
        if (opt.type === 'datetime') {
          reData.startDate = limitStartDate.Format('YYYY-MM-DD HH:mm:ss')
        } else {
          reData.startDate = limitStartDate.Format('YYYY-MM-DD')
        }
        this.msg({
          message:
            unitDict[opt.type] +
            '类型：开始、结束时间间隔不能超过' +
            opt.num +
            unitDict[opt.type],
          type: 'warning'
        })
      }
    }
    return reData
  }
  getSubTitle(formData) {
    if (formData.startDate && formData.endDate) {
      if (
        formData.startDate.Format('time') == formData.endDate.Format('time')
      ) {
        return formData.startDate.Format('YYYY年MM月DD日')
      } else {
        return (
          formData.startDate.Format('YYYY年MM月DD日') +
          '至' +
          formData.endDate.Format('YYYY年MM月DD日')
        )
      }
    } else {
      return ''
    }
  }
  isNew(time, day = 7) {
    return new Date().Format('time') - time.Format('time') < 86400000 * day
  }
}
export default KOApi
