/*
传入{mapPlug:{},url:'url',layer:new VectorTileLayer()}
性能较差
*/

import _ from 'lodash'
import GeoJSON from 'ol/format/GeoJSON'
import VectorImageLayer from 'ol/layer/VectorImage'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import OMU from '@/plug/map/openLayer/utils/utils.js'
import {MVT} from 'ol/format'
import { VectorTile as VectorTileSource} from 'ol/source'
import { VectorTile as VectorTileLayer,VectorTileLayerRenderOrder  } from 'ol/layer';
class KOClass {
  mapPlug
  highlight
  features
  plugKey = 'VIPolygon'
  style = new Style({
    fill: new Fill({
      color: '#eeeeee'
    }),
    stroke: new Stroke({
      color: '#FFF',
      width: 2
    })
  })
  defaultStyle = () => {
    return this.style
  }
  selectStyle = () => {
    return this.style
  }
  constructor(config) {
    _.merge(this, config)
    this.init()
  }
  init() {
    if (this.mapPlug) {
      if (!this.vectorLayer) {
        this.initLayer(this.features)
      }
    } else {
      console.error('未传入地图组件')
    }
    this.initEvent()
  }
  getFeats(ret) {
    if (this.mapPlug.projection == 'EPSG:4326') {
      //如果地图是国标，则不转换直接输出
      return OMU.getFeatures_4326(ret, this.plugKey)
    } else {
      //如果地图是摩卡托，则需要把国标转换为摩卡托再输出
      return OMU.getFeatures_3857(ret, this.plugKey)
    }
  }
  initLayer(ret) {
    // console.log(`ret987 :>>`, ret)
    let feats = this.getFeats(ret)
    this.vectorSource = new VectorSource({
      features: feats,
      format: new GeoJSON()
    })
    this.vectorLayer = new VectorImageLayer({
      imageRatio: 2,
      source: this.vectorSource,
      style: this.defaultStyle,
      zIndex: 10
    })

    this.featureOverlay = new VectorLayer({
      source: new VectorSource(),
      mapPlug: this.mapPlug,
      style: this.selectStyle,
      zIndex: 12
    })
    this.mapPlug.map.addLayer(this.vectorLayer)
    this.mapPlug.map.addLayer(this.featureOverlay)

    // const vectorTileSource = new VectorTileSource({
    //   projection: "EPSG:4326",
    //   format: new MVT(),
    //   url: 'http://192.168.101.155:5048/digital/getVectorTileZYX/{z}/{y}/{x}', // 更换为你的矢量切片服务URL
    // });
    
    // // 创建矢量切片图层
    // const vectorTileLayer = new VectorTileLayer({
    //   declutter: true,
    //   source: vectorTileSource,
    //   renderMode: 'vector', // 或者 'vector'，'hybrid'模式下会优先渲染矢量切片，然后是瓦片
    //   // renderOrder: VectorTileLayerRenderOrder.LAYER_ORDER, // 如果矢量切片中有多个图层，可以通过此选项指定渲染顺序
    //   style: new Style({
    //     fill: new Fill({               //填充样式
    //       color: 'rgba(43, 233, 233, 0.45)'  
    //     }),
    //   })
    // });
    // // this.mapPlug.map.
    // this.mapPlug.map.addLayer(vectorTileLayer)

    // this.mapPlug.map.getView().setCenter( [106.721956580048, 30.7837992047785])
  }
  setFeatures(ret) {
    if (this.highlight) {
      this.featureOverlay.getSource().removeFeature(this.highlight)
    }
    let feats = this.getFeats(ret)
    this.vectorLayer.setSource(
      new VectorSource({
        features: feats,
        format: new GeoJSON()
      })
    )
  }
  remove() {
    this.vectorLayer.setSource(
      new VectorSource({
        features: [],
        format: new GeoJSON()
      })
    )
  }
  displayFeatureInfo(pixel) {
    const feature = this.mapPlug.map.forEachFeatureAtPixel(
      pixel,
      function (feature) {
        return feature
      }
    )
    if (feature?.key == this.plugKey) {
      if (feature !== this.highlight) {
        if (this.highlight) {
          this.featureOverlay.getSource().removeFeature(this.highlight)
        }
        this.featureOverlay.getSource().addFeature(feature)
        this.highlight = feature
      }
    }
    return feature
  }
  setCurrentFeature(coord) {
    let pixel = this.mapPlug.map.getPixelFromCoordinate(coord)
    return this.displayFeatureInfo(pixel)
  }
  initEvent() {
    this.mapPlug.map.on('click', (evt) => {
      const pixel = this.mapPlug.map.getEventPixel(evt.originalEvent)
      this.displayFeatureInfo(pixel)
    })
  }
}

export default KOClass
