<template>
  <div class="cpn-title-base">
    <div class="name">
      <div class="left-line"></div>
      <div class="right-line"></div>
      {{ name }}
    </div>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-title-base {
  display: flex;
  align-items: center;
  justify-content: center;
  .name {
    position: relative;
    padding: 6px 10px;
    color: #fff;
    font-size: 16px;
    background-image: linear-gradient(
      rgba(24, 142, 210, 0.1),
      rgba(24, 126, 210, 0.3),
      rgba(24, 142, 210, 0.1)
    );
    border-radius: 5px;
    font-weight: bold;
    .left-line {
      position: absolute;
      top: 50%;
      left: -60px;
      width: 70px;
      margin-top: -1px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        position: absolute;
        left: 50%;
        top: -2px;
        content: '';
        display: block;
        height: 6px;
        width: 15px;
        transform: translate(-50%, 0);
        background: var(--ko-bd-color);
      }
    }
    .right-line {
      position: absolute;
      top: 50%;
      right: -60px;
      width: 70px;
      margin-top: -1px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        position: absolute;
        left: 50%;
        top: -2px;
        content: '';
        display: block;
        height: 6px;
        width: 15px;
        transform: translate(-50%, 0);
        background: var(--ko-bd-color);
      }
    }
  }
}
</style>
