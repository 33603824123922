<template>
  <div class="box-base-info">
    <CpnTitle title="基地概况" class="mgb-30"></CpnTitle>
    <CpnTitleBase :name="MapWRoot?.farmInsure?.companyBaseName" class="mgb-20"></CpnTitleBase>
    <!-- <CpnTitleC :name="MapWRoot?.farmInsure?.underwriting_time?.Format('YYYY') + '年度承保信息'
      "></CpnTitleC> -->
    <CpnTitleC :name="'2024年度承保信息'
      "></CpnTitleC>
    <CpnBoxLabelList :list="baseInfo"></CpnBoxLabelList>
    <CpnTitleC name="历年赔付金额"></CpnTitleC>
    <CpnBoxLabelList :list="baseMoneyInfo"></CpnBoxLabelList>
  </div>
</template>
<script>
import CpnTitle from '@/components/content/title/CpnTitle.vue';
import CpnTitleC from '@/components/content/title/CpnTitleC.vue';
import CpnTitleBase from '@/components/content/title/CpnTitleBase.vue';
import CpnBoxLabelList from '@/components/content/list/CpnBoxLabelList.vue';
export default {
  inject: ['MapWRoot'],
  components: {
    CpnTitle,
    CpnTitleC,
    CpnTitleBase,
    CpnBoxLabelList
  },
  props: {},
  data() {
    return {
      data: {},
      featureData: {}
    };
  },
  watch: {
    test: function (nl, ol) {
      this.featureData = nl;
    }
  },
  computed: {
    test() {
      return this.$store.state.baodanItem;
    },
    baseInfo() {
      if (!this.$store.state.baodanItem) {
        let labels = [];
        let featureData = this.MapWRoot.farmInsure;

        if (featureData) {
          labels = this.Api.Obj2Arr({
            data: featureData,
            label: [
              {
                key: 'underwriting_type',
                name: '承保类型',
                icon: 'icon-cb chengbaoleixing'
              },
              {
                key: 'total_underwriting_area',
                name: '承保总面积',
                icon: 'icon-cb mianji2'
              },
              {
                key: 'total_underwriting_amount',
                name: '承保总金额',
                icon: 'icon-cb jine'
              },
              {
                key: 'underwriting_time',
                name: '承保时间',
                icon: 'icon-cb shijian',
                reData(e) {
                  return e.Format('YYYY-MM-DD');
                }
              },
              { key: 'customer_name', name: '客户名称', icon: 'icon-cb kehu' },
              {
                key: 'insurance_manager',
                name: '保险经理',
                icon: 'icon-cb jingli'
              }
            ]
          });
        }
        console.log(`labels :>>`, labels);
        labels[2].value = 4800000;
        labels[3].value = '2024-8-31';
        return labels;
      } else {

        let list = [
          {
            key: 'underwriting_type',
            name: '承保类型',
            icon: 'icon-cb chengbaoleixing',
            value: this.$store.state.baodanItem.cblx
          },
          {
            key: 'total_underwriting_area',
            name: '承保总面积',
            icon: 'icon-cb mianji2',
            value: this.$store.state.baodanItem.cbArea
          },
          {
            key: 'total_underwriting_amount',
            name: '承保总金额',
            icon: 'icon-cb jine',
            value: this.$store.state.baodanItem.cbAllMoney
          },
          {
            key: 'underwriting_time',
            name: '承保时间',
            icon: 'icon-cb shijian',
            value: this.$store.state.baodanItem.cbTime
          },
          {
            key: 'customer_name', name: '客户名称', icon: 'icon-cb kehu',
            value: this.$store.state.baodanItem.khName
          },
          {
            key: 'insurance_manager',
            name: '保险经理',
            icon: 'icon-cb jingli',
            value: this.$store.state.baodanItem.bxjl
          }
        ];

        return list;

      }

    },
    baseMoneyInfo() {
      if (!this.$store.state.baodanItem) {
        let labels = [];
        let featureData = this.MapWRoot.farmInsure;
        if (featureData) {
          labels = this.Api.Obj2Arr({
            data: featureData,
            label: [
              {
                key: 'compensation_time',
                name: '赔付时间',
                icon: 'icon-cb shijian',
                reData(e) {
                  return e.Format('YYYY-MM-DD');
                }
              },
              {
                key: 'total_compensation_area',
                name: '赔付总面积',
                icon: 'icon-cb mianji2'
              },
              {
                key: 'total_compensation_amount',
                name: '赔付总金额',
                icon: 'icon-cb jine'
              }
            ]
          });
        }
        return labels;
      } else {
        let list = [
          {
            key: 'compensation_time',
            name: '赔付时间',
            icon: 'icon-cb shijian',
            value: this.$store.state.baodanItem.pfTime
          },
          {
            key: 'total_compensation_area',
            name: '赔付总面积',
            icon: 'icon-cb mianji2',
            value: this.$store.state.baodanItem.pfArea
          },
          {
            key: 'total_compensation_amount',
            name: '赔付总金额',
            icon: 'icon-cb jine',
            value: this.$store.state.baodanItem.pfMoney
          }
        ];
        return list;
      }
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
<style lang="scss" scoped></style>
