import Store from '@/store'
class KOApi {
  /*
   *当前项目的处理
   */
  getKOStore() {
    return Store.state.info.KOStore
  }
  getAreaCode() {
    return this.getKOStore().GArea.value
  }
  imgPath(url) {
    if (url) {
      let reUrl = ''
      let hostUrl = this.imgHost
      if (hostUrl) {
        if (
          hostUrl.charAt(hostUrl.length - 1) == '/' &&
          url?.charAt(0) == '/'
        ) {
          reUrl = url.substr(1)
        } else {
          reUrl = url
        }
      } else {
        console.error('没有定义.env.development的VUE_APP_IMG_SERVICE')
      }
      return hostUrl + reUrl
    } else {
      console.error('注意：图片路径为空：', url)
      return ''
    }
  }
  getPdf(ret) {
    let url = ret?.replace(/(.doc$)|(.docx$)/g, '.pdf')
    return this.imgHost + url
  }
  openOffice(productId) {
    let link = document.createElement('a') // 创建a标签
    link.style.display = 'none'
    const hostPath = this.host
    link.href = `javascript:POBrowser.openWindowModeless('${hostPath}/api/office/editWord?officeId=${productId}&authToken=${localStorage.getItem(
      'authToken'
    )}','width=1200px;height=800px;')` // 设置下载地址
    document.body.appendChild(link)
    link.click()
  }
  getMapConfig() {
    return {
      center: this.getKOStore().mapCenter, //[117, 32],
      viewPosition: [110.3545, 31.3845, 116.64553, 36.36657],
      zoom: 11,
      minZoom: 2,
      maxZoom: 13,
      baseMap: false //是否需要加载街道基础底图1
    }
  }
}
export default KOApi
