<template>
  <div class="cn-img-isogram"></div>
</template>
<script>
import LayerImg from '@/plug/map/openLayer/layer/LayerImg.js';
export default {
  inject: ['MapRoot'],
  components: {},
  created() {
  },

  props: {
    isoImg: {
      type: String,
      default: ''
    },
    bounds: {
      type: Array,
      default: () => []
    },
    imgLegend: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  watch: {
    isoImg: {
      handler() {
        this.initMapImgIsogram();
      },
      immediate: true
    },
    bounds: {
      handler() {
        this.initMapImgIsogram();
      }
    }
  },
  computed: {},
  mounted() {
  },
  methods: {
    initMapImgIsogram() {
      //地图图片叠加
      let bounds =
        this.bounds?.length == 4
          ? this.bounds
          : this.MapRoot.mapPlug.getMapViewBounds();
      if (bounds) {
        if (!this.MapRoot.mapPlug?.PlugImgLayers) {
          this.MapRoot.mapPlug.PlugImgLayers = new LayerImg({
            mapPlug: this.MapRoot.mapPlug,
            extent: bounds,
            imgs: []
          });
        }
        this.MapRoot.mapPlug.PlugImgLayers.update({
          extent: bounds,
          img: this.isoImg
          // img: '/mapPic.png'
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
