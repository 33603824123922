import Api from '@/api/Api.js'
import cookieCache from '@/utils/cookie'
import { Info_login, Info_userInfo } from '@/info/infoUser.js'
import { Info_dictOption } from '@/info/info.js'
const homeModule = {
  namespaced: true,
  state() {
    return {
      init: '',
      storeName: 'user',
      isLogin: '',
      userInfo: false,
      warningDict: {}
    }
  },
  getters: {},
  mutations: {
    initData(state) {
      state.userInfo = false
      //this.dispatch(state.storeName + '/Action_userInfo') //新打开页面时获取用户信息
    },
    saveState(state, ret) {
      state[ret.key] = ret.value
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      this.commit('info/setArea', { value: userInfo?.areaCode ?? Api.areaCode })
    }
  },
  actions: {
    login() {
      let token = cookieCache.get('token')
      if (!token) {
        Info_login().then((ret) => {
          cookieCache.set('token', ret.token)
        })
      }
    },
    Action_authVerify({ dispatch }, form) {
      Info_login(form).then((ret) => {
        if (ret.token) {
          cookieCache.delete('token')
          cookieCache.set('token', ret.token)
          dispatch('Action_userInfo')
        }
      })
    },
    Action_userInfo({ commit }) {
      commit('saveState', { key: 'isLogin', value: '' })
      Info_userInfo()
        .then((ret) => {
          if (ret?.user) {
            commit('saveState', { key: 'isLogin', value: 'is-login' })
            commit('setUserInfo', ret.user)
          } else {
            commit('saveState', { key: 'isLogin', value: 'not-login' })
            commit('setUserInfo', {})
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    Action_warningDict({ commit }) {
      //获取预警信息字典
      Info_dictOption({
        keyword: 'warning_signal_type'
      }).then((ret) => {
        if (ret.data?.length) {
          let list = {}
          for (let item of ret.data) {
            list[item.value] = item.label
          }
          commit('saveState', { key: 'warningDict', value: list })
        }
      })
    }
  }
}

export default homeModule
