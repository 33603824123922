<template>
  <el-dropdown
    placement="top"
    trigger="click"
    class="box-inner box-handle"
    popper-class="is-dark"
    @command="EventCommand"
    @visible-change="EventVisible_baseLayer"
  >
    <div class="handle-button" :class="popShow ? 'current' : ''">
      <i class="icon icon-ditu icon-lh"></i>底图
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="vector.xyz">矢量底图</el-dropdown-item>
        <el-dropdown-item command="terrain.xyz">地形底图</el-dropdown-item>
        <el-dropdown-item command="satellite.xyz">卫星底图</el-dropdown-item>
        <el-dropdown-item command="">无底图</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
export default {
  log: '',
  components: {},
  inject: ['MapRoot'],
  props: {},
  data() {
    return {
      popShow: false
    }
  },
  watch: {
    'MapRoot.mapPlug': {
      handler() {
        //this.initMap()
      }
    }
  },
  computed: {},
  mounted() {
    //DOM初始化完成进行地图初始化
  },
  methods: {
    initMap() {
      //初始化地图
      //console.log(this.MapRoot.mapPlug)
    },
    EventCommand(type) {
      this.MapRoot.mapPlug?.mapBaseImg?.changeLayerType(type)
    },
    EventVisible_baseLayer(bool) {
      this.popShow = bool
    }
  }
}
</script>
<style lang="scss" scoped>
.handle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-right: 5px;
  }
}
</style>
