<template>
  <div class="m-drawer-view">
    <el-drawer
      v-bind="$attrs"
      class="m-drawer-daping"
      size="25%"
      :title="$attrs.title"
      :append-to-body="true"
    >
      <slot></slot>
    </el-drawer>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped></style>
