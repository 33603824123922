import {
  getDomPosition,
  getDomMaxPosition,
  resizeLimitPosition
} from '@/plug/dom/getStyle.js'
// 拖拽的指令
const drag = {
  beforeMount(el, binding) {
    // 自定义属性，判断是否可拖拽
    if (!binding.value) return
    const dialogHeaderEl = el
    const dragDom = el
    dialogHeaderEl.style.cssText += ';cursor:move;'
    // dragDom.style.cssText += ';bottom:0px;'

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX // - dialogHeaderEl.offsetLeft
      const disY = e.clientY //- dialogHeaderEl.offsetTop
      const limitPosition = getDomMaxPosition(dragDom)
      const pos = getDomPosition(dragDom)
      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        let dragPotition = {}
        dragPotition.left = e.clientX - disX
        dragPotition.top = e.clientY - disY
        // 边界处理
        resizeLimitPosition(dragPotition, limitPosition)
        // 移动当前元素
        dragDom.style.cssText += `;left:${dragPotition.left + pos.left}px;top:${
          dragPotition.top + pos.top
        }px;bottom:auto;right:auto;`
      }

      document.onmouseup = function () {
        document.onmousemove = null
        document.onmouseup = null
      }
      return false
    }
  }
}
// 挂载，注册
const directives = {
  install: function (app) {
    app.directive('drag', drag)
  }
}
export default directives
