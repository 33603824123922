<template>
  <div class="area-map-wrap-home area-map-wrap" v-loading="loading" :element-loading-text="mapLoadingText" element-loading-background="rgba(0, 0, 0, 0.5)">
    <CpnMapModule ref="RCpnMapModule" mapId="map-home" log="地图模块组件" :areaCode="userAreaCode" :bounds="mapAdmin.bounds" :geoJsonInner="mapAdmin.geoJsonInner"
      :geoJsonOuter="mapAdmin.geoJsonOuter" :VIPolygonGeoJson="VIPolygon.geoJson" :showGanjuData="true" :showBaodan="true" :VIPolygonCoord="VIPolygon.coord"
      :VIPolygonLegend="MapWRoot.VIPolygonLegend" :isoImg="mapImg.img" :showLocatMarker="false" @selectFeature="EventSelectFeature">
    </CpnMapModule>
    <GetDataHome @initEnd="(data) => {
      this.MapWRoot.farmInsure = data
      this.VIPolygon.geoJson = this.Api.jsonData(data.geojson)
    }
      "></GetDataHome>
    <LayerBoxGroupHome v-if="MapWRoot.farmInsure"></LayerBoxGroupHome>
    <div class="m-test" v-if="false">
      {{ adminObj }}
    </div>
  </div>
</template>
<script>
import CpnMapModule from '@/components/content/mapModule/CpnMapModule.vue';
/*map-wrap S*/

/*map-wrap E*/
import GetDataHome from './getData/GetDataHome.vue';
import LayerBoxGroupHome from './layerBoxGroup/LayerBoxGroupHome.vue';
//获取区划轮廓hook
import { HookMapAdminFeatures } from '@/hooks/HookMapAdminFeatures.js';
const OPA = 0.7;
export default {
  inject: [],
  components: {
    CpnMapModule,

    GetDataHome,
    LayerBoxGroupHome
  },
  provide: function () {
    return {
      MapWRoot: this.MapWRoot
    };
  },
  props: {
    userAreaCode: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      mapLoadingDict: {},
      mapLoadingText: '',
      areaCode: 522701,
      //行政区划
      mapAdmin: {
        bounds: [105.296799, 30.145844, 105.314520, 30.160014], // 安岳
        // bounds: [104.6, 30.7996, 104.6166, 30.8194], // 金堂
        geoJsonOuter: {},
        geoJsonInner: {}
      },
      adminObj: false,
      //地图-图
      mapImg: { img: '', downImg: '', imgLegend: [] }, //图
      //地图-面
      mapPolygon: { geoJsonInner: false }, //面
      VIPolygon: {
        geoJson: false,
        coord: [104.60921, 30.8063]
      },
      //地图外部-图层
      layer: {
        title: '',
        subTitle: '',
        leftMarkerLegend: [] //左侧图例
      },
      MapWRoot: {
        isoImgValue: '', //当前选择的插值图的要素值
        onLoading: this.onLoading,
        offLoading: this.offLoading,
        farmInsure: false, //农险服务info
        featureInfo: false,
        VIPolygonLegend: [
          { name: '春见', color: 'rgba(5,194,104,' + OPA + ')' },
          { name: '爱媛', color: 'rgba(182,194,12,' + OPA + ')' },
          { name: '明日见', color: 'rgba(17,219,255,' + OPA + ')' },
          { name: '甘平', color: 'rgba(184,116,13,' + OPA + ')' },
          { name: '池塘', color: 'rgba(0,30,255,' + OPA + ')' },
          { name: '尤力克', color: 'rgba(167, 223, 29,' + OPA + ')' }
          // { name: '池1-1', color: 'rgba(214,69,86,' + OPA + ')' },
          // { name: '池1-2', color: 'rgba(0,30,255,' + OPA + ')' },
          // { name: '池1-3', color: 'rgba(120,0,255,' + OPA + ')' }
        ]
      }
    };
  },
  watch: {
    '$store.state.centerBounds': function (newVal) {
      this.mapAdmin.bounds = newVal
    },
    '$store.state.border': function (newVal) {
      this.showBorder(newVal)
    }
    // "$store.state.jidiCode": function (newVal) {
    //   if (newVal === 'fb38662573d244839dd6dd2af2488f72') {
    //     // 金堂
    //     this.MapWRoot.VIPolygonLegend = [
    //       { name: '春见', color: 'rgba(5,194,104,' + OPA + ')' },
    //       { name: '爱媛', color: 'rgba(182,194,12,' + OPA + ')' },
    //       { name: '明日见', color: 'rgba(17,219,255,' + OPA + ')' },
    //       { name: '甘平', color: 'rgba(184,116,13,' + O + ')' }
    //     ]
    //   } else {
    //     this.MapWRoot.VIPolygonLegend = [
    //       { name: '尤力克', color: 'rgba(167, 223, 29,' + OPA + ')' }
    //     ]
    //   }
    // }
  },
  computed: {
    loading() {
      for (let i in this.mapLoadingDict) {
        if (this.mapLoadingDict[i]) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      // this.setMapAdminFeatures()
    },
    setMapAdminFeatures() {
      //获取区划轮廓hook
      this.mapAdmin = HookMapAdminFeatures(this.MapWRoot, this.areaCode);
    },
    onLoading(key, text) {
      this.mapLoadingDict[key] = true;
      this.mapLoadingText = text ?? '数据加载中...';
    },
    offLoading(key) {
      this.mapLoadingDict[key] = false;
      this.mapLoadingText = '';
    },
    EventChange_adminFeatures() { },
    EventSelectFeature(ret) {
      this.MapWRoot.featureInfo = ret;
      // console.log(`ret 22:>>`, ret.value);
      this.$store.commit("setBaodanItem", ret.values_.properties);
    },
    clearMarker() {
      this.$refs.RLayerBaseMarkerStation?.hideAllMarker();
      this.$refs.RCpnMapModule.setMapMarkerDict({});
      this.mapCropGrowthMarker = { type: '', dict: {}, legend: [] };
    },
    clearTimeline() {
      this.$refs.RLayerTimelineImg.clear();
    },
    clearIsoImg() {
      this.mapImg = {
        img: '',
        imgLegend: []
      };
      this.$refs.RBoxElements.elementValue = '';
    },
    clearTitle() {
      this.layer.title = '';
      this.layer.subTitle = '';
    },
    initEnd() {

    },
    showBorder(){
      // console.log("border:"+this.mapPlug.map)
    }
  }
};
</script>
<style lang="scss" scoped>
.area-map-wrap-home {
  position: relative;
  height: 100%;

  .layer-left-top {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 400px;
    box-sizing: border-box;
  }

  .layer-right-top {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 430px;
  }

  .layer-box {
    padding: 20px;
  }

  .line {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.layer-img-legend {
  position: absolute;
  left: 420px;
  bottom: 60px;
  z-index: 10;
}

.layer-marker-legend {
  position: absolute;
  right: 450px;
  bottom: 120px;
}

.m-test {
  position: absolute;
  left: 500px;
  top: 20px;
  background: #000;
}
</style>
