<!--地图轮廓绘制-功能组件-->
<template>
  <div></div>
</template>
<script>
import {
  setMapInnerFeatures,
  setMapOuterFeatures,
  setFeatureLabel
} from '@/plug/map/openLayer/polygon/DrawMapFeatures.js'
export default {
  log: '',
  components: {},
  inject: ['MapRoot'],
  props: {
    mapPlug: {
      type: Object,
      default: () => {}
    },
    geoJsonInner: {
      type: [Object, Boolean],
      default: () => {}
    },
    geoJsonOuter: {
      type: [Object, Boolean],
      default: () => {}
    }
  },
  data() {
    return {
      padding: [30, 30, 30, 30]
    }
  },
  watch: {
    'MapRoot.featureOpacity': {
      handler() {
        this.setMapFeaturesStyle()
      }
    },
    geoJsonInner: {
      handler() {
        this.setMapInnerFeatures()
      },
      immediate: true
    },
    geoJsonOuter: {
      handler() {
        this.setMapOuterFeatures()
      }
    }
  },
  computed: {
    outerFeaturesStyle() {
      return {
        fill: 'rgba(210, 227, 243,' + this.MapRoot.featureOpacity * 0.01 + ')',
        stroke: 'rgba(22, 127, 230, 1)',
        border: 3
      }
    }
  },
  created() {},
  mounted() {
    //DOM初始化完成进行地图初始化
  },
  methods: {
    initMap() {
      //初始化地图
      this.setMapInnerFeatures()
      this.setMapOuterFeatures()
    },
    setMapInnerFeatures() {
      if (this.MapRoot.mapPlug) {
        setMapInnerFeatures(this.MapRoot.mapPlug, this.geoJsonInner)
        setFeatureLabel(this.MapRoot.mapPlug, this.geoJsonInner) //绘制feature标签
      }
    },
    setMapOuterFeatures() {
      let mapPlug = this.MapRoot.mapPlug
      if (mapPlug) {
        setMapOuterFeatures(mapPlug, this.geoJsonOuter)
        //显示当前轮廓区域,并设置中心点
        let opt = {
          geoJson: this.geoJsonOuter,
          padding: [100, 0, 40, 0]
        }
        mapPlug.viewFeature(opt)
        mapPlug.zoomHome = () => {
          mapPlug.viewFeature(opt)
        }
      }
    },
    setMapFeaturesStyle() {
      this.MapRoot.mapPlug.FeatureOuterPlug?.setFeatureStyle(
        this.outerFeaturesStyle
      )
    }
  }
}
</script>
<style lang="scss"></style>
