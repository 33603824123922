import _ from 'lodash'
import VectorSource from 'ol/source/Vector'
import Collection from 'ol/Collection.js'
import { Group as LayerGroup } from 'ol/layer'
import Feature from 'ol/Feature'
import { Vector as VectorLayer } from 'ol/layer.js'
import Select from 'ol/interaction/Select'
import { click } from 'ol/events/condition'
import { Icon, Style, Fill, Stroke, Text, Circle } from 'ol/style.js'
import Point from 'ol/geom/Point'

/**
 * 使用feature点的方式绘制点到地图上-用户绘制多类别marker
 */
class KOClass {
  mapPlug
  layerId = 'featureMultMarker'
  selectValue = 'circles-zoom'
  pointObj = {}
  points = {}
  iconMarkerSize = [20, 30] //图标类型点-大小
  circleMarkerSize = 5 //圆点-大小
  textStyle = 'none' //点的文本类型：none,text,label
  iconOffsetY = -25 //当testStyle为text时，label的Y定位
  labelOffsetY = -16 //当testStyle为label时，label的Y定位
  /**
   * 绘制带类别的marker点到地图上
   * @param {Object} config.mapPlug //地图组件
   * @param {Object} config.points //points点对象集合:{cityStation:{color: '#FF4B4B',icon:"",data: []}}
   */
  constructor(config) {
    _.merge(this, config)
    this.init()
    this.initEvent()
  }
  init() {
    if (this.mapPlug?.map) {
      this.pointObj = this.getPointObj()
      this.addLayer()
    }
  }
  initEvent() {
    const selectClick = new Select({
      condition: click,
      layers: (ret) => {
        let proper = ret.getProperties()
        if (proper.layerId && proper.layerId == this.layerId) {
          return true
        }
      },
      style: (feature) => {
        if (feature.onSelected) {
          return feature.selectStyle
        } else {
          return feature.defStyle
        }
      }
    })
    selectClick.on('select', (ret) => {
      if (ret?.selected?.length) {
        ret.selected[0].onSelected = true
      }
    })
    this.mapPlug.map.addInteraction(selectClick)
  }
  getHoverIcon(ret, item) {
    return this.getIcon({ ...ret, icon: ret.hoverIcon }, item)
  }
  getIcon(ret, item) {
    if (ret.icon) {
      let opt = {
        crossOrigin: 'anonymous',
        src: ret.icon,
        imgSize: this.iconMarkerSize,
        displacement: [0, 10],
        anchorOrigin: 'bottom-left'
      }
      if (ret.color) {
        //如果图标是灰色的，可以对其着色
        opt.color = ret.color
      }
      if (item.data?.rotation) {
        opt.rotation = (Math.PI / 180) * item.data.rotation
      }
      return new Icon(opt)
    } else if (ret.color) {
      return new Circle({
        radius: this.circleMarkerSize,
        fill: new Fill({
          //圆填充色
          color: ret.color
        }),
        stroke: new Stroke({
          color: '#FFF',
          width: 1
        })
      })
    }
  }
  getStyle(item, isSelected) {
    let key = item.markerType
    if (this.points[key]) {
      let opt,
        defOpt = {
          text: item.text,
          font: '12px sans-serif',
          textAlign: 'center',
          textBaseline: 'bottom',
          offsetY: this.iconOffsetY,
          fill: new Fill({
            color: [255, 255, 255, 1]
          }),
          stroke: new Stroke({
            color: '#333',
            width: 1
          })
        }
      if (this.textStyle == 'none') {
        //不显示名称
        opt = {}
      } else if (this.textStyle == 'text') {
        //只显示名称
        opt = defOpt
      } else if (this.textStyle == 'label') {
        let bgColor = 'rgba(4,25,59,.7)'
        if (isSelected == 'selected') {
          bgColor = 'rgba(18,70,156,.9)'
        }
        opt = {
          ...defOpt,
          offsetY: this.labelOffsetY,
          font: '14px sans-serif',
          backgroundFill: new Fill({
            //圆填充色
            color: bgColor
          }),
          backgroundStroke: new Stroke({
            color: 'rgba(0,220,220,.7)',
            width: 1
          }),
          padding: [7, 9, 4, 9]
        }
      }
      let iconConfig = this.getIcon(this.points[key], item)
      if (isSelected == 'selected' && this.points[key].hoverIcon) {
        iconConfig = this.getHoverIcon(this.points[key], item)
      }
      return new Style({
        image: iconConfig,
        text: new Text(opt)
      })
    }
  }
  getPointObj() {
    let pointObj = {}
    for (let key in this.points) {
      if (this.points[key]?.data) {
        let source = new VectorSource({
          features: this.getFeatures(this.points[key]?.data)
        })
        pointObj[key] = {
          layer: new VectorLayer({
            properties: { layerId: this.layerId },
            source: source,
            zIndex: 60
          })
        }
      }
    }
    return pointObj
  }
  getFeatures(points) {
    let features = []
    for (let item of points) {
      if (item.lon && item.lat) {
        let feature = {
          geometry: new Point(this.mapPlug.fromLonLat([item.lon, item.lat])),
          markerType: item.markerType,
          text: item.markerLabel?.toString(),
          data: item
        }
        features.push(feature)
      }
    }
    return new Collection(
      features.map((featureOptions) => {
        const feature = new Feature({
          geometry: featureOptions.geometry
        })
        //设定默认的marker样式
        feature.defStyle = this.getStyle(featureOptions)
        //设定已选中的marker样式
        feature.selectStyle = this.getStyle(featureOptions, 'selected')
        feature.setStyle(feature.defStyle)
        feature.featureType = this.layerId
        feature.data = featureOptions.data
        return feature
      })
    )
  }
  addLayer() {
    let groupLayer = []
    for (let i in this.pointObj) {
      groupLayer.push(this.pointObj[i].layer)
    }
    if (this.layerGroup) {
      this.remove(this.layerGroup)
    }
    this.layerGroup = new LayerGroup({
      layers: groupLayer,
      zIndex: 59
    })
    this.mapPlug.map?.addLayer(this.layerGroup)
  }
  update(config) {
    this.points = config.points
    this.pointObj = this.getPointObj()
    this.addLayer()
  }
  remove(layerGroup) {
    if (layerGroup) {
      let layers = layerGroup.getLayers()
      if (layers) {
        layers.clear()
      }
    }
  }
}

export default KOClass
/*
const predefinedStyles = {
  icons: {
    symbol: {
      symbolType: 'image',
      src: require('@/assets/img/icon/point-red.png'),
      size: [15, 22],
      color: 'lightyellow',
      rotateWithView: false,
      offset: [0, 5]
    }
  },
}
*/
