/*用于接口请求临时存储返回值的modules */
export default {
  namespaced: true,
  state() {
    return {
      projectName: 'schenan',
      KOData: {},
      KOStore: {
        //会存入storeage的对象
        mapCenter: [113.66, 34], //[117, 32],
        mapZoom: 6,
        GArea: {}
      }
    }
  },
  getters: {},
  mutations: {
    saveState(state, ret) {
      state.KOData[ret.key] = ret.value
    },
    clear(state) {
      state.KOData = {}
    }
  },
  actions: {}
}
