import { Point, LineString, Polygon ,Circle as CircleGeom} from 'ol/geom';
import { Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Circle as CircleStyle, Fill, Icon } from 'ol/style';

export default function addFeature(map){
  let pointFeatureArr = [[104.655841, 30.768038], [104.599585, 30.804840], [104.668800, 30.814147], [104.663506, 30.693641]];
  // let pointArr = pointFeatureArr.map(item => {
  //   return new Feature(new Point([...item]));
  // });

  let radiusArr = [0.004, 0.004, 0.004, 0.004];
  // let circleArr = pointFeatureArr.map((item,index)=>{
  //   return new Feature(new CircleGeom(item,radiusArr[index]))
  // })


  let PointSource = new VectorSource({
    features: pointArr
  });
  let circleSource = new VectorSource({
    features: circleArr
  });

  let pointVectorLayer = new VectorLayer({
    source:PointSource,
    style: new Style({
      // image: new CircleStyle({            //点样式
      //   radius: 7,
      //   fill: new Fill({
      //     color: '#ffcc33'
      //   }),
      // }),
      image: new Icon({
        src: require('@/assets/img/garden/hot.png'),
        // size: 20,
      })
    })
  });

  let circleVectorLayer = new VectorLayer({
    source:circleSource,
    style: new Style({
      fill: new Fill({               //填充样式
        color: 'rgba(43, 233, 233, 0.45)'  
      }),
    })
  });
  map.map.addLayer(circleVectorLayer);
  map.map.addLayer(pointVectorLayer);
}


var circleArr = [
  new Feature({
    geometry: new CircleGeom([104.655841, 30.768038],0.0025),
    properties:{
      cbArea: 260,
      cbAllMoney: '62400元',
      cbTime: '2023-12-09',
      cblx: '政策性农业水果',
      khName: '成都广泽农业服务有限公司',
      bxjl: "周静",
      pfTime:'2024-04-03',
      pfArea:52,
      pfMoney:32843.2,
      zjh:'91510121MA681KC604',
      syq:'-',
      tdlzdx:'-',
      szdq:'金堂县三溪镇',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.655841, 30.768038",
      jj:"成都广泽农业服务有限公司成立于2013年，是以批发农资、农业技术推广服务和其他农业服务为主的农业公司。在四川省成都市金堂县三溪镇金河村拥有共计约260亩的柑橘种植基地，主要生产春见、甘平等产品，种植面积广，产量产值大。"
    }
  }),
  new Feature({
    geometry: new CircleGeom([104.5875, 30.8039],0.0035),
    properties:{
      cbArea: 103,
      cbAllMoney: '24720元',
      cbTime: '2023-12-09',
      cblx: '政策性农业水果',
      khName: '金堂县佳荣家庭农场',
      bxjl: "周静",
      pfTime:'2024-04-04',
      pfArea:20,
      pfMoney:12632,
      zjh:'92510121L685362344',
      syq:'-',
      tdlzdx:'-',
      szdq:'金堂县赵家镇',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.599585, 30.804840",
      jj:"金堂县佳荣家庭农场成立于2018年，是以水果及其他作物种植为主的农业公司。在四川省成都市金堂县赵家镇平水桥村拥有共计约103亩的柑橘种植基地，主要生产爱媛、甘平、明日见等产品，种植面积广，产量产值大。"
    }
  }),
  new Feature({
    geometry: new CircleGeom([104.668800, 30.814147],0.003),
    properties:{
      cbArea: 585,
      cbAllMoney: '140400元',
      cbTime: '2023-10-22',
      cblx: '政策性农业水果',
      khName: '成都市金橙故事果蔬专业合作社',
      bxjl: "周静",
      pfTime:'2024-03-29',
      pfArea:98,
      pfMoney:61896.8,
      zjh:'93510121MA6C61928G',
      syq:'-',
      tdlzdx:'-',
      szdq:'金堂县福兴镇',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.668800, 30.814147",
      jj:"成都市金橙故事果蔬专业合作社成立于2017年，是以水果种植及销售为主的农业公司。在四川省成都市金堂县福兴镇金福村拥有共计约585亩的柑橘种植基地，主要生产甘平、明日见等产品，种植面积广，产量产值大"
    }
  }),
  new Feature({
    geometry: new CircleGeom([104.663506, 30.693641],0.0034),
    properties:{
      cbArea: 248,
      cbAllMoney: '59520元',
      cbTime: '2023-12-09',
      cblx: '政策性农业水果',
      khName: '金堂县唐金琳家庭农场',
      bxjl: "周静",
      pfTime:'2024-02-21',
      pfArea:28,
      pfMoney:17684.8,
      zjh:'92510121MA7HL92QXW',
      syq:'-',
      tdlzdx:'-',
      szdq:'高板街道玉溪社区',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.663506, 30.693641",
      jj:"金堂县唐金琳家庭农场成立于2017年，是以水果种植为主的农业公司。在四川省成都市金堂县高板街道玉溪社区拥有共计约248亩的柑橘种植基地，主要生产春见、爱媛等产品，种植面积广，产量产值大。",
    }
  }),
]

var pointArr = [
  new Feature({
    geometry: new Point([104.655841, 30.768038]),
    properties:{
      cbArea: 260,
      cbAllMoney: '62400元',
      cbTime: '2023-12-09',
      cblx: '政策性农业水果',
      khName: '成都广泽农业服务有限公司',
      bxjl: "周静",
      pfTime:'2024-04-03',
      pfArea:52,
      pfMoney:32843.2,
      zjh:'91510121MA681KC604',
      syq:'-',
      tdlzdx:'-',
      szdq:'金堂县三溪镇',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.655841, 30.768038",
      jj:"成都广泽农业服务有限公司成立于2013年，是以批发农资、农业技术推广服务和其他农业服务为主的农业公司。在四川省成都市金堂县三溪镇金河村拥有共计约260亩的柑橘种植基地，主要生产春见、甘平等产品，种植面积广，产量产值大。"
    }
  }),
  new Feature({
    geometry: new Point([104.5875, 30.8039]),
    properties:{
      cbArea: 103,
      cbAllMoney: '24720元',
      cbTime: '2023-12-09',
      cblx: '政策性农业水果',
      khName: '金堂县佳荣家庭农场',
      bxjl: "周静",
      pfTime:'2024-04-04',
      pfArea:20,
      pfMoney:12632,
      zjh:'92510121L685362344',
      syq:'-',
      tdlzdx:'-',
      szdq:'金堂县赵家镇',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.599585, 30.804840",
      jj:"金堂县佳荣家庭农场成立于2018年，是以水果及其他作物种植为主的农业公司。在四川省成都市金堂县赵家镇平水桥村拥有共计约103亩的柑橘种植基地，主要生产爱媛、甘平、明日见等产品，种植面积广，产量产值大。"
    }
  }),
  new Feature({
    geometry: new Point([104.668800, 30.814147]),
    properties:{
      cbArea: 585,
      cbAllMoney: '140400元',
      cbTime: '2023-10-22',
      cblx: '政策性农业水果',
      khName: '成都市金橙故事果蔬专业合作社',
      bxjl: "周静",
      pfTime:'2024-03-29',
      pfArea:98,
      pfMoney:61896.8,
      zjh:'93510121MA6C61928G',
      syq:'-',
      tdlzdx:'-',
      szdq:'金堂县福兴镇',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.668800, 30.814147",
      jj:"成都市金橙故事果蔬专业合作社成立于2017年，是以水果种植及销售为主的农业公司。在四川省成都市金堂县福兴镇金福村拥有共计约585亩的柑橘种植基地，主要生产甘平、明日见等产品，种植面积广，产量产值大"
    }
  }),
  new Feature({
    geometry: new Point([104.663506, 30.693641]),
    properties:{
      cbArea: 248,
      cbAllMoney: '59520元',
      cbTime: '2023-12-09',
      cblx: '政策性农业水果',
      khName: '金堂县唐金琳家庭农场',
      bxjl: "周静",
      pfTime:'2024-02-21',
      pfArea:28,
      pfMoney:17684.8,
      zjh:'92510121MA7HL92QXW',
      syq:'-',
      tdlzdx:'-',
      szdq:'高板街道玉溪社区',
      pz:"-",
      dkbm:"-",
      dxlb:"平原",
      jwd:"104.663506, 30.693641",
      jj:"金堂县唐金琳家庭农场成立于2017年，是以水果种植为主的农业公司。在四川省成都市金堂县高板街道玉溪社区拥有共计约248亩的柑橘种植基地，主要生产春见、爱媛等产品，种植面积广，产量产值大。",
    }
  }),
]