import _ from 'lodash'
import ImageLayer from 'ol/layer/Image'
import Projection from 'ol/proj/Projection'
import Static from 'ol/source/ImageStatic'
import { Group as LayerGroup } from 'ol/layer'
import { fromLonLat } from 'ol/proj'
import TypeIs from '@/api/TypeIs.js'
/*
传入单个多边形json，获取轮廓的中心点
*/
class KOClass {
  extent = [128.462677, 43.523126, 130.055034, 44.462265] //[小,大]
  showLength = 10 //每次缓存的图片数量
  opacity = 1
  zIndex = 7
  imgs = []
  imgDict = {}
  viewImg = ''
  constructor(config) {
    _.merge(this, config)
    this.extent = this.reExtent(config.extent)
    this.init()
  }
  init() {
    this.getProjection()
    this.setImgLayers()
    this.addLayerGroup()
    this.setVisible_imgLayer()
  }
  updateImgs() {
    this.setImgLayers()
    this.addLayerGroup()
    this.setVisible_imgLayer()
  }
  update(ret) {
    if (ret?.extent?.length == 4) {
      this.extent = this.reExtent(ret.extent)
    }
    this.hiddenLayers()
    if (ret?.img) {
      this.addImgLayer(ret.img)
    }
  }
  addImgLayer(ret) {
    if (ret && TypeIs.String(ret)) {
      this.viewImg = ret
      if (this.imgDict[encodeURIComponent(ret)]) {
        this.setVisible_imgLayer()
      } else {
        this.imgs.push(ret)
        this.addImg2Group(ret)
        this.addLayerGroup()
      }
    } else {
      this.hiddenLayers()
    }
  }
  setImgLayers() {
    let data = this.imgs
    this.imgGroups = []
    for (let i = 0; i < data.length; i++) {
      this.addImg2Group(data[i])
    }
  }
  addImg2Group(img) {
    let imgLay = this.createImg(img)
    imgLay.img = img
    this.imgGroups.push(imgLay)
    this.imgDict[encodeURIComponent(img)] = imgLay
  }
  addLayerGroup() {
    if (this.imgGroups?.length) {
      if (this.layerGroup) {
        this.tempGroup = this.layerGroup
      }
      this.layerGroup = new LayerGroup({
        layers: this.imgGroups
      })
      this.mapPlug.map.addLayer(this.layerGroup)
      this.tempGroup?.getLayers().clear()
    }
  }
  setVisible_imgLayer() {
    let layers = this.imgGroups
    for (let item of layers) {
      if (this.viewImg == item.img) {
        item.setVisible(true)
      } else {
        item.setVisible(false)
      }
    }
  }
  createImg(url) {
    let staticObj = {
      url: url,
      projection: this.mapPlug.projection,
      imageExtent: this.extent
    }
    if (process.env.NODE_ENV == 'production') {
      //如果图片跨域，将导致canvas2html截图无法下载到本地
      staticObj.crossOrigin = 'anonymous'
    }
    return new ImageLayer({
      source: new Static(staticObj),
      opacity: this.opacity,
      zIndex: this.zIndex
    })
  }
  hiddenLayers() {
    let layers = this.imgGroups
    for (let i in layers) {
      layers[i].setVisible(false)
    }
  }
  reExtent(extent) {
    if (this.mapPlug.projection == 'EPSG:4326') {
      //国标不需要转换
    } else {
      let ex0 = fromLonLat([extent[0], extent[1]])
      let ex1 = fromLonLat([extent[2], extent[3]])
      //非国标需要对经纬度进行转换
      extent = [...ex0, ...ex1]
    }
    return extent
  }
  getProjection() {
    return new Projection({
      code: this.mapPlug.projection, //国标
      extent: this.extent
    })
  }
}

export default KOClass
