<template>
  <div>
    <BoxContent title="承保统计" width="24vw" height="34vh" top="44vh" left="20px">
      <div class="cbtj_list">
        <div class="cbtj_list_item" v-for="(v, i) in countList" :key="i">
          <div class="cbtj_list_item_left">
            <img src="@/assets/img/newVersion/orange.png" alt="" v-if="$store.state.jidiCode === 'fb38662573d244839dd6dd2af2488f72'">
            <img src="@/assets/img/newVersion/lemon.png" alt="" v-else>
            <div class="cb_name">{{ v.name }}</div>
          </div>
          <div class="cbtj_list_item_right">
            <div class="cb_area_title">承保面积</div>
            <span class="cb_area_area">{{ v.value }}</span>
            <span class="cb_area_unit">亩</span>
          </div>
          <!-- <img src="@/assets/img/newVersion/cbtj-bg.png" class="orange_bottom_img"> -->
          <div class="mask_item">
            <img src="@/assets/img/changeColor/cbtj_white.png">
            <div class="cbtj_mask_bg_color"></div>
          </div>
        </div>
      </div>
    </BoxContent>
  </div>
</template>

<script>
import BoxContent from '@/components/newVersion/boxContent.vue'
export default {
  inject: ['MapWRoot'],
  components: {
    BoxContent
  },
  data() {
    return {
      countList: [], // 统计数据
    }
  },
  watch: {
    'MapWRoot.farmInsure.company_base': function (newVal) {
      this.countArea()
    }
  },
  mounted() {
    this.countArea()
  },
  methods: {
    countArea() {
      // 统计面积
      if (!this.MapWRoot.farmInsure.geojson) return
      let allFeatures = JSON.parse(this.MapWRoot.farmInsure.geojson).features
      let pzNameArr = allFeatures.map(v => {
        return v.properties['品种']
      })
      let pzName = [...new Set(pzNameArr)]
      this.countList = []

      pzName.forEach(v => {
        let origin = allFeatures.filter(item => item.properties['品种'] === v)

        let sum = origin.reduce(function (prev, cur) {
          return cur.properties.mj + prev;
        }, 0);

        sum = Number(Number(sum).toFixed(2))

        this.countList.push({
          name: v,
          value: sum
        })
      })
      // 删除池-1 池-2 池-3
      this.countList = this.countList.splice(0, 4)
    }
  }
}
</script>

<style lang="scss" scoped>
.cbtj_list {
  display: flex;
  flex-direction: column;
  width: 100%;

  .cbtj_list_item {
    position: relative;
    width: 100%;
    // background: url('@/assets/img/newVersion/cbtj-bg.png') no-repeat bottom;
    background-size: 100% 15px;
    padding: 0px 34px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    img {
      width: 68px;
      height: 60px;
    }

    .orange_bottom_img {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 25%;
    }

    .mask_item {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 30%;
      // background: linear-gradient(to top, var(--theme-insurance-statistics-bg-color), rgba(0, 0, 0, 0));
      // clip-path: polygon(0 100%, 5% 0, 95% 0, 100% 100%);

      img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }

      .cbtj_mask_bg_color {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background: linear-gradient(to bottom, var(--theme-insurance-statistics-lgc1), var(--theme-insurance-statistics-lgc2));
        // background: radial-gradient(#dbdbdb2e, 60%, rgba(223, 247, 7, 0.845));
        mask: url('@/assets/img/changeColor/cbtj_black.png') no-repeat 100% 100% / cover;
        mask-size: 100% 100%;
        mix-blend-mode: multiply;
      }
    }

    .cbtj_list_item_left {
      display: flex;
      align-items: center;
      justify-content: end;
    }

    .cbtj_list_item_right {
      display: flex;

      align-items: center;

      .cb_area_unit {
        color: #fff !important;
        font-size: 12px;
      }
    }

    .cb_name {
      background-image: -webkit-linear-gradient(bottom, var(--theme-title-font-lgc2), var(--theme-title-font-lgc1)); //开始方向，渐变颜色

      -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。

      -webkit-text-fill-color: transparent; //把文字变透明
    }

    .cb_area_title {
      color: var(--theme-font-color1);
      padding: 0px 20px 0px 0px;
      font-size: 14px;
    }

    .cb_area_area {
      font-family: 'ysbthFont';
      width: 80px;
      text-align: end;
      padding-right: 10px;
      background-image: -webkit-linear-gradient(bottom, var(--theme-font-lgc2), var(--theme-title-font-lgc1)); //开始方向，渐变颜色
      -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。
      -webkit-text-fill-color: transparent; //把文字变透明
      font-size: 20px;
    }

  }
}
</style>
