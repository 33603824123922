<template>
  <el-menu class="cpn-nav" :mode="navs?.length > 1 ? 'horizontal' : 'vertical'" text-color="var(--ko-regular-color)" background-color="transparent"
    @click="this.$store.commit('Flag_True')">
    <SubMenu :navs="navs"></SubMenu>
  </el-menu>
</template>
<script>
import SubMenu from './vnav/SubMenu.vue';
export default {
  log: '',
  components: { SubMenu },
  props: {},
  data() {
    return {
      activeIndex: 0,
      navs: [
        { name: '农险服务', path: '/home' },
        { name: '气象服务', path: '/warning' },
        { name: '数字农业示范园', path: '/garden' },
        // { name: '米易农资供应链金融生态', path: '/jinrong' }
      ],
      menuCurrent: '/home'
    };
  },
  watch: {
  },
  computed: {
  },
  created() { },
  mounted() {
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.cpn-nav {
  padding-left: 32px;
  font-size: 22px;
  color: var(--ko-regular-color);
  border-bottom: 0 solid #000;
  border-right: 0 solid #000;
}
</style>
