<template>
  <div></div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.m-footer {
  text-align: center;
  font-size: 14px;
  padding: 10px 0;
}
</style>
