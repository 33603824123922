<template>
  <div class="m-left-nav">
    <ul>
      <li
        v-for="item in nav"
        :key="item.name"
        :class="$route.fullPath == item.path ? 'current' : ''"
        @click="EventClick(item)"
      >
        <div class="name" v-html="item.name"></div>
      </li>
    </ul>
    <div class="m-erwei-lump" v-if="false">
      <el-popover placement="right" :width="200" trigger="hover">
        <template #reference>
          <div>
            <img class="small-ew" src="@/assets/img/index/erwei.jpg" alt="" />
            <div class="text">扫码关注</div>
          </div>
        </template>
        <template #default>
          <img src="@/assets/img/index/erwei.jpg" class="view-erwei" alt="" />
        </template>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {},
  data() {
    return {
      nav: [
        { name: '智能<br>网格', path: '/grid', icon: 'icon-ditu1 icon-lh' },
        { name: '服务<br>产品', path: '/service', icon: 'icon-ditu1 icon-lh' }
      ]
    }
  },
  watch: {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  mounted() {},
  methods: {
    EventClick(item) {
      this.$router.push({ path: item.path })
    }
  }
}
</script>
<style lang="scss" scoped>
.m-left-nav {
  position: relative;
  width: 80px;
  padding-top: 20px;
  height: 100%;
  box-sizing: border-box;
  background: var(--ko-color-bg);
  li {
    text-align: center;
    padding: 10px 0;
    margin: 10px 0 20px;
    cursor: pointer;
    transition: 0.3s ease-out;
    .icon {
      display: block;
      height: 40px;
      font-size: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name {
      font-size: 16px;
    }
    &.current {
      background: #0a4a5a;
      border-left: 4px solid #00b5ff;
      color: #00ffff;
      .name {
        font-weight: bold;
      }
    }
    &:hover {
      background: #0a4a5a;
    }
  }
  .m-erwei-lump {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 150px;
    flex-shrink: 0;
    text-align: center;
    .small-ew {
      width: 40px;
      height: 40px;
      margin-bottom: 5px;
    }
  }
}
.view-erwei {
  width: 100%;
  display: block;
}
</style>
