<template>
  <div>
    <BoxContent title="承保信息" width="24vw" height="33vh" top="10vh" left="20px">
      <div class="cbxx_box">
        <div class="cb_item" v-for="(v, i) in list" :key="i">
          <div style="position: relative;">
            <img :src="imgList[i]" alt="">
            <div class="img_color_bg"></div>
          </div>
          <div class="row1">{{ v.name }}</div>
          <div class="row2">{{ v.value }}</div>
        </div>
      </div>
      <div style="filter: invert(100%) hue-rotate(180deg) brightness(115%) contrast(95%) !important; width:200px;height: 200px;"></div>
    </BoxContent>
  </div>
</template>

<script>
import BoxContent from '@/components/newVersion/boxContent.vue'
// import img1 from '@/assets/img/newVersion/img1.png'
// import img2 from '@/assets/img/newVersion/img2.png'
// import img3 from '@/assets/img/newVersion/img3.png'
// import img4 from '@/assets/img/newVersion/img4.png'
// import img5 from '@/assets/img/newVersion/img5.png'
// import img6 from '@/assets/img/newVersion/img6.png'
import img1 from '@/assets/img/changeColor/cbxx_icon1_white.png'
import img2 from '@/assets/img/changeColor/cbxx_icon2_white.png'
import img3 from '@/assets/img/changeColor/cbxx_icon3_white.png'
import img4 from '@/assets/img/changeColor/cbxx_icon4_white.png'
import img5 from '@/assets/img/changeColor/cbxx_icon5_white.png'
import img6 from '@/assets/img/changeColor/cbxx_icon6_white.png'
import axios from 'axios'

export default {
  data() {
    return {
      imgList: [img1, img2, img3, img4, img5, img6],
      msgList: []
    }
  },
  inject: ['MapWRoot'],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  components: {
    BoxContent
  },
  watch: {
    '$store.state.jidiCode': function (newVal, oldVal) {
      // this.getJT(newVal)
    }
  },
  mounted() {
    console.log(`this.MapWRoot :>>`, this.MapWRoot)
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.cbxx_box {
  height: 240px;
  display: flex;
  flex-wrap: wrap;

  .cb_item {
    width: 140px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img_color_bg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      inset: 0;
      background: linear-gradient(135deg, var(--theme-insurance-icon-lgc1), var(--theme-insurance-icon-lgc2));

      mask: url('@/assets/img/changeColor/cbxx_icon_black.png') no-repeat 100% 100% / cover;
      mask-size: 100% 100%;
      mix-blend-mode: multiply;
    }

    img {
      width: 50px;
      height: 50px;
    }

    .row1 {
      color: var(--theme-font-color1);
      font-size: 12px;
      line-height: 30px;
    }

    .row2 {
      background-image: -webkit-linear-gradient(top, var(--theme-font-lgc2), var(--theme-font-lgc1)); //开始方向，渐变颜色
      -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。
      -webkit-text-fill-color: transparent; //把文字变透明
      line-height: 19px;
      text-align: center;
    }
  }
}
</style>
