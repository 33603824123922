<template>
  <div class="abc">
    <img src="@/assets/img/changeColor/head_bg_white.png" style="width:100%;height: 100%;position: absolute;left:0px;top:0px;">
    <div class="head_color_bg"></div>
    <div class="view-header">
      <div class="lump-1">
        <img :src="require('@/assets/img/logo.png')" alt="" />
        <div class="logo-text" data="中国人保·农险风险减量平台">
          <div>
            中国人保•农险风险减量服务平台
          </div>
        </div>
      </div>
      <div class="back">
        <el-select placeholder="金堂晗晟柑橘种植基地" name="" id="" v-model="park" @change="selectChange" size="large">
          <el-option v-for="(v, i) in selectList" :key="i" :value="v.id" :label="v.name"></el-option>
        </el-select>
      </div>
    </div>
    <div class="lump-2">
      <VNav></VNav>
    </div>
    <div class="time_box">
      {{ time }}
    </div>

  </div>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'
import VNav from '@/views/index/VHead/VNav.vue'
import dayjs from 'dayjs';
import { get_companyList } from '@/api/hsy'

export default {
  components: { VNav },
  data() {
    return {
      loginCount: '',
      park: '金堂晗晟柑橘种植基地',
      selectList: [],
      time: ''
    }
  },
  computed: {},
  beforeCreate() {
    get_companyList().then(res => {
      this.$store.commit("SET_JIDICODE", res.data.data[0].id)
    })
  },
  watch: {
    '$store.state.jidiCode': async function (newVal) {
      if (newVal === 'fb38662573d244839dd6dd2af2488f72') {
        let data = await (await fetch(`${process.env.VUE_APP_CHILD_NAME}/map/jt.json`)).json()
        // console.log(`data :>>`, data)
        for (let key in data) {
          document.documentElement.style.setProperty(key, data[key])
        }
      } else {
        let data = await (await fetch(`${process.env.VUE_APP_CHILD_NAME}/map/ay.json`)).json()
        // console.log(`data :>>`, data)
        for (let key in data) {
          document.documentElement.style.setProperty(key, data[key])
        }
      }
    }
  },
  mounted() {
    setInterval(() => {
      this.time = dayjs().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);


    // console.log(`this.$route :>>`, this.$route)
    get_companyList().then(async (res) => {
      this.park = res.data.data[0].id
      this.selectList = res.data.data.map(item => {
        return {
          ...item
        }
      })
      this.$store.commit("SET_JIDICODE", res.data.data[0].id)
      let ret = this.selectList.find(item => item.id === this.park)
      this.$store.commit("SET_JIDINAME", ret.name)
      this.$store.commit("SET_COOR", { lon: ret.lon, lat: ret.lat })
      this.$store.commit("SET_CENTERBOUNDS", ret.bounds.split(",").map(item => Number(item)))
      this.$store.commit("SET_BORDER", ret.border)
      if (this.$store.state.jidiCode === 'fb38662573d244839dd6dd2af2488f72') {
        let data = await (await fetch(`${process.env.VUE_APP_CHILD_NAME}/map/jt.json`)).json()
        console.log(`data :>>`, data)
        for (let key in data) {
          document.documentElement.style.setProperty(key, data[key])
        }
      } else {
        let data = await (await fetch(`${process.env.VUE_APP_CHILD_NAME}/map/ay.json`)).json()
        console.log(`data :>>`, data)
        for (let key in data) {
          document.documentElement.style.setProperty(key, data[key])
        }
      }
    })

  },
  methods: {
    setTime() {
      this.nowTime = new Date().Format('YYYY年MM月DD日<br> hh:mm:ss')
      setTimeout(() => {
        this.setTime()
      }, 500)
    },
    EventSelect(ret) {
      console.log(ret)
    },
    EventClick_drawer() {
      this.drawerShow = true
    },
    EventClick_mng() { },
    handleClick() {
      alert('button click')
    },

    selectChange(value) {
      this.$store.commit("SET_JIDICODE", value)
      let ret = this.selectList.find(item => item.id === value)
      this.$store.commit("SET_JIDINAME", ret.name)
      this.$store.commit("SET_COOR", { lon: ret.lon, lat: ret.lat })
      this.$store.commit("SET_CENTERBOUNDS", ret.bounds.split(",").map(item => Number(item)))
      this.$store.commit("SET_BORDER", ret.border)
    }
  }
}
</script>

<style lang="scss" scoped>
.abc {
  display: flex;
  // justify-content: space-between;
  // align-items: center;

  // background: url('~@/assets/img/newVersion/head-bg.png') center/auto 100% repeat-x;
  position: relative;
}

.head_color_bg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  inset: 0;
  background: linear-gradient(to right, var(--theme-nav-bg-lgc1), var(--theme-nav-bg-lgc2));
  mask: url('@/assets/img/changeColor/head_bg_black.png') no-repeat 50% 50% / cover;
  mask-size: 100% 100%;
  mix-blend-mode: multiply;
}

.back {
  width: 220px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.view-header {
  height: 80px;
  position: relative;
  display: flex;

  .lump-1 {
    padding: 0 30px 0 30px;
    flex-shrink: 0;
    // background: url('@/assets/img/header/logo-bg.png') right center/auto 100% no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;

    img {
      height: 40px;
    }

    .logo-text {
      font-weight: bold;
      padding: 0 10px;
      text-shadow: 0px -1px 0px rgba(255, 255, 255, 0.3),
        0px 1px 3px rgba(0, 0, 0, 0.3);
      position: relative;
      height: 38px;
      padding-top: 20px;
      overflow: hidden;
      line-height: 23px;
    }

    .logo-text::after {
      content: '中国人保•农险风险减量服务平台';
      /* 复制文字内容 */
      position: absolute;
      top: 92%;
      left: 10px;
      color: #FFFFFF;
      opacity: 0.3;
      /* 调整倒影透明度 */
      transform: rotateX(180deg) skewX(0deg);
      /* 垂直翻转 */
      // filter: blur(1px);
      /* 增加模糊效果 */
      background-image: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); //开始方向，渐变颜色

      -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。

      -webkit-text-fill-color: transparent; //把文字变透明
    }
  }


  .m-nav {
    position: absolute;
    left: 650px;
    top: 0;
    font-size: 22px;
    color: var(--ko-regular-color);
    display: flex;

    li {
      height: 83px;
      width: 140px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    li.current {
      color: #00ffff;
    }
  }

  .m-login {
    position: absolute;
    right: 30px;
    top: 25px;
  }

  .m-datetime {
    position: absolute;
    right: 30px;
    top: 25px;
    font-size: 14px;
    text-align: right;
  }
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

.icon-cb {
  font-size: 12px;
  transform: scale(.7);
}

.lump-2 {
  padding-top: 18px;

  ::v-deep .el-menu {
    height: 100%;

    .el-menu-item:hover {
      background-color: rgba(0, 0, 0, 0);
    }

    .el-menu-item:focus {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.time_box {
  position: absolute;
  top: 4vh;
  right: 2vw;
}

::v-deep .el-button {
  background-color: #091c2a;
  border: 0px;
  // border: 1px #0d2540 solid !important;
  // box-shadow: 0px 0px 8px #63b0ff !important;
  margin-right: 20px;
  padding: 20px 20px !important;
}

::v-deep .el-button:focus {
  background-color: #091c2a;
  border: 0px;
  // border: 1px #0d2540 solid !important;
  // box-shadow: 0px 0px 8px #63b0ff !important;
}

::v-deep .el-button:hover {
  background-color: #091c2a;
  border: 0px;
  // border: 1px #0d2540 solid !important;
  // box-shadow: 0px 0px 8px #63b0ff !important;
}

::v-deep .el-button:active {
  background-color: #091c2a;
  border: 0px;
  // border: 1px #0d2540 solid !important;
  // box-shadow: 0px 0px 8px #63b0ff !important;
}

::v-deep .el-button:focus-visible {
  border: 0px;
  // border: 1px #0d2540 solid !important;
  // box-shadow: 0px 0px 8px #63b0ff !important;
}
</style>

<style>
.el-popper.is-light .el-popper__arrow::before {
  background: transparent !important;
  border: 1px solid var(--theme-dropdown-border-color) !important;
}

.el-popper {
  border: 1px solid var(--theme-color) !important;
  background: #02020280 !important;
}

.el-select-dropdown__list .el-select-dropdown__item:hover {
  background: #00000082 !important;
}
</style>
