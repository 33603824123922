<template>
  <div class="module-location-marker"></div>
</template>
<script>
import MapMultMarker from '@/plug/map/openLayer/marker/MapMultMarker.js'
import {
  getMarkerLegend,
  getMarkerList
} from '@/plug/map/openLayer/marker/MarkerConfig.js'
export default {
  inject: ['MapRoot'],
  components: {},
  props: {
    locatMarker: {
      type: [Object, Boolean],
      default: () => {}
    }
  },
  data() {
    return {}
  },
  watch: {
    locatMarker: {
      handler() {
        this.setMapLocatMarker()
      }
    }
  },
  computed: {},
  mounted() {
    this.setMapLocatMarker()
  },
  methods: {
    setMapLocatMarker() {
      let markerType = 'locat'
      let viewMarkerType = [markerType]
      let mapPlug = this.MapRoot.mapPlug
      let selectLocat = this.locatMarker
      if (mapPlug?.map) {
        let locatMarker = [
          {
            lon: selectLocat.lon,
            lat: selectLocat.lat,
            markerType: markerType,
            name: '',
            icon: require('@/assets/img/icon/icon-city-station.png')
          }
        ]
        let markerLegend = getMarkerLegend({
          list: locatMarker,
          viewTypes: viewMarkerType
        }) //获取点列表的图例
        let locatMarkerList = getMarkerList({
          list: locatMarker,
          type: markerType
        })
        let options = {
          markerOption: {
            layerId: 'locatMarker'
          },
          mapPlug: mapPlug,
          markerLegend: markerLegend,
          markerList: locatMarkerList,
          viewMarkerType: viewMarkerType
        }
        if (mapPlug.LocatMarker) {
          mapPlug.LocatMarker.update(options)
        } else {
          mapPlug.LocatMarker = new MapMultMarker(options)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
