import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/pages/pageHome/VPageHome.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      animate: true,
      title: '',
      footerIndex: 10,
      keepAlive: true,
      routerIndex: 0
    },
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      animate: true,
      title: '',
      footerIndex: 10,
      keepAlive: true,
      routerIndex: 0
    },
    component: Home
  },
  {
    path: '/warning',
    name: 'warning',
    meta: {
      animate: true,
      title: '',
      footerIndex: 20,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/pages/pageWarning/VPageWarning.vue')
  },
  {
    path: '/garden',
    name: 'garden',
    meta: {
      animate: true,
      title: '',
      footerIndex: 30,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/pages/pageGarden/VPageGarden.vue')
  },
  {
    path: '/jinRong',
    name: 'jinRong',
    meta: {
      animate: true,
      title: '',
      footerIndex: 40,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/index/jinRong.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      animate: false,
      title: '',
      footerIndex: 40,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'home',
    meta: {
      animate: true,
      title: '',
      element: 'prec',
      footerIndex: 10,
      keepAlive: true,
      routerIndex: 0
    },
    component: Home
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  //判断是否有标题
  document.title = '中国人保•农险风险减量服务平台' + to.meta.title ?? ''
  next() //执行进入路由，如果不写就不会进入目标页
})
export default router
