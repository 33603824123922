<template>
  <div class="vr">
    <div id="viewer"></div>
    <img src="" alt="">
  </div>
</template>
<script>
import { get_Polygon, get_Marker } from '@/api/hsy/index'
import { Viewer } from 'photo-sphere-viewer'; // 引入插件
import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'; //引入CSS样式
import 'photo-sphere-viewer/dist/plugins/markers.css';
// import { plIcon, plPolygon, cangkuIcon } from './ay.js'
// import { plIcon, plPolygon, cangkuIcon } from './pl.js'

export default {
  data() {
    return {
      panoramaViewer: null,
      imgurl: require('/anyue.jpg'),
      // imgurl: require('/720.jpg'),
      plIcon: null,
      plPolygon: null,
      cangkuIcon: null,
    };
  },
  watch: {
    '$store.state.jidiCode': function (newVal) {
      if (newVal == 'fb38662573d244839dd6dd2af2488f72') {
        this.imgurl = require("/720.jpg")
        if (this.panoramaViewer) {
          this.panoramaViewer.destroy()
          this.$nextTick(() => {
            this.initViewer()
          })
        }
      } else {
        this.imgurl = require("/anyue.jpg")
        if (this.panoramaViewer) {
          this.panoramaViewer.destroy()
          this.$nextTick(() => {
            this.initViewer()
          })
        }
      }
    }
  },
  computed: {},
  methods: {
    async initViewer() {
      // 从css变量中获取borderColor 和 fillColor
      let polygonBorderColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--theme-viewer-polygon-border-color')

      let module
      if (this.$store.state.jidiCode === 'fb38662573d244839dd6dd2af2488f72') {
        //   module = await import('./pl.js')
        this.imgurl = require('/720.jpg')
      } else {
        //   module = await import('./ay.js')
        //   // module = await get_Polygon({ companyBaseId : $})
        this.imgurl = require('/anyue.jpg')
      }
      // this.plIcon = module.plIcon
      // this.plPolygon = module.plPolygon
      // this.cangkuIcon = module.cangkuIcon


      let amodule = await get_Polygon({ companyBaseId: this.$store.state.jidiCode })
      this.plPolygon = JSON.parse(amodule.data.data)

      let bmodule = await get_Marker({ companyBaseId: this.$store.state.jidiCode })
      console.log(`bmodule :>>`, bmodule)
      this.plIcon = [...bmodule.data.data].map(item => {
        return {
          type: item.type,
          longitude: item.x,
          latitude: item.y,
          html: `<div class="pl_class"><span class="pl_title">${item.type}</span><img src="${item.icon}"></img></div>`,
          tooltip: {
            width: '180',
            content: `<img style="width:160px;height:100px;background: transparent;" src="${item.img}">${item.descr}`,
            position: 'right'
          }
        }
      })


      let plList = this.plIcon.map((item, index) => {
        return {
          ...item,
          id: `id${index}`,
          position: { yaw: '180deg', pitch: '45deg' },
          anchor: '150%, 55%',
          scale: [0.5, 0.5],
          style: {
            maxWidth: '300px',
            color: '#fff',
            fontSize: '22px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center'
          },
        }
      })

      let plPolygonList = this.plPolygon.map(item => {
        item.svgStyle.stroke = polygonBorderColor
        return {
          ...item
        }
      })
      this.panoramaViewer = new Viewer({
        container: document.querySelector('#viewer'), // 容器
        panorama: this.imgurl,
        navbar: ['autorotate', 'zoom', 'caption', 'fullscreen'],
        autorotateDelay: 5,
        autorotateSpeed: '0.5rpm',
        minFov: 1,
        maxFov: 120,
        plugins: [
          [
            MarkersPlugin,
            {
              markers: [
                ...plPolygonList
              ]
            }
          ],
          [MarkersPlugin, {
            markers: [
              // ...this.cangkuIcon,
              ...plList
            ]
          }],

        ], // 标记点
        size: {
          width: '100%',
          height: '100%'
        }
      });
      console.log(`this.panoramaViewer :>>`, this.panoramaViewer)
      this.panoramaViewer.on('click', (e, data) => {
        console.log(`data.longitude :>>`, `[${data.longitude},${data.latitude}]`);
        // console.log(`data.latitude :>>`, data.latitude);
      });
    }
  },
  created() { },
  async mounted() {
    this.initViewer();
    // return
    this.markersPlugin = this.panoramaViewer.getPlugin(MarkersPlugin);
    console.log(`this.markersPlugin :>>`, this.markersPlugin);
    this.panoramaViewer.on('position-updated', (e, position) => {
      // console.log(`this.panoramaViewer.plugins.markers.config.markers :>>`, this.panoramaViewer.plugins.markers.config.markers);
      this.panoramaViewer.plugins.markers.config.markers.forEach((item) => {
        if (
          item.longitude > position.longitude - 0.5 &&
          item.longitude < position.longitude + 0.5
        ) {
          this.markersPlugin.showMarker(item.id);
        } else {
          this.markersPlugin.hideMarker(item.id);
        }
      });
    });


  }
};
</script>

<style lang="scss" scoped>
.vr {
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  display: block;
  z-index: 0;
}


::v-deep .psv-tooltip {
  font-size: 14px !important;
  // overflow: hidden;
  transform-origin: 50% 0% !important;
}

.ui-tooltip {
  width: 500px;
}
</style>

<style lang="scss">
.psv-marker {
  // transform: translate(50%, -100%) !important;
  transform-origin: 0% -30% !important;
}

.psv-tooltip--visible {
  width: 500px !important;
  background-color: transparent !important;
  border: 1px solid var(--theme-color);
  background: linear-gradient(to bottom, rgba(74, 64, 33, 0.95), rgba(37, 40, 41, 0.2));

  /* background-color: rgba(1, 246, 245, 0.2); */
  /* border: 2px solid #00fefe; */
  /* clip-path: polygon(5% 0%, 90% 0%, 100% 5%, 100% 90%, 90% 100%, 5% 100%, 0% 90%, 0% 5%); */
  // background: url('@/assets/img/index/wenben.png');
  // background-size: 100% 100%;
}

.psv-tooltip--center-right .psv-tooltip-arrow {
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;

}

.psv-tooltip-content {
  padding-top: 10px;
  font-size: 12px !important;
  font-family: sans-serif !important;
  text-shadow: 0 0px #000;
  line-height: 17px;

  img {
    margin-bottom: 10px;
    text-align: center;
  }
}

.photo_popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  .photo_popup_title {
    padding: 15px;
    margin-bottom: 10px;
    clip-path: polygon(10px 0px,
        calc(100% - 10px) 0,
        100% 10px,
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        10px 100%,
        0 calc(100% - 10px),
        0 10px);
    background:
      linear-gradient(-45deg, var(--theme-color) 7px, rgba(0, 0, 0, 0) 0) bottom right,
      linear-gradient(45deg, var(--theme-color) 7px, rgba(0, 0, 0, 0) 0) bottom left,
      linear-gradient(135deg, var(--theme-color) 7px, rgba(0, 0, 0, 0) 0) top left,
      linear-gradient(-135deg, var(--theme-color) 7px, rgba(0, 0, 0, 0.609) 0) top right;
    border: 5px solid var(--theme-color);
  }

  img {
    width: 42px;
  }
}

.pl_class {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pl_title {
    text-align: center;
    font-size: 30px;
    background: linear-gradient(to right, rgba(255, 195, 0, 0), var(--theme-viewer-marker-text-bg), rgba(255, 195, 0, 0));
    padding: 10px 20px;
    letter-spacing: 2px;
    color: #fff;
  }

  img {
    width: 120px;
  }
}
</style>
