<template>
  <div class="box-feature-info">
    <CpnTitleB name="基地简要概览"></CpnTitleB>
    <div class="overview">{{ overview }}</div>
    <CpnTitleB name="地块基本信息"></CpnTitleB>
    <div class="m-dl-content">
      <CpnDlLabels :list="featureLabels"></CpnDlLabels>
    </div>
    <CpnTitleB name="地块品种信息"></CpnTitleB>
    <div class="m-dl-content">
      <CpnDlLabels :list="featureType"></CpnDlLabels>
    </div>
    <!-- <CpnTitleB name="该地块历年赔付情况"></CpnTitleB>
    <div class="m-dl-content">
      <CpnDlLabels :list="featureMoney"></CpnDlLabels>
    </div> -->
    <div class="m-kind-arr">
      <template v-if="!MapWRoot.featureInfo.data">
        <div v-for="(v, i) in kindArr" :key="i" style="opacity: 0;">
          <!-- <img :src="" :title="v.name" /> -->
          <el-image style="width: 150px;" :src="v.url" :title="v.name" :preview-src-list="srcList">
          </el-image>
        </div>
      </template>
      <template v-else>
        <div v-for="(v, i) in kindArr" :key="i">
          <el-image v-if="MapWRoot.featureInfo.data.品种 === v.name" style="width: 100%;height: 100%; " :src="v.url" :title="v.name" :preview-src-list="srcList">
          </el-image>
        </div>
      </template>
    </div>

  </div>
</template>
<script>
import CpnTitleB from '@/components/content/title/CpnTitleB.vue';
import CpnDlLabels from '@/components/content/content/CpnDlLabels.vue';
export default {
  inject: ['MapWRoot'],
  components: { CpnTitleB, CpnDlLabels },
  props: {},
  data() {
    return {
      overview: `四川晗晟现代农业发展有限公司成立于2014年，是以柑橘生产为主的农业公司。在四川省成都市金堂县赵家镇连墩村拥有共计约1200亩的柑橘种植基地，主要生产春见、爱媛、甘平、明日见等产品，种植面积广，产量产值大。`,
      oriOverView: `四川晗晟现代农业发展有限公司成立于2014年，是以柑橘生产为主的农业公司。在四川省成都市金堂县赵家镇连墩村拥有共计约1200亩的柑橘种植基地，主要生产春见、爱媛、甘平、明日见等产品，种植面积广，产量产值大。`,
      kindArr: [
        {
          name: '爱媛',
          // url: 'http://192.168.101.252:9002/jintang/Data/爱媛.png'
          url: require("@/assets/img/index/aiyuan.jpg")
        },
        {
          name: '春见',
          // url: 'http://192.168.101.252:9002/jintang/Data/春见.png'
          url: require("@/assets/img/index/chunjian.jpg")
        },
        {
          name: '甘平',
          // url: 'http://192.168.101.252:9002/jintang/Data/甘平.png'
          url: require("@/assets/img/index/ganping.jpg")
        },
        {
          name: '明日见',
          // url: 'http://192.168.101.252:9002/jintang/Data/明日见.png'
          url: require("@/assets/img/index/mingrijian.jpg")
        }
      ],
      srcList: [
        // 'http://192.168.101.252:9002/jintang/Data/爱媛.png',
        // 'http://192.168.101.252:9002/jintang/Data/春见.png',
        // 'http://192.168.101.252:9002/jintang/Data/甘平.png',
        // 'http://192.168.101.252:9002/jintang/Data/明日见.png'
        require("@/assets/img/index/aiyuan.jpg"),
        require("@/assets/img/index/chunjian.jpg"),
        require("@/assets/img/index/ganping.jpg"),
        require("@/assets/img/index/mingrijian.jpg")
      ]
    };
  },
  watch: {
    '$store.state.baodanItem': function (nl, ol) {
      if (nl) {
        this.overview = nl.jj;
      } else {
        this.overview = this.oriOverView;
      }
    },
  },
  computed: {
    featureLabels() {
      if (!this.$store.state.baodanItem) {
        let labels = [];
        let featureData = this.MapWRoot?.featureInfo?.data;
        if (featureData) {
          labels = this.Api.Obj2Arr({
            data: featureData,
            label: [
              { key: 'KHMC', name: '客户名称' },
              { key: 'ZJH', name: '证件号' },
              { key: 'TDSYQ', name: '土地所有权' },
              { key: 'TDLZDX', name: '土地流转对象' },
              { key: 'szdq', name: '所在地区' },
            ]
          });
        }
        return labels;
      } else {
        let list = [
          { key: 'KHMC', name: '客户名称', value: this.$store.state.baodanItem.khName },
          { key: 'ZJH', name: '证件号', value: this.$store.state.baodanItem.zjh },
          { key: 'TDSYQ', name: '土地所有权', value: this.$store.state.baodanItem.syq },
          { key: 'TDLZDX', name: '土地流转对象', value: this.$store.state.baodanItem.tdlzdx },
          { key: 'szdq', name: '所在地区', value: this.$store.state.baodanItem.szdq },
        ];
        return list;
      }
    },
    featureType() {
      if (!this.$store.state.baodanItem) {
        let labels = [];
        let featureData = this.MapWRoot?.featureInfo?.data;
        if (featureData) {
          labels = this.Api.Obj2Arr({
            data: featureData,
            label: [
              { key: '品种', name: '品种' },
              {
                key: 'mj',
                name: '面积',
                reData: (e) => {
                  return this.Api.keep2(e, 3) + '亩';
                }
              },
              { key: '地块编', name: '地块编码' },
              { key: '地形类', name: '地形类别' },
              {
                key: '经度',
                name: '经纬度',
                reData: () => {
                  return (
                    this.Api.keep2(featureData['经度'], 3) +
                    '，' +
                    this.Api.keep2(featureData['纬度'], 3)
                  );
                }
              }
            ]
          });
        }
        return labels;
      } else {
        let list = [
          { key: '品种', name: '品种', value: this.$store.state.baodanItem.pz },
          {
            key: 'mj',
            name: '面积',
            value: this.$store.state.baodanItem.cbArea
          },
          { key: '地块编', name: '地块编码', value: this.$store.state.baodanItem.dkbm },
          { key: '地形类', name: '地形类别', value: this.$store.state.baodanItem.dxlb },
          {
            key: '经度',
            name: '经纬度', value: this.$store.state.baodanItem.jwd
          }
        ];
        return list;
      }
    },
    featureMoney() {
      let labels = [];
      let featureData = this.MapWRoot?.featureInfo?.data;
      if (featureData) {
        labels = this.Api.Obj2Arr({
          data: featureData,
          label: [
            { key: '赔付时', name: '赔付时间' },
            { key: '赔付面', name: '赔付面积' },
            { key: '赔付原', name: '赔付原因' },
            { key: '赔付金', name: '赔付金额' }
          ]
        });
      }
      return labels;
    },
  },
  mounted() { },
  methods: {}
};
</script>
<style lang="scss" scoped>
.box-feature-info {
  color: #fff;

  .overview {
    text-indent: 28px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .m-dl-content {
    margin-bottom: 20px;
  }
}

.el-image {
  width: 150px;
  height: 100px;
  margin-right: 10px;
  margin-left: 10px;
}

.m-kind-arr {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  height: 319px;

  :deep(.el-image) {
    margin: 0px;
  }
}

:deep(.el-image-viewer__close) {
  height: 30px !important;
  width: 30px !important;
  border: none;
}
</style>
