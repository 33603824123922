import { Info_companyBaseList } from '@/info/infoMapCpn.js'
import Api from '@/api/Api.js'
import OMU from '@/plug/map/openLayer/utils/utils.js'
import { ref } from 'vue'
export function HookMapAdminFeatures(MapWRoot, areaCode) {
  let adminObj = ref(false)
  MapWRoot.onLoading('adminDivi')
  let postData = { areaCode: Api.removeZero(areaCode) }
  let pmsArr = [Info_companyBaseList(postData)]
  Promise.all(pmsArr)
    .then((rets) => {
      MapWRoot.offLoading('adminDivi')
      let data = rets[0].data
      let geoJsonOuter = Api.jsonData(data[0].geojson)
      adminObj.value = {
        geoJsonOuter: geoJsonOuter,
        geoJsonInner: geoJsonOuter,
        bounds: OMU.getBoundsByGeojson(geoJsonOuter)
      }
    })
    .catch((err) => {
      console.log(err)
    })
  return adminObj
}
