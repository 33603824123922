import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'dayjs/locale/zh-cn' //中文
import locale from 'element-plus/es/locale/lang/zh-cn' //中文
import 'amfe-flexible'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import 'swiper/css'
import '@/assets/font/icon-cb/iconfont.css'
import { KOAjax } from './service/koajax.js'
import Api from '@/api/Api.js'
import drag from '@/utils/directives/drag.js'
import regCpn from '@/components/global/regCpn.js'
import '@/api/Index.js'
import '@/assets/css/base.css'
import '@/assets/scss/init.scss'
import '../font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { camelToSnake } from '@/utils/camelToSnake.js'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.KOAjax = KOAjax
app.config.globalProperties.Api = Api
app.config.globalProperties.$store = store
app.config.globalProperties.$camelToSnake = camelToSnake
app.use(store).use(router).use(ElementPlus, { locale })
app.use(drag)
app.use(regCpn)
app.mount('#app')
