<!--点的弹出层组件：markerPopup-->
<template>
  <div class="module-marker-popup" ref="RMarkerPopup">
    <div class="inner-box" v-if="selectFeature && markerType && markerType != 'locat'">
      <slot name="popup" :popupData="{ selectFeature: selectFeature, markerType: markerType }"></slot>
    </div>
    <img src="@/assets/img/changeColor/mapPop_white.png" alt="" style="width:25vw;height: 50vh;">
    <div class="pop_bg_color">

    </div>
    <div class="img_bg">
      <div class="close_icon" @click="closePop" v-if="popup">
        <el-icon size="20">
          <CloseBold />
        </el-icon>
      </div>
      <div class="img_bg_title">地块信息</div>
      <div class="base_msg">
        <div class="base_title">基地信息</div>
        <div class="base_item">
          <div>客户名称：</div>
          <div>{{ selectFeature['KHMC'] }}</div>
        </div>
        <div class="base_item">
          <div>证件号：</div>
          <div>{{ selectFeature['ZJH'] }}</div>
        </div>
        <div class="base_item">
          <div>土地所有权：</div>
          <div>{{ selectFeature['TDSYQ'] }}</div>
        </div>
        <div class="base_item">
          <div>土地流转对象：</div>
          <div>{{ selectFeature['TDLZDX'] }}</div>
        </div>
        <div class="base_item">
          <div>所在地区：</div>
          <div>{{ selectFeature['szdq'] }}</div>
        </div>
      </div>
      <div class="variety">
        <div class="variety_title">品种信息</div>
        <div class="variety_content">
          <div class="img_contetn">
            <img src="@/assets/img/index/chunjian.jpg" alt="" v-if="selectFeature['品种'] === '春见'">
            <img src="@/assets/img/index/aiyuan.jpg" alt="" v-if="selectFeature['品种'] === '爱媛'">
            <img src="@/assets/img/index/mingrijian.jpg" alt="" v-if="selectFeature['品种'] === '明日见'">
            <img src="@/assets/img/index/ganping.jpg" alt="" v-if="selectFeature['品种'] === '甘平'">
          </div>
          <div class="variety_text">
            <div class="variety_item">
              <div>品种：</div>
              <div>{{ selectFeature['品种'] }}</div>
            </div>
            <div class="variety_item">
              <div>面积：</div>
              <div>{{ Number(selectFeature['mj']).toFixed(2) }}</div>
            </div>
            <div class="variety_item">
              <div>地块编码：</div>
              <div>{{ selectFeature['地块编'] }}</div>
            </div>
            <div class="variety_item">
              <div>地形类别：</div>
              <div>{{ selectFeature['地形类'] }}</div>
            </div>
            <div class="variety_item">
              <div>经纬度：</div>
              <div>{{ coorMsg }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Overlay } from 'ol'
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {
      popup: false
    }
  },
  watch: {
    selectFeature: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.setPosition()
        } else {
          this.closePop()
        }
      }
    }
  },
  computed: {
    selectFeature() {
      return this.MapRoot.mapEvent.selectFeature
    },
    markerType() {
      return this.MapRoot.mapEvent.markerType
    },
    coorMsg() {
      if (this.selectFeature && this.selectFeature['经度']) {
        return `${(this.selectFeature['经度']).toFixed(6)},${(this.selectFeature['纬度']).toFixed(6)}`
      }
    }
  },
  mounted() { },
  methods: {
    closePop() {
      this.popup.setPosition(undefined);
    },
    initPopup() {
      if (this.$refs?.RMarkerPopup && this.MapRoot?.mapPlug?.map) {
        if (this.popup == false) {
          this.popup = new Overlay({
            element: this.$refs.RMarkerPopup,
            positioning: 'bottom-center',
            stopEvent: true,
            autoPan: {
              margin: 100,
            }, // 自动移动
            offset: [0, -26],
          })
          this.MapRoot.mapPlug.map.addOverlay(this.popup)
          console.log(`this.popup :>>`, this.popup)
        }
      }
    },
    setPosition() {
      if (this.popup) {
        let coord = [this.selectFeature['经度'], this.selectFeature['纬度']]
        console.log(`this.selectFeature :>>`, this.selectFeature)
        if (this.selectFeature['经度'] && this.selectFeature['纬度']) {
          let reCoord = this.MapRoot.mapPlug.fromLonLat(coord)
          console.log(`reCoord :>>`, reCoord)
          this.popup.setPosition(reCoord)
        }
      } else {
        this.initPopup()
        this.setPosition()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.module-marker-popup {
  position: relative;

  .inner-box {
    position: absolute;
    left: 0;
    bottom: 10px;
    border-radius: 3px;
    background: var(--ko-color-boxbg);
    box-shadow: 0 0 6px rgba(0, 255, 255, 0.5);
    transform: translate(-50%, 0);
    line-height: 1.8;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      margin-top: 0px;
      border-width: 6px;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border-style: solid;
      border-color: rgba(2, 33, 76, 0.7) transparent transparent transparent;
    }
  }
}

.pop_bg_color {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  inset: 0;
  background: linear-gradient(to bottom, var(--theme-map-bg-lgc1), var(--theme-map-bg-lgc2));
  // background: radial-gradient(#dbdbdb2e, 60%, rgba(223, 247, 7, 0.845));
  mask: url('@/assets/img/changeColor/mapPop_black.png') no-repeat 100% 100% / cover;
  mask-size: 100% 100%;
  mix-blend-mode: multiply;
}

.img_bg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 25vw;
  height: 50vh;

  ::v-deep .el-icon {
    color: var(--theme-color)
  }

  .close_icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 1vw;
    top: 2vh;
    cursor: pointer;
  }

  .img_bg_title {
    font-size: 20px;
    color: #fff;
    // background-image: -webkit-linear-gradient(top, rgba(255, 219, 166, 1), rgba(255, 255, 255, 1)); //开始方向，渐变颜色
    // -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。
    // -webkit-text-fill-color: transparent; //把文字变透明
    font-weight: bold;
    text-indent: 1.5vw;
    line-height: 4vh;
  }

  .base_msg {
    padding: 0px 1.8vw;

    .base_title {
      color: var(--theme-color);
      font-size: 18px;
      line-height: 4vh;
    }

    .base_item {
      display: flex;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, 0.2);
      background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
      line-height: 3vh;
      margin-bottom: 0.5vh;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.variety {
  padding: 0px 1.8vw;

  .variety_title {
    color: var(--theme-color);
    font-size: 18px;
    line-height: 4vh;
  }

  .variety_content {
    display: flex;


    .img_contetn {
      width: 33%;
      margin-right: 0.5vh;

      img {
        width: 100%;
      }
    }

    .variety_text {
      flex-grow: 1;

      .variety_item {
        display: flex;
        justify-content: space-between;
        line-height: 3vh;
        background-color: rgba(255, 255, 255, 0.2);
        margin-bottom: 1vh;
        padding: 0px 1vh;

      }
    }

  }
}
</style>
