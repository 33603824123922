import service from '@/utils/request.js'

// 登录
export function login(data) {
  return service({
    url: `/login`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 基地列表
export function get_companyList(params) {
  return service({
    url: `/companyBaseList`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 基地信息
export function get_companyMsg(params) {
  return service({
    url: `/insure/insurandFarmland/detail`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 价格列表
export function get_price(params) {
  return service({
    url: `/price/quotations/list`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 溯源
export function get_SuyuanInfo(data) {
  return service({
    url: `/suyuan/agriculturalTechnology/getSuYuanInfo`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 作物概括
export function get_Production(params) {
  return service({
    url: `/productionService/getProduction`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 获取设备类型
export function get_DeviceType(params) {
  return service({
    url: `/farmlandMicroclimate/data/getDeviceType`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 获取设备类型
export function get_DeviceList(params) {
  return service({
    url: `/farmlandMicroclimate/data/getDeviceNameList`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 获取最新数据
export function get_LastData(params) {
  return service({
    url: `/farmlandMicroclimate/data/getLastData`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 获取近24小时数据
export function get_R24Data(params) {
  return service({
    url: `/farmlandMicroclimate/data/getR24Data`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

// 获取全景图地块
export function get_Polygon(data) {
  return service({
    url: `/getVrLand`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 获取全景图标点
export function get_Marker(data) {
  return service({
    url: `/getMarker`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 没什么用
export function InfoMap_baseInfo(postData) {
  return service({
    url: '/companyBase/' + postData?.id,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
