<template>
  <ul class="cpn-map-legend">
    <!-- <div v-if="showUnit" class="unit">面积单位：亩</div> -->
    <li v-for="item in list" :key="item">
      <div class="label" :style="{ 'background-color': reColor(item) }"></div>
      <div class="name">{{ item.name }}</div>
    </li>
  </ul>
</template>
<script>
import { color2Hex } from '@/api/func/colorRibbon.js'
export default {
  inject: [],
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showUnit: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    reColor(item) {
      let color = item.colors || item.color
      return color2Hex(color)
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-legend {
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .label {
    width: 20px;
    height: 12px;
    border: 1px solid #fff;
    margin-right: 5px;
  }
}

.unit {
  margin-bottom: 10px;
}
</style>
