class CookieCache {
  set(cname, cvalue, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + (exdays ?? 30) * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toGMTString()
    let path = 'path=/;'
    let str = cname + '=' + cvalue + '; ' + expires + '; ' + path
    //Vue.cookie.set('token', str)
    document.cookie = str
  }
  get(cname) {
    var name = cname + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim()
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
    }
    return ''
  }
  remove(name) {
    if (this.get(name)) {
      let path = 'path=/;'
      document.cookie =
        name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; ' + path
    }
  }
  delete(name) {
    this.remove(name)
  }
}

export default new CookieCache()
