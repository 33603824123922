<template>
  <div class="layer-box-group">
    <!-- <div class="layer-group-left">
      <div class="box-bg">
        <BoxBaseInfo></BoxBaseInfo>
      </div>
    </div> -->
    <Cbxx :list="baseInfo"></Cbxx>

    <Cbtj></Cbtj>

    <Pfje :list="baseMoneyInfo"></Pfje>

    <!-- <div class="layer-group-right">
      <div class="box-bg">
        <BoxFeatureInfo></BoxFeatureInfo>
      </div>
    </div> -->
    <div class="layer-middle-right-bottom">
      <div class="box-bg">
        <CpnLegendList :showUnit="true" :list="legendList" v-if="$store.state.jidiCode === 'fb38662573d244839dd6dd2af2488f72'"></CpnLegendList>
      </div>
    </div>
  </div>
</template>
<script>
import CpnLegendList from '@/components/content/legend/CpnLegendList.vue'

import BoxBaseInfo from './layerBox/BoxBaseInfo.vue'
import BoxFeatureInfo from './layerBox/BoxFeatureInfo.vue'
import Cbxx from './layerBox/cbxx.vue'
import Cbtj from './layerBox/cbtj.vue'
import Pfje from './layerBox/pfje.vue'
import axios from 'axios'

let OPA = 0.7
export default {
  inject: ['MapWRoot'],
  components: {
    CpnLegendList,
    BoxBaseInfo,
    BoxFeatureInfo,
    Cbxx,
    Cbtj,
    Pfje
  },
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {
    legendList() {
      if (this.$store.state.jidiCode === 'fb38662573d244839dd6dd2af2488f72') {
        return [
          { name: '春见', color: 'rgba(5,194,104,' + OPA + ')' },
          { name: '爱媛', color: 'rgba(182,194,12,' + OPA + ')' },
          { name: '明日见', color: 'rgba(17,219,255,' + OPA + ')' },
          { name: '甘平', color: 'rgba(184,116,13,' + OPA + ')' }
        ]
      } else {
        return [
          { name: '尤力克', color: 'rgba(167, 223, 29,' + OPA + ')' }
        ]
      }
    },

    baseInfo() {
      // if (!this.$store.state.baodanItem) {
      let labels = [];
      let featureData = this.MapWRoot.farmInsure;
      if (featureData) {
        labels = this.Api.Obj2Arr({
          data: featureData,
          label: [
            {
              key: 'underwriting_type',
              name: '承保类型',
              icon: 'icon-cb chengbaoleixing'
            },
            {
              key: 'total_underwriting_area',
              name: '承保总面积',
              icon: 'icon-cb mianji2'
            },
            {
              key: 'total_underwriting_amount',
              name: '承保总金额',
              icon: 'icon-cb jine'
            },
            {
              key: 'underwriting_time',
              name: '承保时间',
              icon: 'icon-cb shijian',
              reData(e) {
                return e.Format('YYYY-MM-DD');
              }
            },
            { key: 'customer_name', name: '客户名称', icon: 'icon-cb kehu' },
            {
              key: 'insurance_manager',
              name: '保险经理',
              icon: 'icon-cb jingli'
            }
          ]
        });
      }
      labels[2].value = 4800000;
      labels[3].value = '2024-8-31';
      return labels;
      // } else {

      //   let list = [
      //     {
      //       key: 'underwriting_type',
      //       name: '承保类型',
      //       icon: 'icon-cb chengbaoleixing',
      //       value: this.$store.state.baodanItem.cblx
      //     },
      //     {
      //       key: 'total_underwriting_area',
      //       name: '承保总面积',
      //       icon: 'icon-cb mianji2',
      //       value: this.$store.state.baodanItem.cbArea
      //     },
      //     {
      //       key: 'total_underwriting_amount',
      //       name: '承保总金额',
      //       icon: 'icon-cb jine',
      //       value: this.$store.state.baodanItem.cbAllMoney
      //     },
      //     {
      //       key: 'underwriting_time',
      //       name: '承保时间',
      //       icon: 'icon-cb shijian',
      //       value: this.$store.state.baodanItem.cbTime
      //     },
      //     {
      //       key: 'customer_name', name: '客户名称', icon: 'icon-cb kehu',
      //       value: this.$store.state.baodanItem.khName
      //     },
      //     {
      //       key: 'insurance_manager',
      //       name: '保险经理',
      //       icon: 'icon-cb jingli',
      //       value: this.$store.state.baodanItem.bxjl
      //     }
      //   ];

      //   return list;

      // }

    },

    baseMoneyInfo() {
      // if (!this.$store.state.baodanItem) {
      let labels = [];
      let featureData = this.MapWRoot.farmInsure;
      if (featureData) {
        labels = this.Api.Obj2Arr({
          data: featureData,
          label: [
            {
              key: 'compensation_time',
              name: '赔付时间',
              icon: 'icon-cb shijian',
              reData(e) {
                return e.Format('YYYY-MM-DD');
              }
            },
            {
              key: 'total_compensation_area',
              name: '赔付总面积',
              icon: 'icon-cb mianji2'
            },
            {
              key: 'total_compensation_amount',
              name: '赔付总金额',
              icon: 'icon-cb jine'
            }
          ]
        });
      }
      return labels;
      // } else {
      //   // axios.post("http://nxfw.pfje", { code: this.$store.state.jidiCode }).then(res => {
      //   //   this.$store.commit("setBaodanItem", res.data);
      //   // })
      //   let list = [
      //     {
      //       key: 'compensation_time',
      //       name: '赔付时间',
      //       icon: 'icon-cb shijian',
      //       value: this.$store.state.baodanItem.pfTime
      //     },
      //     {
      //       key: 'total_compensation_area',
      //       name: '赔付总面积',
      //       icon: 'icon-cb mianji2',
      //       value: this.$store.state.baodanItem.pfArea
      //     },
      //     {
      //       key: 'total_compensation_amount',
      //       name: '赔付总金额',
      //       icon: 'icon-cb jine',
      //       value: this.$store.state.baodanItem.pfMoney
      //     }
      //   ];
      //   return list;
      // }
    }
  },
  mounted() { },
  methods: {}
}
</script>
<style lang="scss" scoped>
.layer-box-group {
  .box-bg {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    background: var(--ko-color-boxbg);
    overflow: auto;
  }
}

.layer-group-left {
  position: absolute;
  left: 10px;
  top: 10px;
  bottom: 50px;
  width: 420px;
}

.layer-group-right {
  position: absolute;
  right: 10px;
  top: 10px;
  bottom: 50px;
  width: 420px;
}

.layer-middle-right-bottom {
  position: absolute;
  bottom: 11px;
  left: 490px;
}
</style>
