// service统一出口
import router from '@/router'
import KORequest from './request'
import { BASE_URL, MOCK_URL, TIME_OUT } from './request/config'

import cookieCache from '@/utils/cookie.js'

const KOReq = new KORequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = cookieCache.get('token')
      if (token) {
        config.headers.token = token
      }
      return config
    },
    requestInterceptorCatch: (err) => {
      console.log('请求失败的拦截')
      return err
    },
    responseInterceptor: (res) => {
      //console.log('响应成功的拦截')
      return res
    },
    responseInterceptorCatch: (err) => {
      if (err?.response?.data?.code == 401 || err?.response?.status == 401) {
        cookieCache.delete('token')
        router.push('login')
        return { msg: '请先登录' }
      }
      return err.response
    }
  }
})

const MKReq = new KORequest({
  baseURL: MOCK_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = cookieCache.get('token')
      if (token) {
        config.headers.token = token
      }
      return config
    },
    requestInterceptorCatch: (err) => {
      console.log('请求失败的拦截')
      return err
    },
    responseInterceptor: (res) => {
      //console.log('响应成功的拦截')
      return res
    },
    responseInterceptorCatch: (err) => {
      if (err?.response?.data?.code == 401 || err?.response?.status == 401) {
        cookieCache.delete('token')
        router.push('login')
        return { msg: '请先登录' }
      }
      return err.response
    }
  }
})

const LCReq = new KORequest({
  baseURL: '',
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = cookieCache.get('token')
      if (token) {
        config.headers.token = token
      }
      return config
    },
    requestInterceptorCatch: (err) => {
      console.log('请求失败的拦截')
      return err
    },
    responseInterceptor: (res) => {
      //console.log('响应成功的拦截')
      return res
    },
    responseInterceptorCatch: (err) => {
      if (err?.response?.data?.code == 401 || err?.response?.status == 401) {
        cookieCache.delete('token')
        router.push('login')
        return { msg: '请先登录' }
      }
      return err.response
    }
  }
})

const KOPost = (ret) => {
  if (ret.load == 'mock') {
    return MKReq.request(ret)
  } else if (ret.load == 'json') {
    return LCReq.request(ret)
  } else {
    return KOReq.request(ret)
  }
}
export default KOPost
