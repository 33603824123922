<template>
  <ul class="cpn-box-label-list">
    <li v-for="(item, index) in list" :class="getClass(item, index)" :key="item.name" @click="EventClick(index)">
      <div class="box">
        <div class="name">
          <i class="icon" :class="item.icon"></i> {{ item.name }}
        </div>
        <div class="cont">{{ item.value }}</div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    getClass(item, index) {
      let dis = item.disabled ? 'disabled' : '';
      let newMsg = item.newMsg ? 'new-msg' : '';
      let cur = index == this.currentIndex ? 'current' : '';
      return cur + ' ' + newMsg + ' ' + dis;
    },
    EventClick(index) {
      if (index == this.currentIndex) {
        this.$emit('update:currentIndex', -1);
        this.$emit('change', -1);
      } else {
        this.$emit('update:currentIndex', index);
        this.$emit('change', index);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.cpn-box-label-list {
  display: flex;
  flex-wrap: wrap;

  li {
    flex-basis: 44%;
    position: relative;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    .box {
      background: rgba(15, 34, 62, 0.5);
      box-shadow: inset 0 0 10px rgba(0, 119, 255, 0.2);
    }

    &:nth-child(odd) {
      margin-left: 3%;
    }

    &.disabled {
      .box {
        opacity: 0.3;

        .cont {
          color: #aaa;
        }
      }

      cursor: not-allowed;
    }

    &.new-msg::after {
      display: block;
      content: '';
      position: absolute;
      right: 10px;
      top: 10px;
      width: 8px;
      height: 8px;
      background: #ec1515;
      border-radius: 50%;
    }

    .box {
      padding: 20px;
      color: #fff;

      .icon {
        font-size: 14px;
        margin-right: 5px;
        color: var(--ko-icon-color);
      }

      .name {
        font-size: 14px;
        margin-bottom: 15px;
      }

      .cont {
        font-size: 16px;
        padding-left: 20px;
      }
    }

    &.current {
      box-shadow: var(--ko-yy);
    }
  }
}
</style>
