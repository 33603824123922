import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router/index.js'

axios.defaults.withCredentials = true
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_HSY_SERVICE, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor

service.interceptors.request.use(
  function (config) {
    if (localStorage.getItem('hsyToken')) {
      config.headers['Authorization'] =
        'Bearer ' + JSON.parse(localStorage.getItem('hsyToken'))
    }
    // console.log(`config :>>`, config)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  function (config) {
    if (config.data.code === 401) {
      ElMessage.error({
        message: '登录过期，即将跳转至登录页',
        type: 'error',
        grouping: true
      })
      setTimeout(() => {
        router.push('/login')
      }, 2000)
      return Promise.reject(new Error(config.data.msg))
    } else if (config.data.code === 500) {
      ElMessage({ message: config.data.msg, type: 'error' })
      return Promise.reject(new Error(config.data.msg))
    }
    // console.log('响应', config.headers['set-cookie'])
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default service
