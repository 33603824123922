<template>
  <div :style="boxStyle" class="content_style">

    <header class="content_title_box">
      <img src="@/assets/img/changeColor/title_bg_white.png" alt="" style="position: absolute;left: 0px;top: 0px;width: 100%;z-index: -1;">
      <div class="content_title_bg_color"></div>
      <div class="content_title">
        {{ title }}
      </div>
    </header>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    top: { type: String, default: 0 },
    left: { type: String, default: 0 },
    width: { type: String, default: 0 },
    height: { type: String, default: 0 },
    title: { type: String, default: '' }
  },
  computed: {
    boxStyle() {
      return {
        top: this.top,
        left: this.left,
        width: this.width,
        height: this.height
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content_style {
  background-color: rgba(28, 23, 17, 0.8);
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;

  .content_title_box {
    // background: url('@/assets/img/newVersion/contentTitle.png');
    position: relative;
    background-size: cover;
    height: 36px;
    text-indent: 28px;
    line-height: 30px;
    font-style: italic;
    width: 100%;

    .content_title {
      background-image: -webkit-linear-gradient(top, var(--theme-title-font-lgc2), var(--theme-title-font-lgc1)); //开始方向，渐变颜色
      -webkit-background-clip: text; //以盒子内的文字作为裁剪区域向外裁剪，文字之外的区域都将被裁剪掉。
      -webkit-text-fill-color: transparent; //把文字变透明
      width: 100%;
      font-size: 18px;
      font-family: 'pbzdFont';
    }

    .content_title_bg_color {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      inset: 0;
      background: linear-gradient(to bottom, var(--theme-title-bg-lgc1), var(--theme-title-bg-lgc2));

      mask: url('@/assets/img/changeColor/title_bg_black.png') no-repeat 50% 50% / cover;
      mask-size: 100%;
      mix-blend-mode: multiply;
      z-index: -1;
    }
  }
}
</style>
