<template>
  <div class="get-data-home"></div>
</template>
<script>
import { Info_farmInsureList } from '@/info/infoFarmInsure.js'
import { get_companyMsg } from '@/api/hsy/index'
export default {
  inject: ['AppRoot', 'MapWRoot'],
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {
    '$store.state.jidiCode': function (newVal, oldVal) {
      this.init()
    },
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let farmInsure = false
      this.MapWRoot.onLoading('Info_farmInsureList')
      Info_farmInsureList().then((ret) => {
        this.MapWRoot.offLoading('Info_farmInsureList')
        if (ret.data?.length) {
          farmInsure = ret.data[0]
        }
        get_companyMsg({ companyBaseId: this.$store.state.jidiCode }).then(res => {
          let obj = this.$camelToSnake(res.data.data)
          farmInsure = { ...farmInsure, ...obj }
          this.$emit('initEnd', farmInsure)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
