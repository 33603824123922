<template>
  <div class="body-box">
    <!-- <div class="bgImg"></div> -->
    <header class="header-box" v-if="$route.path !== '/login'">
      <VHead></VHead>
    </header>
    <div class="left-box" v-if="false">
      <VLeftNav></VLeftNav>
    </div>
    <main class="main-box" v-loading="loading" element-loading-text="数据加载中..." element-loading-background="rgba(0, 0, 0, 0.5)">
      <router-view v-slot="{ Component }" v-if="isShow">
        <transition :name="transitionName" :duration="5000">
          <component class="slide-view" :is="Component" />
        </transition>
      </router-view>
      <div v-else class="ui-no-data" style="display: none;">{{ msg }}</div>
    </main>
    <footer class="footer-box" v-if="false">
      <VFoot></VFoot>
    </footer>
  </div>
</template>
<script>
import VHead from './index/VHead.vue'
import VLeftNav from './index/VLeftNav.vue'
import VFoot from './index/VFoot.vue'
// import { InfoMap_baseInfo } from '@/info/infoGarden.js'
import { get_companyMsg,InfoMap_baseInfo } from '@/api/hsy/index'
export default {
  components: {
    VHead,
    VLeftNav,
    VFoot
  },
  provide() {
    return {
      reload: this.reload,
      AppRoot: this.AppRoot
    }
  },
  data() {
    return {
      isShow: false,
      transitionName: '',
      loading: false,
      AppRoot: {
        base: false,
        crop: false
      },
      msg: ''
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(to, from) {
        if (
          from &&
          to.meta.animate &&
          from.meta &&
          from.meta.footerIndex >= 0
        ) {
          if (to.meta.footerIndex > from.meta.footerIndex) {
            this.transitionName = 'slide-right'
          } else {
            this.transitionName = 'slide-left'
          }
        }
      }
    },

    '$store.state.jidiCode': function (newVal, oldVal) {
      // this.newGetMsg()
    },
    userInfo: {
      handler() {
        this.loading = false
        this.reload()
        this.isShow = true
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  created() {
    this.init()
  },
  mounted() { },
  methods: {
    reload() {
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
      })
    },
    init() {
      this.getData_baseDetail()
    },
    getData_baseDetail(newId) {
      let id = 'fb38662573d244839dd6dd2af2488f72'
      this.loading = true
      InfoMap_baseInfo({ id })
        .then((ret) => {
          console.log("ret",ret)
          this.loading = false
          if (ret.data?.success) {
            this.isShow = true
            this.AppRoot.crop = {
              code: 'A0401',
              varietiesId: ret.data.data.varietiesIds
            }
            this.AppRoot.base = ret.data.data
            //document.title = ret.data.data.name
          } else {
            this.Api.msg(ret.data.msg)
            this.msg = ret.data.msg
          }
          // this.newGetMsg()
        })
        .catch((ret) => {
          this.loading = false
          this.Api.msg(ret.data.msg)
        })
    },
    newGetMsg() {
      get_companyMsg({ companyBaseId: this.$store.jidiCode }).then(res => {
        let obj = this.$camelToSnake(res.data.data)
        console.log(`this.MapWRoot :>>`, this.MapWRoot)
        console.log(`22this.MapWRoot.farmInsure :>>`, this.MapWRoot.farmInsure)
        this.MapWRoot.farmInsure && (this.MapWRoot.farmInsure = { ...this.MapWRoot.farmInsure, ...obj })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.m-nav {
  display: flex;

  li {
    height: 20px;
    font-size: 14px;
  }
}

.body-box {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--ko-body-bg) center/100% 100% no-repeat;
}

.bgImg {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // background: var(--ko-body-bg) center/100% 100% no-repeat;
  background: url('@/assets/img/newVersion/big_bg.png');
  background-size: 100vw 100vh;
  pointer-events: none;
}

.header-box {
  flex-shrink: 0;
  // z-index: 2;
  background-color: #021b39;
  // background: url('~@/assets/img/newVersion/head-bg.png') center/auto 100% repeat-x;
  z-index: 10;
}

.main-box {
  // position: relative;
  height: 10px;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
}

.left-box {
  position: absolute;
  left: 0;
  top: 135px;
  bottom: 0;
  width: 80px;
}

.footer-box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.slide-view {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.4s;
  transform: translate3d(0, 0, 0);
}

.slide-right-enter-to {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.slide-right-enter-from {
  transform: translate3d(100%, 0, 0);
}

.slide-right-leave-to {
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-from {
  transform: translate3d(0, 0, 0);
}

/**/
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.4s;
  transform: translate3d(0, 0, 0);
}

.slide-left-enter-to {
  transform: translate3d(0, 0, 0);
}

.slide-left-enter-from {
  transform: translate3d(-100%, 0, 0);
}

.slide-left-leave-to {
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-from {
  transform: translate3d(0, 0, 0);
}
</style>
