<template>
  <div class="module-marker"></div>
</template>
<script>
import FeatureMultMarker from '@/plug/map/openLayer/marker/FeatureMultMarker.js'
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    //绘制点到地图上
    initMarker(markerDict) {
      let options = {
        mapPlug: this.MapRoot.mapPlug,
        points: markerDict, //对象-数组：点经纬度数组，必有字段：lon，lat
        iconMarkerSize: [21, 30],
        labelOffsetY: -35
      }
      if (this.MapRoot.PlugFeaturePoint) {
        this.MapRoot.PlugFeaturePoint.update(options)
      } else {
        this.MapRoot.PlugFeaturePoint = new FeatureMultMarker(options)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
