import { DefAjax } from '../service/ajax.js'

/**
 * 通过区域code和基地id查询基地列表
 * @param {String,Number} ret.areaCode
 * @param {String} ret.cropCode
 * @returns
 */
export function Info_companyBaseList(ret) {
  let def = {
    areaCode: 5227,
    companyBaseIds: 'a7353d4b4c0746168be7ed4c18aebacd'
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/dp/ids/companyBaseList',
    params: opt
  })
}

/**
 * 通过产品id查询分布图
 * @param {String} ret.id
 * @returns
 */
export function Info_technologyList(ret) {
  let def = {
    companyBaseId: '',
    varieties: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    // url: '/source/api/wx/product/product/technology/list',
    url: '/wx/product/product/technology/list',
    params: opt
  })
}

/**
 * 通过基地id查询小气候仪站点数据
 * @param {String} ret.companyBaseId
 * @returns
 */
export function Info_farmlandSensorMarkerList(companyBaseId) {
  let def = {
    companyBaseId: companyBaseId
  }
  return DefAjax({
    method: 'get',
    // url: '/source/api/farmlandMicroclimate/company/queryStation/info',
    url: '/farmlandMicroclimate/company/queryStation/info',
    params: def
  })
}
